import React from 'react'
import styles from './logo.module.css'
import { getFullDomainName } from '../new-editor/utils/localization.utils'

const Logo: React.FC = () => {
  return (
    <div className={styles.logoList}>
      <a href={getFullDomainName()}>
        <img src="/mpcard.svg" alt="Logo" />
      </a>
    </div>
  )
}

export default Logo
