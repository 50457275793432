import React, { PropsWithChildren, ReactNode } from 'react'
// todo any
export const Case = ({ children }: PropsWithChildren<{ condition: boolean }>): any =>
  children ?? undefined
export const Default = ({ children }: PropsWithChildren<any>): any => children ?? null

export function Switch({ children }: PropsWithChildren<unknown>) {
  let matchChild: ReactNode | null = null
  let defaultCase: ReactNode | null = null

  React.Children.forEach(children, (child: ReactNode | any) => {
    if (!matchChild && child.type === Case) {
      const { condition } = child.props

      const conditionResult = Boolean(condition)

      if (conditionResult) {
        matchChild = child
      }
    } else if (!defaultCase && child.type === Default) {
      defaultCase = child
    }
  })

  return matchChild ?? defaultCase ?? null
}
