import axios from 'axios'

import { backendUrl } from '../config/app'

export const fetchCredits = async () => {
  const response = await axios.get(`${backendUrl()}api/check-credits`)
  if (response?.data.hasCredits) {
    return response?.data?.credits || null
  } else {
    return 0
  }
}
