import {PaywallVariations, useSubscriptions} from "../../../store/useSubscriptions";
import {useModalManager} from "../../../store/useModalManager";
import {MODAL_NAME as PAYWALL_MODAL} from "../../../ds/modal/PayWallModal";
import {MODAL_NAME as OPEN_OVERLAY} from "../../../ds/modal/OverlayModal";
import {SectionTab} from "polotno/side-panel";
import {observer} from "mobx-react-lite";
import {useResize} from "../../../hooks/useResize";
import { ReactComponent as BackgroundIcon } from '../background.svg'
import { ReactComponent as ArrowLeft } from '@material-design-icons/svg/round/chevron_left.svg'
import { useTranslation } from 'react-i18next'
import { BackgroundPanel } from "./Backgrounds/BackgroundsPanel";
import { useClosePanelInMobile } from '../../../hooks/useClosePanelInMobile'

export const BackgroundsTool = {
    name: 'backgrounds',
    Tab: (props: any) => {
        const hasActiveSubscription = useSubscriptions(s => s.hasActiveSubscription)
        const setPaywallVariation = useSubscriptions(s => s.setPaywallVariation)
        const toggleModal = useModalManager((s) => s.toggleModal)
        const { t } = useTranslation()
        const onTabClick = () => {
            if ( !hasActiveSubscription) {
                setPaywallVariation(PaywallVariations.backgrounds)
                toggleModal(PAYWALL_MODAL, true)
            } else {
                props.onClick()
                toggleModal(OPEN_OVERLAY, !props.active)
            }
        }

        return (
            <SectionTab name={t('pages.editor.backgrounds')} {...props} onClick={onTabClick}>
                <BackgroundIcon />
            </SectionTab >)
    },
    //Panel: BackgroundPanel,
    Panel: observer(({ store }: { store: any }) => {
        const { isScreenDesktop } = useResize()
        const { t } = useTranslation()
        const closePanelInMobile = useClosePanelInMobile()
        const onBackButtonClick = () =>{
            closePanelInMobile()
        }

        return(
            <div className="tool-panel info_panel">
                <div className="tool-panel_content">
                    <div className="tool-panel_content_text">
                        <div className='backButton' onClick={() =>
                            onBackButtonClick()
                        }>
                            <ArrowLeft />
                        </div>
                        <h2 className={`${isScreenDesktop ? 'titleS' : 'bodyL'} colorTextBlackPrimary`}>{t('pages.editor.backgrounds')}</h2>
                    </div>
                    <div className="tool-panel_content_wrapper content_default_wrapper">
                        <BackgroundPanel store={store}/>
                    </div>
                </div>
            </div>
        )
    }),
}