import { create } from "zustand";
import {createStore, StoreType} from "polotno/model/store";
import { isRuLocale } from "../components/new-editor/utils/localization.utils";
import { SIZE_RATIO_1_1, SIZE_RATIO_3_4 } from "../components/new-editor/constants/sizeRatios";

type PolotnoStore = {
    store: StoreType,
    product: any,
    setProduct: (p: any) => void,
    infographics: any,
    setInfographics: (i: any) => void,
}

const getDefaultCardSize = () => {
    return isRuLocale() ? SIZE_RATIO_3_4 : SIZE_RATIO_1_1
}

export const DEFAULT_STORE_PROPS = {
    objectPositionBeforeDisplacement: {
        x: 0,
        y: 0,
        width: 0,
        height: 0,
        rotation: 0,
    },
    activeInfographicsTemplate: 0,
    currentPromptInStore: {
        prompt: '',
        negativePrompt: '',
    },
    imageUrl: '',
    cardSize: getDefaultCardSize(),
}

export const usePolotno = create<PolotnoStore>((set) => ({
    store: createStore({
        key: '-GCZl3DuUJYY4M4gVVOq',
        showCredit: false,
    }),
    product: null,
    setProduct: (product: any) => set(() => ({product})),
    infographics: null,
    setInfographics: (infographics: any) => set(() => ({infographics}))
}))