import { SharedProps } from '../../components/ModalDialog/ModalDialog'
import { UserProfileContainer } from '../../components/UserProfile/UserProfileContainer'
import { useModalManager } from '../../store/useModalManager'

export type UserProfileModalProps = {}

export const MODAL_NAME = 'userProfile'

/**
 * @deprecated since Aug 15 by iSavi.
 */
export function UserProfileModal(props: UserProfileModalProps & SharedProps) {
  const {} = props
  const toggleModal = useModalManager((s) => s.toggleModal)

  const handleExitClick = () => {
    toggleModal(MODAL_NAME, false)
  }

  return (
    <div>
      <UserProfileContainer onClickBack={handleExitClick} />
    </div>
  )
}
