import { PropsWithChildren, ReactNode } from 'react'
import s from './styles.module.css'
import cx from 'clsx'
import {AppLevelModals} from "../../AppLevelModals";

export type FullWideLayoutProps = {
  header?: ReactNode
}

/**
 * A layout represent clean page with the Header (navbar) support
 * @param props
 * @returns
 */
export function FullWideLayout(props: PropsWithChildren<FullWideLayoutProps>) {
  const { children, header } = props

  return (
    <div className={s.Wrapper}>
      {header && <div>{header}</div>}
      <div className={cx(s.ContentWrapper, { [s.NoHeader]: !header, [s.WithHeader]: !!header })}>
        {children}
      </div>
      <AppLevelModals />
    </div>
  )
}
