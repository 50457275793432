import { ScrollLayout } from '../components/layouts/ScrollLayout'
import Header from '../components/Header/Header'
import ForgotPassword from '../auth/forgotPassword'

export function ForgotPasswordPage() {
  return (
    <ScrollLayout header={<Header />}>
      <ForgotPassword />
    </ScrollLayout>
  )
}
