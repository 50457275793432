import cx from 'clsx'
import s from './FullScreenSizeContainer.module.css'
import { ReactComponent as CrossIcon } from './white-cross.svg'
import { ReactComponent as MPCardProImage } from './mpcard_pro.svg'
import { ModalDialogProps } from './ModalDialog'
export const FullScreenSizeContainer = (props: ModalDialogProps) => {
  const {
    isOpen,
    withCloseButton = true,
    closeable = true,
    maxWidth = true,
    close,
    children,
    render,
  } = props

  if (!isOpen) {
    return null
  }

  const onCloseHandler = () => (closeable ? close() : false)

  return (
    <div className={cx(s.dialog)} onClick={onCloseHandler}>
      <div className={cx(s.inner_wrapper)} onClick={(event) => event.stopPropagation()}>
        <div className={cx(s.container)}>
          <div className={cx(s.body)}>
            {render && render({ close: close, closeable })}
            {!render && children && children}
          </div>
        </div>
      </div>
    </div>
  )
}
