import s from './modal.module.css'
import { useCredits } from '../../store/useCredits'
import Button, { ButtonType } from '../button/Button'
import { SharedProps } from '../../components/ModalDialog/ModalDialog'
import { reachGoal } from '../../utils/metrics'
import { WriteTelegramBtn } from '../button/WriteTelegramBtn'
import { BackToEditBtn } from '../button/BackToEditBtn'
import boxStarImage from './box-star.png'
import useFeatureFlag from '../../hooks/useFeatureFlag'
import cx from 'clsx'
import { pluralizeGenerations } from '../../utils/pluralizeGenerations'
import { useSubscriptions } from '../../store/useSubscriptions'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {isEnLocale} from "../../components/new-editor/utils/localization.utils";
import {maximizeSupport} from "../../utils/internationalSupport";

export const MODAL_NAME = 'successfullyPaid'
export const MODAL_NAME_SUBSCRIPTION = 'successfullySubscriptionPaid'

export function SuccessfullyPaidModal(props: SharedProps) {
  const { close } = props
  const credits = useCredits((s) => s.credits)
  const { t } = useTranslation()
  
  const onSendMessageClick = () => {
      reachGoal('click_send_message')
      maximizeSupport()
      close()
  }

  return (
    <div>
      <img className={s.imgPopup} src={boxStarImage} />

      <h1 className={cx(s.mainTextPopup, 'titleM')}>{t('pages.successfullyPaidModal.thankYouForPurchase')}</h1>

      <div className={cx(s.creditsTextPopup, 'bodyM')}>
        {credits !== null && (
          <p>
            {t('pages.successfullyPaidModal.remaining', { credits })} {t('pages.header.generation', { count: credits })}
          </p>
        )}
      </div>

      <div className={cx(s.minorTextPopup, 'paragraphM')}>
        <p>{t('pages.successfullyPaidModal.generationsAddedToYourAccount')}</p>

        <p>
          {t('pages.successfullyPaidModal.contactUsThroughTelegramForQuestions')}
        </p>
      </div>

      <div className={s.popupButtons}>
        <BackToEditBtn pos="left" onClick={close}>
          {t('pages.createNewCardModal.returnToEditing')}
        </BackToEditBtn>
        <WriteTelegramBtn pos="right" onClick={onSendMessageClick}>
          {t('pages.header.messageInTelegram')}
        </WriteTelegramBtn>
      </div>
    </div>
  )
}

// type SuccessfullyPaidSubscriptionModalProps = {
//   human
// }

export function SuccessfullyPaidSubscriptionModal(props: SharedProps) {
  const { close } = props
  const [planName, setPlanName] = useState<string | undefined>(undefined)
  const { t } = useTranslation()

  useEffect(() => {
    if (window.PURCHASE_PLAN) {
      setPlanName(window.PURCHASE_PLAN)
    }

    return () => {
      window.PURCHASE_PLAN = undefined
    }
  }, [])

  const onSendMessageClick = () => {
      reachGoal('click_send_message')
      maximizeSupport()
      close()
  }

  return (
    <div>
      <img className={s.imgPopup} src={boxStarImage} />

      <h1 className={cx(s.mainTextPopup, 'titleM')}>{t('pages.successfullyPaidModal.thankYouForPurchase')}</h1>

      <div className={cx(s.creditsTextPopup, 'bodyM')}>
        {planName && (
          <>
            {t('pages.successfullyPaidModal.youHaveSubscribedTo')} {planName} {t('pages.successfullyPaidModal.plan')}
          </>
        )}
        {!planName && (
          <>
            {t('pages.successfullyPaidModal.subscriptionSuccess')}
          </>
        )}
      </div>

      <div className={cx(s.minorTextPopup, 'paragraphM')}>
        {t('pages.successfullyPaidModal.subscriptionManagementAvailableInAccount')}
      </div>

      <div className={s.popupButtons}>
        <BackToEditBtn pos="left" onClick={close}>
          {t('pages.createNewCardModal.returnToEditing')}
        </BackToEditBtn>
        <WriteTelegramBtn pos="right" onClick={onSendMessageClick}>
        {t('pages.header.messageInTelegram')}
        </WriteTelegramBtn>
      </div>
    </div>
  )
}
