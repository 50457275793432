import { PropsWithChildren, useState } from 'react'
import cx from 'clsx'
import s from './SidePanel.module.css'
import { useTranslation } from 'react-i18next'

export type MenuItemType = 'personalData' | 'subscriptionManagement' | 'removeAccount'
export type SidePanelProps = {
  initItem?: MenuItemType
  onItemClick: (item: MenuItemType) => void
}

type UserMenuItemProps = {
  isActive: boolean
  onClick: () => void
}

const UserMenuItem = (p: PropsWithChildren<UserMenuItemProps>) => {
  const { isActive, onClick } = p
  return (
    <div className={cx('bodyM', s.Item, { [s.Active]: isActive })} onClick={onClick}>
      {p.children}
    </div>
  )
}

export function SidePanel(props: SidePanelProps) {
  const { onItemClick, initItem = 'personalData' } = props
  const [activeItem, setActiveItem] = useState<MenuItemType>(initItem)
  const { t } = useTranslation()

  const applyMenu = (item: MenuItemType) => {
    setActiveItem(item)
    onItemClick(item)
  }
  return (
    <div className={s.Wrapper}>
      <UserMenuItem
        onClick={() => applyMenu('personalData')}
        isActive={activeItem == 'personalData'}
      >
        {t('pages.profile.loginParameters')}
      </UserMenuItem>
      <UserMenuItem
        onClick={() => applyMenu('subscriptionManagement')}
        isActive={activeItem == 'subscriptionManagement'}
      >
        {t('pages.profile.manageSubscription')}
      </UserMenuItem>
      {/* Uncomment if it's implemented */}
      {/* <UserMenuItem onClick={() => applyMenu('removeAccount')} isActive={activeItem == 'removeAccount'}>{t('pages.profile.deleteAccount')}</UserMenuItem> */}
    </div>
  )
}
