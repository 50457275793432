import axios from 'axios'

import { backendUrl } from '../config/app'
import { reachGoal } from '../utils/metrics'
const LS_FIRST_CREDIT_FLAG = 'first_credit_flag'

export interface CreditsDTO {
  total: number,
  isUnlimited: boolean
}
export const updateCredits = (credits:CreditsDTO, setCredits:(credits:number)=>void) => {
  if (!localStorage.getItem(LS_FIRST_CREDIT_FLAG)) {
    reachGoal('user_spent_first_credit')
    localStorage.setItem(LS_FIRST_CREDIT_FLAG, 'true')
  }
  if (credits.total == 0) {
    reachGoal('user_spent_all_credits')
  }
  const count = credits.isUnlimited? -1 : credits.total
  setCredits(count)
  return  count;
}