import noProjectsImage from './no-projects.svg'
import s from './NoProjects.module.css'
import { useTranslation } from 'react-i18next'

export function NoProjects() {
  const { t } = useTranslation()

  return (
    <div className={s.NoProjectsContainer}>
      <div className={s.image}>
        <img src={noProjectsImage} />
      </div>

      <div className={s.Content}>
        <div className={s.Header}>{t('pages.fileUploadScreen.noSavedProjectsYet')}</div>

        <div className={s.SubHeader}>{t('pages.fileUploadScreen.uploadPhotoToStartFirstProject')}</div>
      </div>
    </div>
  )
}
