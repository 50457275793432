import axios from 'axios'

import { backendUrl } from '../config/app'
import { Subscription } from '../store/useSubscriptions'

export const deleteSubscription = async (subscriptionId: string) => {
  const response = await axios.delete(
    `${backendUrl()}api/ngb/payments/delete-subscription/${subscriptionId}`
  )

  const sbs = response.data as Subscription[]

  return sbs
}
