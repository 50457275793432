import {ScrollLayout} from '../components/layouts/ScrollLayout';
import Header from '../components/Header/Header'
import Register from '../auth/register'
// @todo fix handlers
export function SignUpPage() {
  return (
    <ScrollLayout header={<Header />}>
      <Register />
    </ScrollLayout>
  )
}
