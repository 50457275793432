import styles from './aicard.module.css'
import {Card} from "./aiCardsContext";

export type Props = {
  cardSize: string
  previewKey: number
  activeBackgroundKey: any
  setActiveBackgroundKey: any
  cardItem: Card
  onClick: (card: Card) => void

  //applyBackground: (imageUrl: string) => void
}

export const AICard = ({
  cardSize,
  previewKey,
  setActiveBackgroundKey,
  activeBackgroundKey,
  cardItem,
  onClick,
}: Props) => {
  const { meta, title } = cardItem
  let imageSrc =
    meta && meta?.srv == 'cobalt' && !cardItem.loading ? meta.thumbUrl : cardItem.dataUrl

  return (
    <div
      className={
        activeBackgroundKey === previewKey
          ? `${styles.aiCard} aiCardSelected active`
          : `${styles.aiCard}`
      }
    >
      <img
        className={cardSize === '1_1' ? styles.aiCardImageSquare : styles.aiCardImage}
        src={imageSrc}
        alt={title}
        onClick={() => {
          setActiveBackgroundKey(previewKey)
          onClick(cardItem)
        }}
      />
    </div>
  )
}
