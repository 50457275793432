import axios from 'axios'

import { backendUrl } from '../config/app'

export interface UpscaleBgRequestDTO {
  thumbUrl: string
  maskImageUrl: string
  imageUrl: string
  prompt: string
}

export interface UpscaleBgResponseDTO {
  url: string
  credits: {total:number, isUnlimited:boolean}
}

export const upscaleThumb = async (
  pl: UpscaleBgRequestDTO,
  pid: string
): Promise<UpscaleBgResponseDTO> => {
  const response = await axios.post(`${backendUrl()}api/image/background?pid=${pid}`, pl, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
  if (!response?.data) {
    console.error('Error during generate bg (upscaled)')
    throw new Error('Bad response from bg upscale service')
  }

  return response?.data as UpscaleBgResponseDTO
}
