import { useEffect, useState } from 'react'
import cx from 'clsx'
import { SubscriptionList } from './SubscriptionList'
import { Subscription, useSubscriptions } from '../../../store/useSubscriptions'
import { Button } from '../../Button'
import { deleteSubscription } from '../../../api/deleteSubscription'
import { fetchActiveSubscriptions } from '../../../api/fetchActiveSubscriptions'
import s from './SubscriptionHOC.module.css'
import { NoSubscription } from './NoSubscription'
import { reachGoal } from '../../../utils/metrics'
import {
  RemoveSubscriptionModal,
  MODAL_NAME as REMOVE_SUBSCRIPTION_MODAL_NAME,
} from '../../../ds/modal/RemoveSubscriptionModal'
import { ModalDialog } from '../../ModalDialog/ModalDialog'
import { useModalManager } from '../../../store/useModalManager'
import { useTranslation } from 'react-i18next'
import {isRuLocale} from "../../new-editor/utils/localization.utils";
import {backendUrl} from "../../../config/app";
import axios from "axios";

export type SubscriptionHOCProps = {}

const getActive = (s: Subscription[]) => {
  if (s.length > 0) {
    return s[0]
  }
  return null
}

const getPortalLink = async () => {
  const res = await axios.get(`${backendUrl()}api/stripe-portal-link`)

  return res.data.portalLink;
}

export function SubscriptionHOC(props: SubscriptionHOCProps) {
  const {} = props
  const toggleModal = useModalManager((s) => s.toggleModal)
  const [isBusy, setBusy] = useState(false)
  const setActiveSubscriptions = useSubscriptions((s) => s.setActiveSubscriptions)
  const sbs = useSubscriptions((s) => s.activeSubscriptions)
  const subscription = getActive(sbs)
  const { t } = useTranslation()
  const removeSubscription = async (id: string | null) => {
    if(isRuLocale()) {
      if (id) {
        reachGoal('confirm_unsubscribe')
        setBusy(true)
        await deleteSubscription(id)
        const subscriptions = await fetchActiveSubscriptions()
        setActiveSubscriptions(subscriptions)
        setBusy(false)
        toggleModal(REMOVE_SUBSCRIPTION_MODAL_NAME, false)
      }
    } else {
      window.location.href = await getPortalLink()
    }
  }

  const handleRemoveSubscription = () => {
    reachGoal('click_unsubscribe')
    toggleModal(REMOVE_SUBSCRIPTION_MODAL_NAME, true)
  }

  return (
    <div className={s.Container}>
      {subscription && <span className={cx('bodyS', s.CurrentTariffLabel)}>{t('pages.plans.currentPlan')}</span>}
      <div className={s.ListContainer}>
        {!subscription ? <NoSubscription /> : <SubscriptionList subscriptions={sbs} />}
      </div>
      {subscription && !subscription.cancelledAt && (
        <div className={s.Actions}>
          <Button
            className={s.Desktop}
            disabled={isBusy}
            variation="tertiary"
            size="s"
            grow={false}
            onClick={handleRemoveSubscription}
          >
            {t('pages.profile.cancelSubscription')}
          </Button>
          <Button
            className={s.Mobile}
            disabled={isBusy}
            variation="tertiary"
            size="s"
            grow={true}
            onClick={handleRemoveSubscription}
          >
            {t('pages.profile.cancelSubscription')}
          </Button>
        </div>
      )}

      {subscription && subscription.id && (
        <ModalDialog
          name={REMOVE_SUBSCRIPTION_MODAL_NAME}
          render={(p) => (
            <RemoveSubscriptionModal
              subscriptionId={subscription.id}
              onCancel={() => p.close()}
              onUnsubscribe={removeSubscription}
              {...p}
            />
          )}
        />
      )}
    </div>
  )
}
