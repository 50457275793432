import React from 'react';
import { isAlive } from 'mobx-state-tree';
import { InputGroup } from '@blueprintjs/core';
import { StoreType } from 'polotno/model/store';
import { ImagesGrid } from 'polotno/side-panel/images-grid';
import { svgToURL } from 'polotno/utils/svg';
import { figureToSvg, TYPES } from 'polotno/utils/figure-to-svg';
import styled from 'polotno/utils/styled';
import { t } from 'polotno/utils/l10n';
import { useInfiniteAPI } from 'polotno/utils/use-api';
import { URLS } from 'polotno/utils/api';
import { MODAL_NAME as OPEN_OVERLAY } from '../../../../ds/modal/OverlayModal';
import { useModalManager } from '../../../../store/useModalManager';

const iconToSrc = async (id: any): Promise<any> => {
  const req = await fetch(URLS.nounProjectDownload(id));
  const text = await req.text();
  const base64 = await svgToURL(text);
  return base64;
};

const NounContainer = styled('div')`
  height: 100%;
  overflow: hidden;

  .bp5-dark & img {
    filter: invert(1);
  }
`;
const limit = 50;

// export const NounprojectPanel = ({ store, query }) => {
export const NounprojectPanel = ({ store, query }: { store: any, query: any }) => {
  // load data
  const { data, isLoading, loadMore, setQuery, hasMore } = useInfiniteAPI({
    defaultQuery: query,
    getAPI: ({ page, query }) => URLS.nounProjectList({ query, page/*, limit*/ }),
    getSize: (res) => {
      // console.log(res);
      return res.pagesNumber;
    },
  });
  const toggleModal = useModalManager((s) => s.toggleModal)

  React.useEffect(() => {
    setQuery(query);
  }, [query]);

  return (
    <NounContainer>
      <ImagesGrid
        shadowEnabled={false}
        images={data?.map((data) => data.icons).flat()}
        getPreview={(item) => item.preview_url_84}
        isLoading={isLoading}
        onSelect={async (item, pos, element) => {
          if (element && element.type === 'image' && element.contentEditable) {
            const src = await iconToSrc(item.id);
            element.set({ clipSrc: src });
            return;
          }
          if (element && element.type === 'video' && element.contentEditable) {
            const src = await iconToSrc(item.id);
            element.set({ clipSrc: src });
            return;
          }
          const width = 200;
          const height = 200;
          store.history.transaction(async () => {
            const x = (pos?.x || store.width / 2) - width / 2;
            const y = (pos?.y || store.height / 2) - height / 2;
            const svg = store.activePage?.addElement({
              type: 'svg',
              width,
              height,
              x,
              y,
            });
            const src = await iconToSrc(item.id);
            if (isAlive(svg)) {
              await svg.set({ src });
            }
          });
          toggleModal(OPEN_OVERLAY, false)
        }}
        rowsNumber={4}
        loadMore={hasMore && loadMore}
      />
    </NounContainer>
  );
};

const LINES = [
  {
    preview: svgToURL`
<svg xmlns="http://www.w3.org/2000/svg" width="32" height="16">
  <path stroke="currentColor" strokeWidth="4" d="M 1 8 L 30 8"></path>
</svg>
`,
    data: {},
  },
  {
    preview: svgToURL`
<svg xmlns="http://www.w3.org/2000/svg" width="32" height="16">
  <path stroke="currentColor" strokeWidth="4" stroke-dasharray="4 2" d="M 1 8 L 30 8"></path>
</svg>
`,
    data: { dash: [4, 2] },
  },
  {
    preview: svgToURL`
<svg xmlns="http://www.w3.org/2000/svg" width="32" height="16">
  <path stroke="currentColor" strokeWidth="4" stroke-dasharray="1 1" d="M 1 8 L 30 8"></path>
</svg>
`,
    data: { dash: [1, 1] },
  },
  {
    preview: svgToURL`
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="16">
    <path
      stroke="currentColor"
      strokeWidth="2"
      d="M 25 6 L 29 8 L 25 10"
      fill="none"
      strokeLinejoin="round"
      strokeLinecap="round"
    ></path>
    <path
      stroke="currentColor"
      strokeWidth="4" d="M 1 8 L 29 8"
      strokeLinejoin="round"
      strokeLinecap="round"
    ></path>
  </svg>
`,
    data: { endHead: 'arrow' },
  },
  {
    preview: svgToURL`
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="16">
    <path
      stroke="currentColor"
      strokeWidth="2"
      d="M 25 6 L 29 8 L 25 10 Z"
      fill="currentColor"
      strokeLinejoin="round"
      strokeLinecap="round"
    ></path>
    <path
      stroke="currentColor"
      strokeWidth="4" d="M 1 8 L 29 8"
      strokeLinejoin="round"
      strokeLinecap="round"
    ></path>
    <circle cx="3" cy="8" r="2" fill="currentColor"></circle>
  </svg>
`,
    data: { startHead: 'circle', endHead: 'arrow' },
  },
  {
    preview: svgToURL`
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="16">
    <path
      stroke="currentColor"
      strokeWidth="2"
      d="M 1 6 L 5 6 L 5 10 L 1 10 Z"
      fill="currentColor"
      strokeLinejoin="round"
      strokeLinecap="round"
    ></path>
    <path
      stroke="currentColor"
      strokeWidth="4" d="M 1 8 L 29 8"
      strokeLinejoin="round"
      strokeLinecap="round"
      stroke-dasharray="2 1"
    ></path>
    <path
      stroke="currentColor"
      strokeWidth="4" d="M 29 6 L 29 10"
      strokeLinejoin="round"
      strokeLinecap="round"
    ></path>
  </svg>
`,
    data: { startHead: 'circle', endHead: 'bar', dash: [2, 1] },
  },
];

const Lines = ({ store }: { store: StoreType }) => {
  const toggleModal = useModalManager((s) => s.toggleModal)

  return (
    <ImagesGrid
      shadowEnabled={false}
      rowsNumber={3}
      images={LINES}
      getPreview={(image) => image.preview}
      itemHeight={50}
      isLoading={false}
      onSelect={async (image, pos, element) => {
        const width = store.activePage.computedWidth / 3;
        store.activePage.addElement({
          type: 'line',
          x: pos ? pos.x : store.activePage.computedWidth / 2 - width / 2,
          y: pos ? pos.y : store.activePage.computedHeight / 2,
          width: width,
          ...image.data,
        });
        toggleModal(OPEN_OVERLAY, false)
      }}
    />
  );
};

const FIGURE_DEFAULTS = {
  width: 300,
  height: 300,
  fill: 'lightgray',
  stroke: '#0c0c0c',
  strokeWidth: 0,
  url: '',
};

const subTypes = Object.keys(TYPES);

const DEFAULTS = [FIGURE_DEFAULTS];
const FIGURES: any[] = [];
subTypes.forEach((subType) => {
  DEFAULTS.forEach((defaults) => {
    FIGURES.push({
      subType,
      ...defaults,
    });
  });
});

FIGURES.forEach((figure) => {
  figure.url = svgToURL(figureToSvg(figure));
});

const BasicShapesContainer = styled('div')`
  height: 220px;
  // .bp5-dark & img {
  //   filter: invert(1);
  // }
`;

const BasicShapes = ({ store }: { store: StoreType }) => {
  const rowsNumber = Math.ceil(FIGURES.length / 4) || 1;
  const toggleModal = useModalManager((s) => s.toggleModal)

  return (
    <BasicShapesContainer style={{ height: rowsNumber * 110 + 'px' }}>
      <ImagesGrid
        shadowEnabled={false}
        rowsNumber={4}
        images={FIGURES}
        getPreview={(image) => image.url}
        isLoading={false}
        itemHeight={100}
        onSelect={async (image, pos, element) => {
          if (element && element.type === 'image' && element.contentEditable) {
            element.set({ clipSrc: image.url });
            return;
          }
          if (element && element.type === 'video' && element.contentEditable) {
            element.set({ clipSrc: image.url });
            return;
          }

          const baseSize = 1080 + 1080;
          const currentSize =
            store.activePage.computedWidth + store.activePage.computedHeight;
          const scale = currentSize / baseSize;

          const width = image.width * scale;
          const height = image.height * scale;

          const x = (pos?.x || store.activePage.computedWidth / 2) - width / 2;
          const y =
            (pos?.y || store.activePage.computedHeight / 2) - height / 2;
          store.activePage?.addElement({
            type: 'figure',
            ...image,
            x,
            y,
            width,
            height,
          });
          toggleModal(OPEN_OVERLAY, false)
        }}
      />
    </BasicShapesContainer>
  );
};

const LinesContainer = styled('div')`
  height: 220px;
  .bp5-dark & img {
    filter: invert(1);
  }
`;

export const Shapes = ({ store }: { store: StoreType }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        overflow: 'auto',
      }}
    >
      <p>{t('sidePanel.lines')}</p>
      <LinesContainer>
        <Lines store={store} />
      </LinesContainer>
      <p>{t('sidePanel.shapes')}</p>
      <BasicShapes store={store} />
    </div>
  );
};

export const ElementsPanel = ({ store }: { store: StoreType }) => {
  const requestTimeout = React.useRef<any>();
  const [query, setQuery] = React.useState('');
  const [delayedQuery, setDelayedQuery] = React.useState(query);

  React.useEffect(() => {
    requestTimeout.current = setTimeout(() => {
      setDelayedQuery(query);
    }, 500);
    return () => {
      clearTimeout(requestTimeout.current);
    };
  }, [query]);

  const hasSearch = !!delayedQuery;

  return (
    <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <InputGroup
        leftIcon="search"
        placeholder={t('sidePanel.searchPlaceholder')}
        onChange={(e) => {
          setQuery(e.target.value);
        }}
        style={{
          marginBottom: '20px',
        }}
        type="search"
      />
      {hasSearch && <NounprojectPanel query={delayedQuery} store={store} />}
      {!hasSearch && <Shapes store={store} />}
    </div>
  );
};
