import { useEffect } from 'react'
import { Button } from '../Button'
import cx from 'clsx'
import s from './UserProfile.module.css'
import { SidePanel, type MenuItemType } from './SidePanel'
import { Switch, Case } from '../CondRender/CondRender'
import { useTranslation } from 'react-i18next'
import { ReactComponent as IconArrowLeft } from '../plans/icon-arrow-left.svg'
import { useState } from 'react'
import { SubscriptionHOC } from './subscrption-management/SubscriptionHOC'
import { PersonalData } from './PersonalData'
import { reachGoal } from '../../utils/metrics'
import { backendUrl } from '../../config/app'
import { useAuthContext } from '../../contexts/AuthProvider'

export type UserProfileContainerProps = {
  onClickBack: () => void
}

export function UserProfileContainer(props: UserProfileContainerProps) {
  const [activeItem, setItem] = useState<MenuItemType>('personalData')
  const { onClickBack: onExitClick } = props
  const [user, setUser] = useState({ email: '', provider: '' })
  const { userId } = useAuthContext()
  const { t } = useTranslation()

  const loadUser = async () => {
    const res = await fetch(`${backendUrl()}api/user?id=${userId}`)
    const user = await res.json()
    setUser(user)
  }

  useEffect(() => {
    reachGoal('visit_profile')
  }, [])
  useEffect(() => {
    if (userId) {
      loadUser()
    }
  }, [userId])

  const handleMenuItemClick = (item: MenuItemType) => {
    setItem(item)
  }

  return (
    <div className={s.Container}>
      <div className={s.ContentWrapper}>
        <Button
          size="s"
          variation="secondary"
          grow={false}
          onClick={onExitClick}
          className={s.BackButtonContainer}
        >
          <div className={s.BackButton}>
            <div>
              <IconArrowLeft />
            </div>
            <div>{t('pages.profile.returnToEditor')}</div>
          </div>
        </Button>

        <div className={s.Header}>
          <h1 className={cx('titleM')}>{t('pages.header.profileSettings')}</h1>
        </div>

        <div className={s.Content}>
          <div>
            <SidePanel onItemClick={handleMenuItemClick} />
          </div>
          <div>
            <Switch>
              <Case condition={activeItem == 'personalData'}>
                <PersonalData user={user} />
              </Case>
              <Case condition={activeItem == 'subscriptionManagement'}>
                <SubscriptionHOC />
              </Case>
              <Case condition={activeItem == 'removeAccount'}>Remove Account</Case>
            </Switch>
          </div>
        </div>
      </div>
    </div>
  )
}
