import { useState, useRef, useEffect, MutableRefObject } from 'react'
import { ProjectObject } from '../../../domain/types/Project'
import { Link } from 'react-router-dom'
import s from './CardProject.module.css'
import cx from 'clsx'
import { ReactComponent as DuplicateIcon } from '@material-design-icons/svg/round/control_point_duplicate.svg'
import { ReactComponent as DeleteIcon } from '@material-design-icons/svg/round/delete.svg'
import { useTranslation } from 'react-i18next'

export type CardProjectProps = {
  project: ProjectObject
}

export type MenuHandlers = {
  onDuplicate: (pid: string) => void
  onDelete: (pid: string) => void
}

function useEventListener(eventType: string, callback: any, element = window) {
  const callbackRef = useRef(callback)

  useEffect(() => {
    callbackRef.current = callback
  }, [callback])

  useEffect(() => {
    if (element == null) return
    const handler = (e: any) => callbackRef.current(e)
    element.addEventListener(eventType, handler)

    return () => element.removeEventListener(eventType, handler)
  }, [eventType, element])
}

function useClickOutside(ref: any, cb: any) {
  useEventListener(
    'click',
    (e: any) => {
      if (ref.current == null || ref.current.contains(e.target)) return
      cb(e)
    },
    document as any
  )
}

const useOutsideClick = (ref: MutableRefObject<any>, callback: () => void) => {
  useEffect(() => {
    const handleClick = (event: any) => {
      if (ref && ref.current && ref.current.contains(event.target)) {
        callback()
      }
    }

    document.addEventListener('click', handleClick)

    return () => {
      document.removeEventListener('click', handleClick)
    }
  }, [])
}

export function CardProject(props: CardProjectProps & MenuHandlers) {
  const { project, onDuplicate, onDelete } = props
  const { t } = useTranslation()
  const menuRef = useRef(null)
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const toggleMenu = (e: any) => {
    e.preventDefault()

    setIsMenuOpen(!isMenuOpen)
  }

  useClickOutside(menuRef, () => {
    if (isMenuOpen) {
      setIsMenuOpen(false)
    }
  })

  const createHandler = (handler: (a: any) => void, pid: string) => (e: any) => {
    e.preventDefault()
    setIsMenuOpen(false)
    handler(pid)
  }

  return (
    <div>
      <Link to={`/projects/${project.id}`} className={s.link}>
        <div className={s.Card}>
          <div className={s.CardImage}>
            <img src={project.meta?.coverImage} alt={''} />
            <div className={s.CardOverlay}>
              <button
                className={cx(s.MenuButton, { [s.MenuButtonActive]: isMenuOpen })}
                onClick={toggleMenu}
                ref={menuRef}
              >
                <span>•••</span>
              </button>
            </div>
          </div>
          {isMenuOpen && (
            <div className={s.DropdownMenu}>
              <div className={s.MenuItem} onClick={createHandler(onDuplicate, project.id)}>
                <DuplicateIcon className={s.colorDuplicate} />
                {t('pages.fileUploadScreen.createDuplicate')}
              </div>
              <div className={s.MenuItem} onClick={createHandler(onDelete, project.id)}>
                <DeleteIcon className={s.colorDelete} />
                {t('pages.fileUploadScreen.deleteProject')}
              </div>
            </div>
          )}
        </div>
      </Link>
    </div>
  )
}
