import { useEffect, useRef } from 'react'
import { EditorLayout } from '../components/layouts'
import Header from '../components/Header/Header'
import { CardsProvider } from '../components/new-editor/aicard/aiCardsContext'
import { useProjects, useProjectsSaving } from '../store/useProjects'
import { useParams } from 'react-router-dom'
import { fetchProject } from '../api/projects/fetch-project'
import { ProjectObject } from '../domain/types/Project'
import { useNavigate } from 'react-router-dom'
import { R_START_PAGE } from '../router-constants'
import { useModalManager } from '../store/useModalManager'
import { MODAL_NAME as SAVING_MODAL } from '../ds/modal/SavingModal'
import { Editor } from "../components/new-editor/editor";
import { useTranslation } from 'react-i18next'

export function ProjectWorkspacePage() {
  const { id: projectId } = useParams()
  const navigate = useNavigate()
  const projectManager = useProjects((s) => ({
    setActiveProject: s.setActiveProject,
    resetActiveProject: s.resetActiveProject,
  }))
  const toggleModal = useModalManager((s) => s.toggleModal)
  const isProjectSaving = useProjectsSaving((s) => s.isSaving)
  const { t } = useTranslation()
  const activeProject: ProjectObject | null = useProjects((s) => s.activeProject)

  const newEditorEnabled = process.env.REACT_APP_NEW_EDITOR_ON === 'true';


  // reset active project when page is being unmounted
  useEffect(() => {
    return () => projectManager.resetActiveProject();
  }, []);

  // load project
  useEffect(() => {
    ; (async () => {
      if (projectId) {
        try {
          const project = await fetchProject(projectId)

          projectManager.setActiveProject(project)
        } catch (e) {
          // go to start page.
          navigate(R_START_PAGE, { replace: true })
        }
      }
    })()
  }, [projectId])

  const handleBackToProjects = () => {
    if (isProjectSaving) {
      toggleModal(SAVING_MODAL, true)
    } else {
      navigate(R_START_PAGE)
    }
  }


  return (
    <EditorLayout header={<Header withBackButton onBackButtonClick={handleBackToProjects} backButtonText={t('pages.projectWorkspacePage.toProjects')} />}>
      {activeProject && (
        <div>
          <CardsProvider>
            <Editor project={activeProject} backgroundRemoved={false} />
          </CardsProvider>
        </div>
      )}
    </EditorLayout>
  )
}
