import {SubscriptionTierType, SubscriptionType} from "../../store/useSubscriptions";

export type ClientType = 'standard' | 'premium' | 'business'

export type FeaturesListKey = 'base' | 'premium' | 'business'

export type PlanData = {
    planId?: string
    stripePriceId?: string
    titleKey: string
    subTitleKey?: string
    clientTypeTipKey?: ClientType
    price: number
    originalPrice: number
    analyticsEvent?: string
    tier?: SubscriptionTierType
    type?: SubscriptionType
    highlight?: boolean
    featuresListKey?: FeaturesListKey
}

export type Plans = {
    yearlyPlans: PlanData[],
    monthlyPlans: PlanData[],
    shopPlans: PlanData[]
}

type PlansVariations = {
    [key:string]: Plans
}

const plans: PlansVariations = {
    'ru': {
        yearlyPlans: [
            {
                planId: 'bcc27de8-e1fa-481d-a347-4ea4c7f84267',
                stripePriceId: 'price_1QIrfBFLCcICeavxsoJyQ3bl',
                titleKey: 'pages.plans.beginner',
                clientTypeTipKey: 'standard',
                price: 6490,
                originalPrice: 540,
                analyticsEvent: 'click_plan_basic_annual',
                tier: 'standard',
                type: 'ANNUAL',
                featuresListKey: 'base',
            },
            {
                planId: 'f54da799-05ba-4883-97dc-5ae58f0f7c2a',
                stripePriceId: 'price_1QIrfBFLCcICeavxsoJyQ3bl',
                titleKey: 'pages.plans.seller',
                subTitleKey: 'pages.plans.recommended',
                clientTypeTipKey: 'premium',
                price: 24990,
                originalPrice: 2082,
                analyticsEvent: 'click_plan_premium_annual',
                tier: 'premium',
                type: 'ANNUAL',
                highlight: true,
                featuresListKey: 'premium',
            },
            {
                planId: '5086fe41-54e1-4f41-99e5-b211db771c97',
                stripePriceId: 'price_1QIrfBFLCcICeavxsoJyQ3bl',
                titleKey: 'pages.plans.business',
                clientTypeTipKey: 'business',
                price: 64490,
                originalPrice: 5374,
                analyticsEvent: 'click_plan_business_annual',
                tier: 'business',
                type: 'ANNUAL',
                featuresListKey: 'business',
            },
        ],
        monthlyPlans: [
            {
                planId: '016f62c0-9990-4a7a-b0de-3d490ebaee99',
                stripePriceId: 'price_1QHN4qFLCcICeavx2dU2ZVTM',
                titleKey: 'pages.plans.beginner',
                clientTypeTipKey: 'standard',
                price: 890,
                originalPrice: 890,
                analyticsEvent: 'click_plan_basic_monthly',
                tier: 'standard',
                type: 'MONTHLY',
                featuresListKey: 'base',
            },
            {
                planId: '0710e2f7-1494-440c-a06e-6c9067f7d757',
                stripePriceId: 'price_1QHN4qFLCcICeavx2dU2ZVTM',
                titleKey: 'pages.plans.seller',
                subTitleKey: 'pages.plans.recommended',
                clientTypeTipKey: 'premium',
                price: 3490,
                originalPrice: 3490,
                analyticsEvent: 'click_plan_premium_monthly',
                tier: 'premium',
                type: 'MONTHLY',
                highlight: true,
                featuresListKey: 'premium',
            },
            {
                planId: '9907a2ac-da83-47d3-a491-0e6f8b9cfc8f',
                stripePriceId: 'price_1QHN4qFLCcICeavx2dU2ZVTM',
                titleKey: 'pages.plans.business',
                clientTypeTipKey: 'business',
                price: 8990,
                originalPrice: 8990,
                analyticsEvent: 'click_plan_business_monthly',
                tier: 'business',
                type: 'MONTHLY',
                featuresListKey: 'business',
            },
        ],

        shopPlans: [
            {
                titleKey: '500',
                price: 1000,
                originalPrice: 1000,
                analyticsEvent: 'click_generations_500',
            },
            {
                titleKey: '1000',
                price: 1500,
                originalPrice: 1500,
                analyticsEvent: 'click_generations_1000',
            },
            {
                titleKey: '2000',
                price: 3000,
                originalPrice: 3000,
                analyticsEvent: 'click_generations_2000',
            },
        ]
    },
    'en': {
        yearlyPlans: [
            {
                planId: 'bcc27de8-e1fa-481d-a347-4ea4c7f84267',
                stripePriceId: 'price_1QHL0IFLCcICeavxFfMphv70',
                titleKey: 'pages.plans.beginner',
                clientTypeTipKey: 'standard',
                price: 144,
                originalPrice: 12,
                analyticsEvent: 'click_plan_basic_annual',
                tier: 'standard',
                type: 'ANNUAL',
                featuresListKey: 'base',
            },
            {
                planId: 'f54da799-05ba-4883-97dc-5ae58f0f7c2a',
                stripePriceId: 'price_1QHL59FLCcICeavx8tCZRPMa',
                titleKey: 'pages.plans.seller',
                subTitleKey: 'pages.plans.recommended',
                clientTypeTipKey: 'premium',
                price: 324,
                originalPrice: 27,
                analyticsEvent: 'click_plan_premium_annual',
                tier: 'premium',
                type: 'ANNUAL',
                highlight: true,
                featuresListKey: 'premium',
            },
            {
                planId: '5086fe41-54e1-4f41-99e5-b211db771c97',
                stripePriceId: 'price_1QHL3JFLCcICeavxmMI10zsb',
                titleKey: 'pages.plans.business',
                clientTypeTipKey: 'business',
                price: 713,
                originalPrice: 59.42,
                analyticsEvent: 'click_plan_business_annual',
                tier: 'business',
                type: 'ANNUAL',
                featuresListKey: 'business',
            },
        ],
        monthlyPlans: [
            {
                planId: '016f62c0-9990-4a7a-b0de-3d490ebaee99',
                stripePriceId: 'price_1QHL5ZFLCcICeavxNdOKw7iX',
                titleKey: 'pages.plans.beginner',
                clientTypeTipKey: 'standard',
                price: 19.99,
                originalPrice: 19.99,
                analyticsEvent: 'click_plan_basic_monthly',
                tier: 'standard',
                type: 'MONTHLY',
                featuresListKey: 'base',
            },
            {
                planId: '0710e2f7-1494-440c-a06e-6c9067f7d757',
                stripePriceId: 'price_1QHL1IFLCcICeavxCthxUqoo',
                titleKey: 'pages.plans.seller',
                subTitleKey: 'pages.plans.recommended',
                clientTypeTipKey: 'premium',
                price: 44.99,
                originalPrice: 44.99,
                analyticsEvent: 'click_plan_premium_monthly',
                tier: 'premium',
                type: 'MONTHLY',
                highlight: true,
                featuresListKey: 'premium',
            },
            {
                planId: '9907a2ac-da83-47d3-a491-0e6f8b9cfc8f',
                stripePriceId: 'price_1QHL2AFLCcICeavxc64ti2k3',
                titleKey: 'pages.plans.business',
                clientTypeTipKey: 'business',
                price: 99,
                originalPrice: 99,
                analyticsEvent: 'click_plan_business_monthly',
                tier: 'business',
                type: 'MONTHLY',
                featuresListKey: 'business',
            },
        ],

        shopPlans: []
    },
    'en_dev': {
        yearlyPlans: [
            {
                planId: 'bcc27de8-e1fa-481d-a347-4ea4c7f84267',
                stripePriceId: 'price_1QJwQwFLCcICeavxk7yZqyRd',
                titleKey: 'pages.plans.beginner',
                clientTypeTipKey: 'standard',
                price: 144,
                originalPrice: 12,
                analyticsEvent: 'click_plan_basic_annual',
                tier: 'standard',
                type: 'ANNUAL',
                featuresListKey: 'base',
            },
            {
                planId: 'f54da799-05ba-4883-97dc-5ae58f0f7c2a',
                stripePriceId: 'price_1QJwRdFLCcICeavxoCfhAltA',
                titleKey: 'pages.plans.seller',
                subTitleKey: 'pages.plans.recommended',
                clientTypeTipKey: 'premium',
                price: 324,
                originalPrice: 27,
                analyticsEvent: 'click_plan_premium_annual',
                tier: 'premium',
                type: 'ANNUAL',
                highlight: true,
                featuresListKey: 'premium',
            },
            {
                planId: '5086fe41-54e1-4f41-99e5-b211db771c97',
                stripePriceId: 'price_1QJwUaFLCcICeavxJ2Y5IQES',
                titleKey: 'pages.plans.business',
                clientTypeTipKey: 'business',
                price: 713,
                originalPrice: 59.42,
                analyticsEvent: 'click_plan_business_annual',
                tier: 'business',
                type: 'ANNUAL',
                featuresListKey: 'business',
            },
        ],
        monthlyPlans: [
            {
                planId: '016f62c0-9990-4a7a-b0de-3d490ebaee99',
                stripePriceId: 'price_1QJwQQFLCcICeavxLwuBjYWz',
                titleKey: 'pages.plans.beginner',
                clientTypeTipKey: 'standard',
                price: 19.99,
                originalPrice: 19.99,
                analyticsEvent: 'click_plan_basic_monthly',
                tier: 'standard',
                type: 'MONTHLY',
                featuresListKey: 'base',
            },
            {
                planId: '0710e2f7-1494-440c-a06e-6c9067f7d757',
                stripePriceId: 'price_1QJwRKFLCcICeavxdyCarLxc',
                titleKey: 'pages.plans.seller',
                subTitleKey: 'pages.plans.recommended',
                clientTypeTipKey: 'premium',
                price: 44.99,
                originalPrice: 44.99,
                analyticsEvent: 'click_plan_premium_monthly',
                tier: 'premium',
                type: 'MONTHLY',
                highlight: true,
                featuresListKey: 'premium',
            },
            {
                planId: '9907a2ac-da83-47d3-a491-0e6f8b9cfc8f',
                stripePriceId: 'price_1QJwToFLCcICeavxT9azmjjj',
                titleKey: 'pages.plans.business',
                clientTypeTipKey: 'business',
                price: 99,
                originalPrice: 99,
                analyticsEvent: 'click_plan_business_monthly',
                tier: 'business',
                type: 'MONTHLY',
                featuresListKey: 'business',
            },
        ],

        shopPlans: []
    },
}

export const getPlans = () => {
    const variant = process.env.REACT_APP_PLANS_VARIANT || 'ru';
    return plans[variant] || plans['ru'];
};
