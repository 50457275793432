import { create } from 'zustand'
import type { ProjectObject } from '../domain/types/Project'

export type ProjectsState = {
  projects: ProjectObject[]
  activeProject: ProjectObject | null
}

export type ProjectsActions = {
  setProjects: (projects: Array<ProjectObject>) => void
  setActiveProject: (project: ProjectObject) => void
  resetActiveProject: () => void
}

export const useProjects = create<ProjectsState & ProjectsActions>()((set) => ({
  projects: [],
  activeProject: null,
  setActiveProject: (p: ProjectObject) =>
    set((s) => ({
      ...s,
      activeProject: p,
    })),
  resetActiveProject: () =>
    set((s) => ({
      ...s,
      activeProject: null,
    })),
  setProjects: (val: ProjectObject[]) =>
    set((state) => ({
      ...state,
      projects: val,
    })),
}))


export type LoadingState = {
  isSaving: boolean
}

export type LoadingActions = {
  setProjectSaving: (v: boolean) => void
}


export const useProjectsSaving = create<LoadingState & LoadingActions>()((set) => ({
  isSaving: false,
  setProjectSaving: (v: boolean) => set((s) => {

    return {
      ...s,
      isSaving: v
    }
  })
}))
