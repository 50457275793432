import { HTMLAttributes, ReactNode, useEffect } from 'react'
import { useModalManager } from '../../store/useModalManager'
import { ModalDialogComponent } from './ModalDialogComponent'

export type SharedProps = {
  closeable?: boolean
  close: () => void
}

export type ModalDialogProps = {
  /**
   * Controls whether the close button is shown or not. Default is true.
   */
  withCloseButton?: boolean
  /**
   * Controls whether the modal container uses full width (set by CSS max-width). Default is true.
   */
  maxWidth?: boolean
  /**
   * Controls whether the modal is shown or not.
   */
  isOpen: boolean
  /**
   * In case the render function is not used, the children must be passed.
   */
  children?: ReactNode

  /**
   * Render props function that provides the modal control function into modal content component. Useful when modal wants to control modal dialog state.
   * @param p
   * @returns
   */
  render?: (p: SharedProps) => ReactNode

  /**
   * Custom modal dialog component. Default is ModalDialogComponent.
   */
  ContainerComponent?: React.ComponentType<ModalDialogComponentProps>
} & SharedProps &
  HTMLAttributes<HTMLDivElement>

export type ModalDialogRegularProps = {
  name: string
  initAsOpened?: boolean
}

export type ModalDialogComponentProps = {
  isOpen: boolean
  close: () => void
} & SharedProps &
  HTMLAttributes<HTMLDivElement>

/**
 * Generic Modal Component to render components as modal dialogs. This is an uncontrolled version of the modal. The control of visibility is delegated to the store.
 * If you want to use a controlled version, use ModalDialogComponent directly.
 *
 * Usage cases:
 * Basic usage:
 * <ModalDialog name="my-modal-1"}>
 *  <MyContent />
 * </ModalDialog>
 *
 * Then in code:
 * const openModal = useModalManager(s => s.toggleModal)
 * openModal(true)
 *
 * If parent components of the modal dialog want to operate with modal state, then you have to use a custom render function like this:
 * <ModalDialog name={"my-modal-1"} render={(p) => (<MyModalContent {...p} />)} />
 *
 * Where MyModalContent is your modal content that is able to receive SharedProps of the ModalDialog. See type SharedProps.
 * @param props
 * @returns
 */
export function ModalDialog(
  props: Omit<ModalDialogProps, 'isOpen' | 'close'> & ModalDialogRegularProps
) {
  const { name, initAsOpened = false, ContainerComponent = ModalDialogComponent, ...rest } = props
  const registerFn = useModalManager((s) => s.registerDialog)
  const toggleModal = useModalManager((s) => s.toggleModal)
  const isActive = useModalManager((s) => !!s.dlgRegistry[name])

  useEffect(() => {
    registerFn(name, initAsOpened)
  }, [name, registerFn, initAsOpened])

  return <ContainerComponent isOpen={isActive} close={() => toggleModal(name, false)} {...rest} />
}
