import React from "react";
import './SidebarTabs.css';

type SidebarTabsProps = {
  tabs: string[];
  value: number;
  onTabClick: (index: number) => void;
};

const SidebarTabs: React.FC<SidebarTabsProps> = ({ tabs, value, onTabClick }) => {
  return (
    <div className="tab-switcher">
      <div className="tab-container" style={{ '--tab-index': value } as React.CSSProperties}>
        {tabs.map((tab, index) => (
          <div
            key={index}
            className={`tab colorAccentVioletDark bodyM ${value === index ? 'active ' : 'inactive'}`}
            onClick={() => onTabClick(index)}
          >
            {tab}
          </div>
        ))}
        <div className="background-slider" />
      </div>
    </div>
  );
};

export default SidebarTabs;
