import { PropsWithChildren, ReactNode } from 'react'
import s from './styles.module.css'
import cx from 'clsx'
import {AppLevelModals} from "../../AppLevelModals";

export type CleanLayoutProps = {
  header?: ReactNode
  isOpenMobileMenu?: boolean
}

/**
 * A layout represent clean page with the Header (navbar) support
 * @param props
 * @returns
 */
export function CleanLayout(props: PropsWithChildren<CleanLayoutProps>) {
  const { children, header, isOpenMobileMenu  } = props

  return (
    <div className={isOpenMobileMenu ? s.layoutWithOutScroll : s.layout}>
      {header && <div>{header}</div>}
      <div className={cx(s.ContentWrapper, { [s.NoHeader]: !header, [s.WithHeader]: !!header })}>
        {children}
      </div>
        <AppLevelModals />
    </div>
  )
}
