import { method } from 'lodash'
import { backendUrl } from '../config/app'

export interface CheckLoginResult {
  status: boolean
  userId?: number
  hasCompletedRegistration?: boolean
  marketingConsent?: boolean | null
}

export const checkLoginStatus = async (): Promise<CheckLoginResult> => {
  try {
    const response = await fetch(`${backendUrl()}api/check-auth`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
      },
    })

    const res: { isLoggedIn: boolean; userId?: number, hasCompletedRegistration?: boolean, marketingConsent?: boolean | null } = await response.json()

    if (res.isLoggedIn && res.userId) {
      return {
        status: true,
        userId: res.userId,
        hasCompletedRegistration: res.hasCompletedRegistration,
        marketingConsent: res.marketingConsent
      }
    }
    return {
      status: false,
    }
  } catch (e) {
    return {
      status: false,
    }
  }
}
