import { CleanLayout } from '../components/layouts/CleanLayout'
import Header from '../components/Header/Header'
import AuthThankYou from '../auth/authTankyou'

// @todo fix handlers
export function ThanksForSignUpPage() {
  return (
    <CleanLayout header={<Header />}>
      <AuthThankYou />
    </CleanLayout>
  )
}
