import { ReactComponent as Loader } from './purple_loader.svg'
import cx from 'clsx'
import s from './LoadingIndicator.module.css'

export function LoadingIndicator() {

  return (
    <div className={s.Loading_outer}>
      <div className={s.Loading_inner}>
        <Loader className={s.Loading_loader} />
      </div>
    </div>
  )

}
