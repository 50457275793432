import { backendUrl } from '../../config/app'

export const updateCover = async (blob: Blob): Promise<{ status: boolean; url?: string }> => {
  const formData = new FormData()
  formData.append('file', blob, 'image.jpg')

  const response = await fetch(`${backendUrl()}api/projects/update-cover`, {
    method: 'POST',
    body: formData,
  })

  const res = await response.json()

  return res
}
