import React, { ReactNode, CSSProperties } from 'react';
import { observer } from 'mobx-react-lite';
import { observable } from 'mobx';
import { InputGroup } from '@blueprintjs/core';
import { ReactComponent as IoMdColorPalette } from '@material-design-icons/svg/round/palette.svg'
import { ColorPicker } from "polotno/toolbar/color-picker"
import { StoreType } from 'polotno/model/store';
import { ImagesGrid } from 'polotno/side-panel/images-grid';
import styled from 'polotno/utils/styled';
import { t } from 'polotno/utils/l10n';
import { useInfiniteAPI } from 'polotno/utils/use-api';
import { unsplashList, unsplashDownload } from 'polotno/utils/api';
import { MODAL_NAME as OPEN_OVERLAY } from "../../../../ds/modal/OverlayModal";
import { useModalManager } from "../../../../store/useModalManager";

let DEFAULT_QUERY = 'gradient';
export const setDefaultQuery = (query: string) => {
  DEFAULT_QUERY = query;
};

const colors = observable([
  'white',
  'rgb(82, 113, 255)',
  'rgb(255, 145, 77)',
  'rgb(126, 217, 87)',
  'rgb(255, 222, 89)',
  'rgb(203, 108, 230)',
  'rgb(0, 0, 0, 0)',
]);

export const setBackgroundColorsPreset = (newColors: string[]) => {
  colors.replace(newColors);
};

const ColoredContainer = styled('div')`
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 2px;
  box-shadow: 0 0 2px rgba(16, 22, 26, 0.3);
  cursor: pointer;
`;

interface ColoredProps {
  children?: ReactNode;
	color: string;
	style?: CSSProperties;
	[key: string]: any;
}

const Colored: React.FC<ColoredProps> = ({ children, color, ...props }) => {
  return (
    <ColoredContainer
      {...props}
      style={{
        ...props.style,
        background: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='15' viewBox='0 0 8 8'%3E%3Cg fill='rgba(112, 112, 116, 1)' fill-opacity='1'%3E%3Cpath fill-rule='evenodd' d='M0 0h4v4H0V0zm4 4h4v4H4V4z'/%3E%3C/g%3E%3C/svg%3E")`,
      }}
    >
      <div style={{ width: '100%', height: '100%', background: color, display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
        {children}
      </div>
    </ColoredContainer>
  );
};

export const BackgroundPanel = observer(({ store }: { store: StoreType }) => {
  const { setQuery, loadMore, isReachingEnd, data, isLoading, error } =
    useInfiniteAPI({
      defaultQuery: DEFAULT_QUERY,
      getAPI: ({ page, query }) => unsplashList({ page, query }),
    });
  const toggleModal = useModalManager((s) => s.toggleModal)
  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-around',
          paddingBottom: '10px',
        }}
      >
        <ColorPicker
          value={store.activePage?.background || 'white'}
          onChange={(color) => {
            store.activePage?.set({
              background: color,
            });
          }}
          store={store}
        >
          <Colored
            color={store.activePage?.background}
            style={{
              fontSize: '30px',
            }}
          >
            <IoMdColorPalette
              style={{
                // mixBlendMode: 'difference',
              }}
            />
          </Colored>
        </ColorPicker>
        {colors.map((color) => {
          return (
            <Colored
              key={color}
              color={color}
              onClick={() => {
                store.activePage?.set({
                  background: color,
                });
              }}
            />
          );
        })}
      </div>
      <InputGroup
        leftIcon="search"
        placeholder={t('sidePanel.searchPlaceholder')}
        onChange={(e) => {
          setQuery(e.target.value || DEFAULT_QUERY);
        }}
        type="search"
        style={{
          marginBottom: '20px',
        }}
      />
      <ImagesGrid
        images={data
          ?.map((item) => item.results)
          .flat()
          .filter(Boolean)}
        isLoading={isLoading}
        getPreview={(image) => image.urls.small}
        loadMore={!isReachingEnd && loadMore}
        onSelect={async (image) => {
          toggleModal(OPEN_OVERLAY, false)
          fetch(unsplashDownload(image.id));
          store.activePage?.set({
            background: image.urls.regular,
          });
        }}
        error={error}
      />
    </div>
  );
});
