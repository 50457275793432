import React, { useState, useRef } from 'react'
import styles from './avatar.module.css'

const Avatar: React.FC<{}> = ({}) => {
  return (
    <div>
      <img className={styles.avatarStub} src="/PersonRounded.svg" alt="Me" />
    </div>
  )
}

export default Avatar
