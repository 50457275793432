import { Subscription } from '../../../store/useSubscriptions'
import { CardActiveSubscription } from './CardActiveSubscription'

export type SubscriptionListProps = {
  subscriptions: Subscription[]
}

export function SubscriptionList(props: SubscriptionListProps) {
  const { subscriptions } = props

  return (
    <div>
      {subscriptions.map((s) => (
        <CardActiveSubscription key={s.id} subscription={s} />
      ))}
    </div>
  )
}
