import cx from 'clsx'
// import s from './PayWallModalDialog.module.css'
import s from '../../ds/modal/overlayModal.module.css'
import { ModalDialogProps } from './ModalDialog'

export const OverlayComponent = (props: ModalDialogProps) => {
  const {
    isOpen,
    withCloseButton = true,
    closeable = true,
    maxWidth = true,
    close,
    children,
    render,
  } = props

  if (!isOpen) {
    return null
  }

  const onCloseHandler = () => (closeable ? close() : false)

  return (
    <div className={cx(s.dialog)} /*onClick={onCloseHandler}*/>
    </div>
  )
}
