import {useModalManager} from "../../../../store/useModalManager";
import { ReactComponent as ArrowLeft } from '@material-design-icons/svg/round/chevron_left.svg'
import {StoreType} from "polotno/model/store";
import { useTranslation } from 'react-i18next'
import { MarketplaceRatioOption } from "../../components/MarketplaceRatioOption";
import { useClosePanelInMobile } from '../../../../hooks/useClosePanelInMobile'

type Props = {store: StoreType, isScreenDesktop: boolean}

export const Panel = ({store, isScreenDesktop}: Props) => {
    const { t } = useTranslation()
    const closePanelInMobile = useClosePanelInMobile()

    const onBackButtonClick = () =>{
        closePanelInMobile()
    }

    return (
        <div className="tool-panel">
            <div className="tool-panel_content">
                <div className="tool-panel_content_text">
                    <div className='backButton' onClick={() =>
                        onBackButtonClick()
                    }>
                        <ArrowLeft />
                    </div>
                    <h2 className={`${isScreenDesktop ? 'titleS' : 'bodyL'} colorTextBlackPrimary`}>{t('pages.editor.cardSize')}</h2>
                </div>
                <div className="tool-panel_content_wrapper content_sizeCard_wrapper">
                    <span className="odyS colorTextBlackPrimary sizeCard_minor_text">
                        {t('pages.editor.sizeChangeNotice')}
                    </span>
                    <MarketplaceRatioOption store={store} isScreenDesktop={isScreenDesktop} />
                </div>
            </div>
        </div>
    )
}