import axios from 'axios'

import { backendUrl } from '../config/app'

export interface AiBgResponseDTO {
  thumbUrl: string
  maskImageUrl: string
  imageUrl: string
}

// formData must have the following fields:
// prompt: string
// pid: string
// file: File

export const generateAiBgThumb = async (
  formData: FormData,
  pid: string
): Promise<AiBgResponseDTO> => {
  const response = await axios.post(`${backendUrl()}api/image/thumb?pid=${pid}`, formData)
  if (!response?.data) {
    console.error('Error during generate thumbnail')
    throw new Error('Bad response from thumbnail generation')
  }

  return response?.data as AiBgResponseDTO
}
