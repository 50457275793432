import { backendUrl } from '../config/app'
import axios from 'axios'

export const generateAiBackground = async (
  formData: FormData,
  pid: string
) => {
  return await axios.post(
    `${backendUrl()}api/image/addAIBackground?pid=${pid}`,
    formData
  )
}