import { useEffect, useState } from 'react'
import { FileUploadScreen } from '../screens'
import { StartPageLayout } from '../components/layouts'
import Header from '../components/Header/Header'
import { useProjects } from '../store/useProjects'
import { fetchProjects } from '../api/projects/fetch-projects'
import { ProjectList } from '../components/projects'
import {useNavigate} from 'react-router-dom'
import { createProject } from '../api/projects/create-project'
import s from './page-styles/StartPage.module.css'
import cx from 'clsx'
import { deleteProject } from '../api/projects/delete-project'
import { duplicateProject } from '../api/projects/duplicate-project'
import { MenuHandlers } from '../components/projects/CardProject/CardProject'
import { NoProjects } from '../components/projects/NoProjects/NoProjects'
import { ProjectsError } from '../components/projects/ProjectsError/ProjectsError'
import { useSubscriptions, PaywallVariations } from "../store/useSubscriptions";
import { useModalManager } from "../store/useModalManager";
import { MODAL_NAME as PAYWALL_MODAL } from "../ds/modal/PayWallModal";
import { LoadingIndicator } from '../components/projects/LoadingIndicator/LoadingIndicator'
import { ProductVideoModal } from '../ds/modal/ProductVideoModal'
import { useAuthContext } from '../contexts/AuthProvider'
import { VideoModalDialogComponent } from '../components/ModalDialog/VideoModalDialogComponent'
import { useResize } from '../hooks/useResize'
import { useTranslation } from 'react-i18next';
import { isRuLocale } from '../components/new-editor/utils/localization.utils'

export function StartPage() {
  const projects = useProjects((s) => s.projects)
  const setProjects = useProjects((s) => s.setProjects)
  const [isFetched, setFetched] = useState(false)
  const [isError, setError] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [isOpenProductVideoModal, setIsOpenProductVideoModal] = useState(true)
  const { hasAuthenticated, isTimeToShowTheVideo } = useAuthContext()
  const [isOpenMobileMenu, setOpenMobileMenu] = useState(false)
  const { t } = useTranslation()

  const fetchProjectsFn = async (showLoader = false) => {
    if (showLoader) {
      setLoading(true)
    }
    const projectsArray = await fetchProjects()
    setProjects(projectsArray)
    if (showLoader) {

      setLoading(false)
    }
  }
  const navigate = useNavigate()
  useEffect(() => {
    ; (async () => {
      try {
        setError(false)
        await fetchProjectsFn()
        setFetched(true)
      } catch (e) {
        setError(true)
      } finally {
        setLoading(false)
      }
    })()
  }, [])

  const hasActiveSubscription = useSubscriptions(s => s.hasActiveSubscription)
  const toggleModal = useModalManager((s) => s.toggleModal)
  const setPaywallVariation = useSubscriptions(s => s.setPaywallVariation)
  const hasPremiumSubscription = useSubscriptions((s) => s.hasPremiumSubscription)
  const hasBusinessSubscription = useSubscriptions((s) => s.hasBusinessSubscription)
  const projectsCount = useProjects(s => s.projects.length)
  const { isScreenSm } = useResize()

  const projectMenuHandlers: MenuHandlers = {
    onDuplicate: async (pid) => {
      if (projectsCount >= 100 && !hasBusinessSubscription) {
        setPaywallVariation(PaywallVariations.createMoreBusiness)
        toggleModal(PAYWALL_MODAL, true)
      } else if (projectsCount >= 10 && !hasPremiumSubscription) {
        setPaywallVariation(PaywallVariations.createMorePremium)
        toggleModal(PAYWALL_MODAL, true)
      } else if (!hasActiveSubscription) {
        setPaywallVariation(PaywallVariations.createMore)
        toggleModal(PAYWALL_MODAL, true)
      } else {
        await duplicateProject(pid)
        await fetchProjectsFn(false)
      }
    },

    onDelete: async (pid: string) => {
      await deleteProject(pid)
      await fetchProjectsFn(false)
    },
  }

  const handleFileUploaded = async (url: string) => {
    const project = await createProject(
      {
        sourceImage: url,
      },
      {
        coverImage: url,
      }
    )
    await fetchProjectsFn()
    navigate(`/projects/${project.id}`)
  }

  const setLocalStorage = () => {
    localStorage.setItem('viewedVideoAboutProduct', 'true')
  }

  const closeProductVideoModal = () => {
    setIsOpenProductVideoModal(false)
  }

  // FIXME fix props for FileUploadScreen
  return (
    <StartPageLayout header={<Header isOpenMobileMenu={isOpenMobileMenu} setOpenMobileMenu={setOpenMobileMenu}/>} isOpenMobileMenu={isOpenMobileMenu}>
      <div className={s.PageLayout}>
        <FileUploadScreen onFileUploaded={handleFileUploaded} />
        {isLoading && (
          <div className={s.LoadingContainer}>
            <LoadingIndicator />
          </div>
        )}
        <div className={s.ProjectListContainer}>
          {isFetched && !isLoading && (
            <div>
              {projects.length > 0 && (
                <div>
                  <h2 className={cx(s.ProjectHeader, isScreenSm ? 'titleS' : 'bodyL')}>{t('pages.fileUploadScreen.projects')}</h2>

                  <ProjectList items={projects} handlers={projectMenuHandlers} />
                </div>
              )}
            </div>
          )}
          {isFetched && !isLoading && <div>{projects.length == 0 && <NoProjects />}</div>}
          {isError && (
            <div>
              <ProjectsError onReload={fetchProjects} />
            </div>
          )}
        </div>
      </div>
      {isRuLocale() && isOpenProductVideoModal && hasAuthenticated && isTimeToShowTheVideo && !Boolean(localStorage.getItem('viewedVideoAboutProduct')) && (
        <VideoModalDialogComponent setLocalStorage={setLocalStorage} close={closeProductVideoModal}>
          <ProductVideoModal />
        </VideoModalDialogComponent>
      )}
    </StartPageLayout>
  )
}
