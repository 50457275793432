/*
 File contains API to get and reset user notifications regarding to successfully paid credits
*/

import { backendUrl } from '../config/app'

/*
{
    id: 'C519BD28-5115-4388-9B6C-93E6CB4C6319',
    user_id: 53,
    notification_type: null,
    is_shown: false,
    created_at: 2024-08-05T14:33:34.923Z,
    details: null
  }
*/

export type PaymentNotificationDto = {
  id: string
  user_id: number
  notification_type: null | 'regular_payment_succeeded' | 'subscription_payment_succeeded'
  is_shown: boolean
  created_at: string
  details: string | null
}

export const getNotification = async (): Promise<Array<PaymentNotificationDto>> => {
  const res = await fetch(`${backendUrl()}api/payment/notifications`, {
    headers: {
      'Content-Type': 'application/json',
    },
  })

  const data = await res.json()

  return data
}

export const unsetNotification = async (id: string) => {
  const res = await fetch(`${backendUrl()}api/payment/notifications`, {
    method: 'post',
    body: JSON.stringify({ id }),
    headers: {
      'Content-Type': 'application/json',
    },
  })

  const data = await res.json()

  return data
}
