import s from './modal.module.css'
import image from './popup_download_image.png'
import { Button } from '../../components/Button'
import { reachGoal } from '../../utils/metrics'
import { useEffect, useContext } from 'react'
import { SharedProps } from '../../components/ModalDialog/ModalDialog'
import cx from 'clsx'
import { useModalManager } from '../../store/useModalManager'
import { ReactComponent as DollarSymbol } from './dollar_symbol.svg'
import {PaywallVariations, useSubscriptions} from "../../store/useSubscriptions";
import {MODAL_NAME as PAYWALL_MODAL} from "./PayWallModal";
import {FeatureFlagContext} from "../../contexts/FeatureFlagContext";
import {useProjects} from "../../store/useProjects";
import { useTranslation } from 'react-i18next'

export type DownloadImageProps = {
  onCreateNewCard: () => void
  onBuyClick: () => void
}

export function DownloadImage(props: DownloadImageProps & SharedProps) {
    const { onBuyClick, onCreateNewCard, close } = props
    useEffect(() => reachGoal('open_download_popup'), [])
    const hasActiveSubscription = useSubscriptions(s => s.hasActiveSubscription)
    const hasBusinessSubscription = useSubscriptions((s) => s.hasBusinessSubscription)
    const hasPremiumSubscription = useSubscriptions((s) => s.hasPremiumSubscription)
    const setPaywallVariation = useSubscriptions(s => s.setPaywallVariation)
    const toggleModal = useModalManager((s) => s.toggleModal)
    const { isAB } = useContext(FeatureFlagContext)
    const projectsCount = useProjects(s => s.projects.length)
    const { t } = useTranslation()
    const checkCreatePossible = () => {
        if (projectsCount >= 100 && !hasBusinessSubscription) {
            setPaywallVariation(PaywallVariations.createMoreBusiness)
            toggleModal(PAYWALL_MODAL, true)
            return false
        } else if (projectsCount >= 10 && !hasPremiumSubscription) {
            setPaywallVariation(PaywallVariations.createMorePremium)
            toggleModal(PAYWALL_MODAL, true)
            return false
        } else if ( !hasActiveSubscription && projectsCount > 0) {
            setPaywallVariation(PaywallVariations.createMore)
            toggleModal(PAYWALL_MODAL, true)
            return false
        } else {
            return true
        }
    }

    const onBuyCreditsClick = () => {
    reachGoal('click_buy_credits_on_download_popup')
    onBuyClick()
    close()
    }

    return (
    <div>
      <img className={s.imgPopup} src={image} />
      <h1 className={cx(s.mainTextPopup, 'titleM')}>{t('pages.downloadModal.done')}</h1>
      <div className={cx(s.minorTextPopup, 'paragraphM')}>
        <p>
          {t('pages.downloadModal.generationsNeededForNewCards')}
        </p>
      </div>
      <div className={s.popupButtons}>
        <Button
          size="m"
          variation="tertiary"
          grow={false}
          onClick={() => {
            close()
            if(checkCreatePossible()) {
                onCreateNewCard()
            }
          }}
        >
          {t('pages.createNewCardModal.createNewCard')}
        </Button>
        <Button
          size="m"
          grow={false}
          onClick={() => {
            onBuyCreditsClick()
          }}
        >
          <div className={s.buttonLabel}>
            <DollarSymbol style={{ width: '20px', height: '20px' }} />
            {t('pages.downloadModal.goToPlans')}
          </div>
        </Button>
      </div>
    </div>
    )
}
