import { PropsWithChildren } from 'react'
import styles from './button.module.css'
import { isEnLocale, isRuLocale } from '../../components/new-editor/utils/localization.utils'

/**
 * @deprecated since Jun 21 2024 by iSavi
 * @param props
 * @returns
 */
export function WriteTelegramBtn(
  props: PropsWithChildren<{ onClick: () => void; pos: 'left' | 'right' }>
) {
  const { onClick, pos, children } = props

  return (
    <>
      {isRuLocale() && (
        <a
          target="_blank"
          href="https://t.me/mpcardai_support"
          onClick={onClick}
          className={pos === 'left' ? styles.buttonLeft : styles.buttonRight}
        >
          <span className="bodyM colorTextWhitePrimary">{children}</span>
        </a>
      )}
      {isEnLocale() && (
        <div onClick={onClick} className={pos === 'left' ? styles.buttonLeft : styles.buttonRight}>
          <span className="bodyM colorTextWhitePrimary">{children}</span>
        </div>
      )}
    </>
  )
}
