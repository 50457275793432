import style from './RadioButtonForIconsCard.module.css'

export type RadioButtonForIconsCardProps = {
  selection: boolean
  title: string
  text: string
  arrayMarketPlace: Array<string>
  onClick: () => void
}

export const RadioButtonForIconsCard = ({
  title,
  text,
  arrayMarketPlace,
  onClick,
  selection,
}: RadioButtonForIconsCardProps) => {
  return (
    <div
      className={`${style.big_buttom_element} big_button_selection ${selection ? 'active' : ''}`}
      onClick={() => {
        onClick()
      }}
    >
      <div className={style.big_buttom_main_text}>{title}</div>
      <div className={style.big_buttom_minor_text}>{text}</div>
      <div className={style.big_buttom_mp_line}>
        {arrayMarketPlace.map((element: any) => {
          return <img className={style.big_buttom_mp_element} src={element} />
        })}
      </div>
    </div>
  )
}
