import {Button} from "../Button";
import React from "react";
import {ReactComponent as DownloadIcon} from '../icons/DownloadIcon.svg'
import {useDownload} from "../../hooks/useDownload";
import { useTranslation } from 'react-i18next'

export const DownloadButton = () => {
    const { onDownloadClick } = useDownload()
    const { t } = useTranslation()
    return (
        <div>
            <Button size="s" grow={false} onClick={onDownloadClick} className="editor-hidden-mobile">
                <div className="dwlButton">
                    <DownloadIcon />
                    <div>{t('pages.header.download')}</div>
                </div>
            </Button>
        </div>
    )
}