export const resizeImage = (file: File, maxSize: number): Promise<File> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = (event) => {
      const img = new Image()
      img.src = event.target?.result as string
      img.onload = () => {
        const { width, height } = img
        /*if (width <= maxSize && height <= maxSize) {
          resolve(new File([file], `${file.name.split('.')[0]}.webp`, { type: 'image/webp' }))
          return
        }*/
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')
        const scale = Math.min(maxSize / width, maxSize / height, 1)
        canvas.width = width * scale
        canvas.height = height * scale
        ctx?.drawImage(img, 0, 0, canvas.width, canvas.height)
        canvas.toBlob((blob) => {
          if (blob) {
            resolve(new File([blob], `${file.name.split('.')[0]}.webp`, { type: 'image/webp' }))
          } else {
            reject(new Error('Canvas is empty'))
          }
        }, 'image/webp')
      }
      img.onerror = (error) => reject(error)
    }
    reader.onerror = (error) => reject(error)
  })
}


export const file2Blob = (file: File): Blob => new Blob([file], { type: file.type })
export const blob2File = (blob: Blob, fileName: string) => new File([blob], fileName, { type: blob.type })

export const resizeFromBlob = async (blobData: Blob, size: number): Promise<Blob> => {

  const f = blob2File(blobData, 'file.webp')

  const image = await resizeImage(f, size)

  return file2Blob(image)
}
