import Header from '../components/Header/Header'
import Login from '../auth/Login'
import {ScrollLayout} from '../components/layouts/ScrollLayout';

export function SignInPage() {
  return (
    <ScrollLayout header={<Header />}>
      <Login />
    </ScrollLayout>
  )
}
