import { create } from 'zustand'

export type ModalManagerState = {
  dlgRegistry: Record<string, boolean>
}

export type ModalManagerActions = {
  registerDialog: (name: string, state?: boolean) => void
  toggleModal: (name: string, status: boolean, closeOthers?: boolean) => void
}

export const useModalManager = create<ModalManagerState & ModalManagerActions>()((set) => ({
  dlgRegistry: {},
  registerDialog: (name: string, s: boolean = false) =>
    set((state) => ({
      ...state,
      dlgRegistry: { ...state.dlgRegistry, [name]: !!s },
    })),
  toggleModal: (name: string, status: boolean, closeOthers = false) =>
    set((s) => {
      if (closeOthers) {
        const newRegistry = { ...s.dlgRegistry }
        const keys = Object.keys(newRegistry)
        for (const n of keys) {
          newRegistry[n] = false
        }
        return {
          ...s,
          dlgRegistry: { ...newRegistry, [name]: status },
        }
      } else {
        return {
          ...s,
          dlgRegistry: { ...s.dlgRegistry, [name]: status },
        }
      }
    }),
}))
