import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import ru from './locales/ru/translation.json'
import en from './locales/en/translation.json'

const REACT_APP_LOCALE = process.env.REACT_APP_LOCALE

i18n
  .use(initReactI18next)
  .init({
    lng: REACT_APP_LOCALE,
    fallbackLng: 'ru',
    interpolation: {
      escapeValue: false,
      format: (value, format, lng) => {
        if (format === 'number') return new Intl.NumberFormat(lng).format(value);
        return value;
      }
    },
    resources: {
      en: { translation: en },
      ru: { translation: ru },
    },
  });

export default i18n;
