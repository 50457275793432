import { PropsWithChildren } from 'react'
import s from './tabs.module.css'
import cx from 'clsx'

export type TabSize = 'm' | 's'
export type TabProps = {
  isActive?: boolean
  size?: TabSize
  onClick?: () => void
}

const getSizeClass = (v: TabSize): string => s[`size-${v}`]

export function Tab(props: PropsWithChildren<TabProps>) {
  const { isActive, children, size = 's', onClick } = props

  return (
    <div className={cx(s.Tab, getSizeClass(size), { [s.active]: isActive })} onClick={onClick}>
      <div className={s.TabLabel}>{children}</div>
    </div>
  )
}
