import { isRuLocale } from "./localization.utils"

export const hasCredits = (credits: number | null): boolean => !!credits && (credits > 0 || credits === -1)

const formatterRussianRubles = new Intl.NumberFormat('ru-RU', {
  style: 'currency',
  currency: 'RUB',
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
})

const formatterUsaDollars = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
})

export const getCurrencyFormat = (amount: number) => {
  return isRuLocale() ? formatterRussianRubles.format(amount) : formatterUsaDollars.format(amount)
}