import projectsError from './projects-error.svg'
import s from './ProjectsError.module.css'
import { Button } from '../../Button'
import { useTranslation } from 'react-i18next'

export type ProjectsErrorProps = {
  onReload?: () => void
}

export function ProjectsError(props: ProjectsErrorProps) {
  const { onReload } = props
  const { t } = useTranslation()

  return (
    <div className={s.NoProjectsContainer}>
      <div className={s.image}>
        <img src={projectsError} />
      </div>

      <div className={s.Content}>
        <div className={s.Header}>{t('pages.fileUploadScreen.somethingWentWrong')}</div>

        {onReload && (
          <div className={s.SubHeader}>
            <Button onClick={onReload} grow={false}>
              {t('pages.fileUploadScreen.refresh')}
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}
