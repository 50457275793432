import { useSubscriptions } from '../store/useSubscriptions'
import { fetchActiveSubscriptions } from '../api/fetchActiveSubscriptions'
import { reachGoal } from '../utils/metrics'
import { PaymentNotificationDto, getNotification, unsetNotification } from '../api/notifications'
import { useModalManager } from '../store/useModalManager'
import {
  MODAL_NAME_SUBSCRIPTION,
  MODAL_NAME as PAID_MODAL,
} from '../ds/modal/SuccessfullyPaidModal'
import { getTranslateKey } from './getTranslateKey'
import { useTranslation } from 'react-i18next'

export function useAfterAuthOps() {
  const toggleModal = useModalManager((s) => s.toggleModal)
  const { t } = useTranslation()
  const setSubscriptions = useSubscriptions((s) => s.setActiveSubscriptions)

  const fetchUserSubscription = async () => {
    const sbs = await fetchActiveSubscriptions()
    setSubscriptions(sbs)
  }

  const processNotifications = async () => {
    const data: Array<PaymentNotificationDto> = await getNotification()
    const notificationsToUnset: Promise<void>[] = []
    if (data.length > 0) {
      data.forEach((n) => {
        if (n.notification_type == 'regular_payment_succeeded' || n.notification_type == null) {
          if (n.details) {
            try {
              const pDetails: any = JSON.parse(n.details)
              if (pDetails.creditsBought) {
                reachGoal(`purchase_generations_${pDetails.creditsBought}`, {
                  order_price: pDetails.sum,
                })
              } else {
                // fallback
                reachGoal(`purchase_generations`)
              }
            } catch (e) {
              console.error(e)
            }
          }

          toggleModal(PAID_MODAL, true)
        }

        if (n.notification_type == 'subscription_payment_succeeded') {
          reachGoal('credits-purchase')
          if (n.details) {
            try {
              const pDetails: any = JSON.parse(n.details)
              if (pDetails.plan) {
                window.PURCHASE_PLAN = t('pages.plans.' + getTranslateKey(pDetails.plan))
                reachGoal(`purchase_plan_${pDetails.plan.name}`, { order_price: pDetails.sum })
                if (pDetails.isUpgrade) {
                  reachGoal(`upgrade_plan_to_${pDetails.plan.name}`) 
                }
              } else {
                // fallback
                reachGoal(`purchase_plan`)
              }
            } catch (e) {
              console.error(e)
            }
          }
          toggleModal(MODAL_NAME_SUBSCRIPTION, true)
        }

        notificationsToUnset.push(unsetNotification(n.id))
      })
    }
    if (notificationsToUnset.length > 0) {
      await Promise.allSettled(notificationsToUnset)
    }
  }

  return async () => {
    await processNotifications()
    fetchUserSubscription()
  }
}
