export const backendUrl = (): string => process.env.REACT_APP_BACKEND_URL ?? '/'

/**
 * @deprecated since Aug 6, 2024 by iSavi
 * @returns
 */
export const areSubscriptionsEnabled = (): boolean =>
  process.env.REACT_APP_ENABLE_SUBSCRIPTIONS
    ? process.env.REACT_APP_ENABLE_SUBSCRIPTIONS == 'true'
    : false
