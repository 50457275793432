import { useAuthContext } from '../contexts/AuthProvider'
import { Outlet } from 'react-router-dom'
import { CompleteRegistrationPage } from './CompleteRegistrationPage'
import { isRuLocale } from '../components/new-editor/utils/localization.utils'

export function CompletedRegistrationProtectedPage() {
  const { hasCompletedRegistration } = useAuthContext()

  if (isRuLocale() && !hasCompletedRegistration) {
    return <CompleteRegistrationPage />
  }

  return <Outlet />
}
