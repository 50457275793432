import { useEffect, useRef } from 'react'
import { setSupportVisibility } from '../../utils/internationalSupport'
import { useResize } from '../../hooks/useResize'
import { useAuthContext } from '../../contexts/AuthProvider'

export type SupportWidgetToggleProps = {
  isOpenMobileMenu: boolean
}

export function SupportWidgetToggle(props: SupportWidgetToggleProps) {
  const { isScreenDesktop } = useResize()
  const { hasAuthenticated } = useAuthContext()
  const isOpenMobileMenu = props.isOpenMobileMenu

  const hasAuthenticatedRef = useRef(hasAuthenticated)
  useEffect(() => {
    hasAuthenticatedRef.current = hasAuthenticated //Otherwise Tawk_API.onLoad has outdated value
  }, [hasAuthenticated])

  const updateWidgetVisibility = () => {
    const needShowSupportWidget =
      isScreenDesktop || isOpenMobileMenu || !hasAuthenticatedRef.current
    setSupportVisibility(needShowSupportWidget)
  }

  useEffect(() => {
    updateWidgetVisibility()
  }, [isOpenMobileMenu, isScreenDesktop, hasAuthenticated])

  useEffect(() => {
    if(!window.Tawk_API) return
    window.Tawk_API.onLoad = () => {
      updateWidgetVisibility()
    }
  }, [])

  return null
}
