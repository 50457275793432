import s from './modal.module.css'
import { useCredits } from '../../store/useCredits'
import { Button } from '../../components/Button'
import { SharedProps } from '../../components/ModalDialog/ModalDialog'
import { reachGoal } from '../../utils/metrics'
import boxStarImage from './box-star.png'
import { useEffect } from 'react'
import cx from 'clsx'
import { useTranslation } from 'react-i18next'

export const MODAL_NAME = 'buyCredits'

export type BuyCreditsModalProps = {
  onBuyClick: () => void
}

export function BuyCreditsModal(props: SharedProps & BuyCreditsModalProps) {
  const { close, onBuyClick } = props
  const credits = useCredits((s) => s.credits)
  const { t } = useTranslation()

  const onSendMessageClick = () => {
    reachGoal('click_get_100_credits_in_credits_popup')
    close()
  }

  const onBuyCreditsClick = () => {
    reachGoal('click_buy_credits')
    onBuyClick()
    close()
  }

  useEffect(() => reachGoal('open_credits_popup'), [])

  return (
    <div>
      <img className={s.imgPopup} src={boxStarImage} />

      <h1 className={cx(s.mainTextPopup, 'titleM')}>{t('pages.buyCreditsModal.allGenerationsUsed')}</h1>

      <div className={cx(s.minorTextPopup, 'paragraphM')}>
        <p>
          <div dangerouslySetInnerHTML={{ __html: t('pages.buyCreditsModal.generationsInCurrentPlanExhausted') }} />
        </p>
      </div>

      <div className={s.popupButtons}>
        <div className={s.footerContainer}>
          <Button size="m" onClick={onBuyCreditsClick}>
            <div className={s.buttonInput}>
              <div className={s.buttonText}>
                {t('pages.buyCreditsModal.goToPricingPlans')}
              </div>
            </div>
          </Button>
        </div>
      </div>
    </div>
  )
}
