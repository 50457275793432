import { CleanLayout } from '../components/layouts/CleanLayout'
import Header from '../components/Header/Header'
import CompleteRegistration from '../auth/CompleteRegistration'

export function CompleteRegistrationPage() {
  return (
    <CleanLayout header={<Header />}>
      <CompleteRegistration />
    </CleanLayout>
  )
}
