import { create } from 'zustand'
/**
 * @deprecated since Aug 14, 2024 by iSavi. Use router pages instead of Screens
 */
export type Screen =
  | 'None'
  | 'fileUpload'
  | 'RegisterSuccess'
  | 'Login'
  | 'forgotPassword'
  | 'registration'
  | 'editor'
  | 'plans'

/**
 * Props to extend exists components than want to change active app screen
 */
export type WithSetScreenProps = {
  setScreen: (scr: Screen) => void
}

export const SCREEN_NONE: Screen = 'None'
export const SCREEN_UPLOAD_FILE: Screen = 'fileUpload'
export const SCREEN_REGISTER_SUCCESS: Screen = 'RegisterSuccess'
export const SCREEN_LOGIN: Screen = 'Login'
export const SCREEN_FORGOT_PASSWORD: Screen = 'forgotPassword'
export const SCREEN_REGISTRATION: Screen = 'registration'
export const SCREEN_EDITOR: Screen = 'editor'
export const SCREEN_PLANS: Screen = 'plans'

export type ScreenState = {
  activeScreen: Screen
  previousScreen: Screen | null
}

export type ScreenActions = {
  setActiveScreen: (v: Screen) => void
}

/**
 * @deprecated since Aug 14, 2024 by iSavi. Use router pages instead of Screens
 */
export const useScreenManager = create<ScreenState & ScreenActions>()((set) => ({
  activeScreen: SCREEN_REGISTRATION,
  previousScreen: null,
  setActiveScreen: (val: Screen) =>
    set((state) => ({
      ...state,
      previousScreen: state.activeScreen,
      activeScreen: val,
    })),
}))
