import axios from 'axios'
import { nanoid } from 'nanoid'
import { backendUrl } from '../config/app'

export type EventScope = 'user' | 'editor' | 'file' | 'ai'

type EventName = `${EventScope}:${string}`

export interface IInternalEvent {
  projectId: string
  data?: string
  dataJson?: any
}

export interface IInternalEventTyped<T> {
  projectId: string
  data?: string
  dataJson?: T
}

export const logEvent = async (eventName: EventName, e: IInternalEvent) => {
  if (!e.projectId) {
    console.log('Event with no project id')
  }
  axios.post(
    `${backendUrl()}api/analytics`,
    {
      project_id: e.projectId,
      event_name: eventName,
      data: e.data?.toString(),
      data_json: JSON.stringify(e.dataJson),
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
}

export const createProjectId = () => {
  const id = nanoid(10)
  window.MPCARD_PID = id
  return id
}

export const getProjectId = () => window.MPCARD_PID ?? ''
export const setProjectId = (id: string | undefined) => {
  if (id) {
    window.MPCARD_PID = id
  }
}

export const logEventT = async <T>(eventName: EventName, e: IInternalEventTyped<T>) =>
  logEvent(eventName, e)
