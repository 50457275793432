import {useModalManager} from "../../../store/useModalManager";
import React, {useEffect} from "react";
import {MODAL_NAME as OPEN_OVERLAY} from "../../../ds/modal/OverlayModal";
import {SectionTab} from "polotno/side-panel";
import { ReactComponent as InfographicsIcon } from '../infographics.svg'
import {InfographicsPanel} from "./Infographics/InfographicsPanel";
import {observer} from "mobx-react-lite";
import {StoreType} from "polotno/model/store";
import {useResize} from "../../../hooks/useResize";
import { useTranslation } from 'react-i18next'

export const InfographicsTool = {
    name: 'templates',
    Tab: (props: any) => {
        const toggleModal = useModalManager((s) => s.toggleModal)
        const { t } = useTranslation()

        const onClickHandler = () => {
            props.onClick()
            toggleModal(OPEN_OVERLAY, !props.active)
        }

        return(
            <SectionTab name={t('pages.editor.infographics')} {...props} onClick={onClickHandler}>
                <InfographicsIcon />
            </SectionTab>
        )
    },
    // we need observer to update component automatically on any store changes
    Panel: observer(({ store }: { store: StoreType }) => {
        const { isScreenDesktop } = useResize()
        return (
            <InfographicsPanel store={store} isScreenDesktop={isScreenDesktop}/>
        )
    })
}