import { PaywallVariations } from '../store/useSubscriptions';
import { MODAL_NAME as PAYWALL_MODAL } from '../ds/modal/PayWallModal';
import { FileError } from 'react-dropzone'
import { useProjects } from '../store/useProjects';
import { useSubscriptions } from '../store/useSubscriptions';
import { useModalManager } from '../store/useModalManager';
import { useTranslation } from 'react-i18next';


export const useCheckDropPossible = () => {
  const projectsCount = useProjects(s => s.projects.length)
  const hasBusinessSubscription = useSubscriptions((s) => s.hasBusinessSubscription)
  const setPaywallVariation = useSubscriptions(s => s.setPaywallVariation)
  const toggleModal = useModalManager((s) => s.toggleModal)
  const hasPremiumSubscription = useSubscriptions((s) => s.hasPremiumSubscription)
  const hasActiveSubscription = useSubscriptions(s => s.hasActiveSubscription)
  const { t } = useTranslation()

  const checkDropPossible = () => {
    if (projectsCount >= 100 && !hasBusinessSubscription) {
      setPaywallVariation(PaywallVariations.createMoreBusiness);
      toggleModal(PAYWALL_MODAL, true);
      return { message: t('pages.fileUploadScreen.upgradeSubscriptionRequired'), code: '403' } as FileError;
    } else if (projectsCount >= 10 && !hasPremiumSubscription) {
      setPaywallVariation(PaywallVariations.createMorePremium);
      toggleModal(PAYWALL_MODAL, true);
      return { message: t('pages.fileUploadScreen.upgradeSubscriptionRequired'), code: '403' } as FileError;
    } else if (!hasActiveSubscription && projectsCount > 0) {
      setPaywallVariation(PaywallVariations.createMore);
      toggleModal(PAYWALL_MODAL, true);
      return { message: t('pages.fileUploadScreen.upgradeSubscriptionRequired'), code: '403' } as FileError;
    } else {
      return null;
    }
  }

  return checkDropPossible
}

export default useCheckDropPossible