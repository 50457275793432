import axios from 'axios'
import { FULL_FEATURES } from '../components/plans/plans-features' 
import { backendUrl } from '../config/app'
import { Subscription } from '../store/useSubscriptions'

export const subscriptionPlansTranslateKey = {
  standard: {
    keyInPlanFeatures: 'base',
    keyTitle: 'beginner'
  }, 
  premium: {
    keyInPlanFeatures: 'premium',
    keyTitle: 'seller'
  }, 
  business: {
    keyInPlanFeatures: 'business',
    keyTitle: 'business'
  }
}

export const fetchActiveSubscriptions = async () => {
  const response = await axios.get(`${backendUrl()}api/ngb/subscriptions`)

  const sbs = await response.data as Subscription[]

  getFeaturesListWithTranslate(sbs)

  return sbs
}

const getFeaturesListWithTranslate = (sbs: any) => {
  if(sbs[0]?.planDetails){
    try {
      const featureType: keyof typeof subscriptionPlansTranslateKey = sbs[0].planDetails.tier_type
      const selectedPlan: keyof typeof FULL_FEATURES = subscriptionPlansTranslateKey[featureType]?.keyInPlanFeatures as keyof typeof FULL_FEATURES
    
      sbs[0].planDetails.features_list = FULL_FEATURES[selectedPlan]
      sbs[0].planDetails.title = subscriptionPlansTranslateKey[featureType]?.keyTitle
    } catch (error) {
      console.error('Error with features list: ', error)
    }
  }
}
