import {getProjectId} from "../../../logger/logEvent";
import {outpaintBackground as outpaintBackgroundAPI} from "../../../api/outpaintBackground";
import {StoreType} from "polotno/model/store";

export const outpaintAndApplyBackground = async (
    backgroundUrl: string,
    objectMask: string,
    prompt: string,
    store: StoreType,
) => {
    const pid = getProjectId()
    const result = await outpaintBackgroundAPI(backgroundUrl, objectMask, prompt, pid)
    if (result.url) {
        store.activePage.set({ background: result.url })
    } else {
        console.error('Url is missing in response', result)
    }
}

export const getBackgroundWidth = async (store: StoreType): Promise<number> => {
    return new Promise((resolve, reject) => {
        const img = new Image()
        img.src = store.activePage.background
        img.onload = ()=> resolve(img.width)
        img.onerror = (ev) => reject(ev)
    })
}