import { create } from 'zustand'

export type CreditsState = {
  credits: number | null // null value is meaning credits are not fetched yet
}

export type CreditsActions = {
  setCredits: (credits: number) => void
}

export const useCredits = create<CreditsState & CreditsActions>()((set) => ({
  credits: null,
  setCredits: (val: number) =>
    set((state) => ({
      ...state,
      credits: val,
    })),
}))
