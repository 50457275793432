import { ReactComponent as Loader } from './purple_loader.svg'
import cx from 'clsx'
import s from './LoadingModal.module.css'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

export function LoadingModal({
  active,
  backgroundRemoved,
}: {
  active: boolean
  backgroundRemoved: boolean
}) {
  const [text, setText] = useState('')
  const { t } = useTranslation()
  const texts = [t('pages.loadingModal.identifyingProduct'), t('pages.loadingModal.writingTexts'), t('pages.loadingModal.creatingMagic')]

  if (backgroundRemoved) {
    texts.unshift(t('pages.loadingModal.removingBackground'))
  }
  useEffect(() => {
    const changeRate = 1000
    setText(texts[0])
    let i = 1
    const int = setInterval(() => {
      setText(texts[i])
      i++
      if (!texts[i]) clearInterval(int)
    }, changeRate)
  }, [active])

  return active ? (
    <div className={s.Loading_outer}>
      <div className={s.Loading_inner}>
        <Loader className={s.Loading_loader} />
        <h2 className={cx('bodyL', s.Loading_text)}>{text}</h2>
      </div>
    </div>
  ) : null
}
