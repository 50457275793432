import React, { createContext, useContext, useState, ReactNode } from 'react'

// Define the type for the context state
type LoadingContextType = {
  isLoading: boolean
  setLoading: (loading: boolean) => void
}

// Create a Context with default values
const LoadingContext = createContext<LoadingContextType>({
  isLoading: false,
  setLoading: () => {}, // noop function
})

// Define the type for the provider props
interface LoadingProviderProps {
  children: ReactNode
}

// Create a Provider Component
export const FullPageLoadingProvider: React.FC<LoadingProviderProps> = ({ children }) => {
  const [isLoading, setLoading] = useState<boolean>(false)

  return (
    <LoadingContext.Provider value={{ isLoading, setLoading }}>{children}</LoadingContext.Provider>
  )
}

// Hook to use the loading context
export const useFullPageLoading = () => useContext(LoadingContext)
