import { PropsWithChildren } from 'react'
import styles from './button.module.css'

/**
 * @deprecated since Jun 21 2024 by iSavi
 * @param props
 * @returns
 */
export function BackToEditBtn(
  props: PropsWithChildren<{ onClick: () => void; pos: 'left' | 'right' }>
) {
  const { onClick, pos, children } = props
  return (
    <a
      href=""
      onClick={onClick}
      className={pos === 'left' ? styles.buttonLeft : styles.buttonRight}
    >
      <span className="bodyM colorAccentVioletDefault">{children}</span>
    </a>
  )
}
