import { create } from 'zustand'

export type ValueType = { thumb: string; bg: string }
export type State = {
  selectedBg: Record<string, ValueType>
}

export type Actions = {
  addBackgroundPair: (thumbUrl: string, imageUrl: string) => void
  recreateFromExists: (data: Record<string, ValueType>) => void
}

export const useSelectedBackgrounds = create<State & Actions>()((set) => ({
  selectedBg: {},
  addBackgroundPair: (thumb: string, bg: string) =>
    set((state) => ({
      ...state,
      selectedBg: {
        ...state.selectedBg,
        [thumb]: {
          thumb,
          bg,
        },
      },
    })),
  recreateFromExists: (data) =>
    set((s) => ({
      ...s,
      selectedBg: { ...data },
    })),
}))
