import {
  Attributes,
  createElement,
  CSSProperties,
  ComponentType,
  ComponentPropsWithRef,
} from 'react'

export type GenericButtonComponent = keyof JSX.IntrinsicElements | ComponentType<any>

type GenericProps<C extends GenericButtonComponent = 'button'> = {
  component?: C
  className?: string
  style?: CSSProperties
} & Attributes

export type GenericButtonProps<C extends GenericButtonComponent = 'button'> =
  C extends keyof JSX.IntrinsicElements
    ? Omit<ComponentPropsWithRef<C>, keyof GenericProps<C>> & GenericProps<C>
    : C extends ComponentType<infer P>
      ? P extends ComponentPropsWithRef<any>
        ? Omit<P, keyof GenericProps<C>> & GenericProps<C>
        : never
      : never

export function GenericButton<C extends GenericButtonComponent = 'button'>({
  component = 'button',
  children,
  ...props
}: GenericButtonProps<C>) {
  return createElement(component, props, children)
}
