import axios from 'axios'

import { backendUrl } from '../config/app'
import { PromoCode } from '../domain/types/PromoCode'

export const checkPromoCode = async (code: string): Promise<PromoCode> => {
  if (!code || code.length === 0) {
    return { is_valid: false }
  }
  try {
    const response = await axios.get(`${backendUrl()}api/ngb/payments/promo-code?code=${code}`)
    return response.data as PromoCode
  } catch (e) {
    console.error('Error during checking promocode', e)
    return { is_valid: false }
  }
}
