import { create } from 'zustand'

export type PromoCodeState = {
  isApplied: boolean
  planIds: null | string[]
  code: string | null
  discount: number
}

export type PromoCodeActions = {
  setPromocode: (code: string | null, discount: number, planIds: null | string[]) => void
  reset: () => void
}

export const usePromocode = create<PromoCodeState & PromoCodeActions>()((set) => ({
  isApplied: false,
  planIds: null,
  code: null,
  discount: 0,
  reset: () =>
    set((state) => ({
      code: null,
      planIds: null,
      isApplied: false,
      discount: 0,
    })),
  setPromocode: (code: string | null, discount: number, plansIds = null) =>
    set((state) => ({
      ...state,
      code,
      discount,
      isApplied: true,
      planIds: plansIds,
    })),
}))
