import s from './tabs.module.css'

export type DiscountLabelProps = {
  percents: number
}

export function DiscountLabel(props: DiscountLabelProps) {
  const { percents } = props

  return <div className={s.DiscountLabel}>-{percents}%</div>
}
