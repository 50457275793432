import { useRef, useState, useContext} from 'react'
import { useDropzone } from 'react-dropzone'
import { useFullPageLoading } from '../../contexts/FullPageLoadingContext'
import { Button } from '../../components/Button'
import { ReactComponent as PlusIcon } from '../../components/icons/PlusIcon.svg'
import cx from 'clsx'
import s from './FileUploadScreen.module.css'
import { useTranslation } from 'react-i18next';
import { useCheckDropPossible } from '../../hooks/useCheckDropPossible'
import { onFileDrop } from '../../components/new-editor/utils/uploadImage.utils'
import { RemoveBackgroundToggle } from '../../components/new-editor/components/RemoveBackgroundToggle'

export type FileUploadScreenProps = {
  onFileUploaded: (url: string) => void
}


export function FileUploadScreen(props: FileUploadScreenProps) {
  const { onFileUploaded } = props
  const uploadRef = useRef<HTMLInputElement>(null)
  const { isLoading, setLoading } = useFullPageLoading()
  const { t } = useTranslation()
  const [removeBackground, setRemoveBackground] = useState(true)
  const checkDropPossible = useCheckDropPossible()

  const isDropPossible = () => {
    return checkDropPossible()
  }

  const onDropAccepted = async (files: File[]) => {
    setLoading(true)
    try {
      const image = await onFileDrop(files, removeBackground);
      onFileUploaded(image.imageUrl)
    } catch (error) {
      console.error("Error with drop file:", error);
    } finally {
      setLoading(false);
    }
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      'image/*': [],
    },
    onDropAccepted: onDropAccepted,
    multiple: false,
    noDragEventsBubbling: true,
    validator: isDropPossible,
  })

  const handleToggleChange = () => setRemoveBackground(!removeBackground)

  return (
    <div className={s.uploaderContainer}>
      <div className={s.uploader}>
        <div
          className={cx(s.uploader_content, { [s.dragActive]: isDragActive })}
          {...getRootProps()}
        >
          <div className={s.uploader_content_title}>
            <h2 className={cx(s.uploader_title_desktop, 'titleM', 'colorTextBlackPrimary')}>{t('pages.fileUploadScreen.uploadPhoto')}</h2> 
            <h2 className={cx(s.uploader_title_mobile, 'bodyM', 'colorTextBlackPrimary')}>{t('pages.fileUploadScreen.uploadPhoto')}</h2>
            <div className={s.SubHeader}>
              <h3 className={cx('paragraphL', 'colorTextBlackSecondary', s.dragAndDropText)}>
                {t('pages.fileUploadScreen.dragProductPhotoHere')}
              </h3>
            </div>
          </div>
          <div className={s.uploader_content_divider}>
            <div className="divider-section">
              <hr className="line" />
              <span className="paragraphM colorTextBlackSecondary orInUploader text-between-lines">
                {t('pages.registerScreen.or')}
              </span>
              <hr className="line" />
            </div>
          </div>
          <input id="upload" ref={uploadRef} type="file" className="hidden" {...getInputProps()} />
          <Button type="button" size='m' disabled={isLoading} grow={false} className={s.upload_button_desktop}>
            <div className={s.upload_button}>
              <PlusIcon />
              <div>
                <span>{t('pages.fileUploadScreen.selectPhoto')}</span>
              </div>
            </div>
          </Button>
          <Button type="button" size='s' disabled={isLoading} grow={false} className={s.upload_button_mobile}>
            <div className={s.upload_button}>
              <PlusIcon />
              <div>
                <span>{t('pages.fileUploadScreen.selectPhoto')}</span>
              </div>
            </div>
          </Button>
        </div>
      </div>
      <RemoveBackgroundToggle removeBackground={removeBackground} handleToggleChange={handleToggleChange}/>
    </div>
  )
}
