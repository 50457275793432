import { JSX } from 'react'
import cx from 'clsx'
import { GenericButton, GenericButtonProps, GenericButtonComponent } from './GenericButton'

import s from './Button.module.css'

export type ButtonSize = 'm' | 's' | 'xs'
export type ButtonVariation = 'primary' | 'secondary' | 'tertiary' | 'clean'

const getSizeClass = (v: ButtonSize): string => s[`size-${v}`]
const getVarClass = (v: ButtonVariation): string => s[v]

export type ButtonProps<C extends GenericButtonComponent = 'button'> = GenericButtonProps<C> & {
  loading?: boolean
  size?: ButtonSize
  variation?: ButtonVariation
  disabled?: boolean
  grow?: boolean
}

export type IconButtonProps<C extends GenericButtonComponent = 'button'> = GenericButtonProps<C> & {
  icon?: JSX.Element
} & Pick<ButtonProps, 'loading' | 'size' | 'disabled'>

//! DON'T DELETE
// export function Button<C extends GenericButtonComponent = 'button'>({
//   className,
//   loading,
//   disabled,
//   size = 's',
//   variation = 'primary',
//   ...props
// }: ButtonProps<C>) {
//   return (
//     <GenericButton<C>
//       className={cx(
//         s.button,
//         getSizeClass(size),
//         getVarClass(variation),
//         {
//           [s.loading]: loading,
//           [s.disabled]: disabled,
//         },
//         className,
//       )}
//       {...(props as GenericButtonProps<C>)}
//     />
//   )
// }

export function Button<C extends GenericButtonComponent = 'button'>({
  className,
  loading,
  disabled,
  size = 's',
  variation = 'primary',
  grow = true,
  ...props
}: ButtonProps<C>) {
  if (loading) {
    return (
      <GenericButton<C>
        className={cx(
          s.button,
          'bodyM',
          getSizeClass(size),
          getVarClass(variation),
          s.disabled,
          s.loading,
          { [s.fw]: grow },
          className
        )}
        {...(props as GenericButtonProps<C>)}
        disabled={disabled}
      >
        {props.children}
        <div className={cx(s.loader, size, variation)}></div>
      </GenericButton>
    )
  }
  return (
    <GenericButton<C>
      className={cx(
        s.button,
        'bodyM',
        getSizeClass(size),
        getVarClass(variation),
        {
          [s.loading]: loading,
          [s.disabled]: disabled,
          [s.fw]: grow,
        },
        className
      )}
      {...(props as GenericButtonProps<C>)}
      disabled={disabled}
    />
  )
}

export function IconButton<C extends GenericButtonComponent = 'button'>({
  className,
  loading,
  disabled,
  icon,
  size = 's',
  ...props
}: Omit<IconButtonProps<C>, 'children'>) {
  return (
    <GenericButton<C>
      className={cx(
        s.iconButton,
        getSizeClass(size),
        { [s.disabled]: disabled || loading, [s.loading]: loading },
        className
      )}
      {...(props as GenericButtonProps<C>)}
    >
      {loading && <div className={cx(s.loader, size)}></div>}

      {!loading && <div className={s.svgContent}>{icon}</div>}
    </GenericButton>
  )
}
