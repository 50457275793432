import { ReactComponent as GradientRounded } from '@material-design-icons/svg/round/gradient.svg'
import { FormControlLabel, Switch } from '@mui/material'
import cx from 'clsx'
import { useTranslation } from 'react-i18next';
import s from './RemoveBackgroundToggle.module.css'

interface RemoveBackgroundToggleProps {
  removeBackground: boolean,
  handleToggleChange: () => void
}

export const RemoveBackgroundToggle: React.FC<RemoveBackgroundToggleProps> = ({ removeBackground, handleToggleChange }) => {
  const { t } = useTranslation()

  const toggleHandler = () => {
    handleToggleChange()
  }

  return(
    <div className={cx('bodyM', s.removeBackgroundToggle)}>
        <GradientRounded className={s.removeBackgroundIcon} />
        <FormControlLabel
          control={<Switch checked={removeBackground} onChange={toggleHandler} />}
          className={s.toggle}
          label={<span className={cx(s.toggleLabel, 'bodyM')}>{t('pages.fileUploadScreen.removeBackground')}</span>}
          labelPlacement="start"
        />
    </div>
  )} 