import axios from 'axios'
import { backendUrl } from '../config/app'
import { getDomainName } from '../components/new-editor/utils/localization.utils'

export const sendUtmData = () => {
  const utmParams = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content', 'utm_term']

  const getCookie = (name: string): string | null => {
    return (
      document.cookie
        .split('; ')
        .find((row) => row.startsWith(`${name}=`))
        ?.split('=')[1] || null
    )
  }

  const deleteCookie = (name: string): void => {
    document.cookie = `${name}=; path=/; domain=.${getDomainName()}; max-age=0`
  }

  const utmData = Object.fromEntries(
    utmParams.map((param) => [param, getCookie(param)]).filter(([, value]) => value)
  )

  if (utmData['utm_source']) {
    axios
      .post(`${backendUrl()}api/user_source`, utmData, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(() => utmParams.forEach(deleteCookie))
  }
}
