import s from './replaceObject.module.css'
import { useRef, useState } from 'react'
import { Button } from '../../components/Button'
import { SharedProps } from '../../components/ModalDialog/ModalDialog'
import cx from 'clsx'
import { useDropzone } from 'react-dropzone'
import { ReactComponent as PlusIconColor } from '../../components/icons/PlusIconColor.svg'
import { useFullPageLoading } from '../../contexts/FullPageLoadingContext'
import { onReplaceObject } from '../new-editor/utils/replaceObject.utils'
import { useReplaceObject } from '../../store/useReplaceObject'
import { onFileDrop } from '../new-editor/utils/uploadImage.utils'
import {useCards} from "../new-editor/aicard/aiCardsContext";
import { useTranslation } from 'react-i18next'
import { RemoveBackgroundToggle } from '../new-editor/components/RemoveBackgroundToggle'

export function ReplaceObject(props: SharedProps) {
  const { close } = props
  const { setLoading, isLoading } = useFullPageLoading()
  const {resetBackgrounds} = useCards()
  const uploadRef = useRef<HTMLInputElement>(null)
  const replaceableObject = useReplaceObject(s => s.replaceableObject)
  const { t } = useTranslation()
  const [removeBackground, setRemoveBackground] = useState(true)

  const onDropAccepted = async (files: File[]) => {
    setLoading(true)
    try {
      const image = await onFileDrop(files, removeBackground);

      await onReplaceObject(image.imageUrl, replaceableObject);
      resetBackgrounds()
      close();
    } catch (error) {
      console.error("Error with drop file:", error);
    } finally {
      setLoading(false);
    }
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      'image/*': [],
    },
    onDropAccepted: onDropAccepted,
    multiple: false,
    noDragEventsBubbling: true,
  })

  const handleToggleChange = () => setRemoveBackground(!removeBackground)

  return (
    <div className={s.uploaderContainer}>
      <h2 className={`titleM colorTextBlackPrimary ${s.uploaderPopupTitle}`}>{t('pages.fileUploadScreen.uploadPhoto')}</h2>
      <div className={s.uploader}>
        <div
          className={cx(s.uploader_content, { [s.dragActive]: isDragActive })}
          {...getRootProps()}
        >
          <div className={s.uploader_content_title}>
            <div className={s.SubHeader}>
              <h3 className="paragraphM colorTextBlackTertiary dragAndDropText">
                {t('pages.editor.dragProductPhotoHere')}
              </h3>
            </div>
          </div>
          <div className={s.dividerSectionInPopup}>
            <hr className={s.lineUploaderInPopup} />
            <span className="paragraphM colorTextBlackSecondary orInUploader text-between-lines">
              {t('pages.registerScreen.or')}
            </span>
            <hr className={s.lineUploaderInPopup} />
          </div>
          <input id="upload" ref={uploadRef} type="file" className="hidden" {...getInputProps()} />
          <Button className={s.selectButton} type="button" variation = 'secondary' size="m" disabled={isLoading} grow={false}>
            <div className={s.upload_button}>
              <PlusIconColor/>
              <div>
                <span>{t('pages.fileUploadScreen.selectPhoto')}</span>
              </div>
            </div>
          </Button>
          <div className={s.SubHeaderMobile}>
            <PlusIconColor/>
            <h3 className="bodyS colorAccentVioletDefault dragAndDropText">
              {t('pages.fileUploadScreen.selectPhoto')}
            </h3>
          </div>
        </div>
      </div>
      <RemoveBackgroundToggle removeBackground={removeBackground} handleToggleChange={handleToggleChange}/>
    </div>

  )
}
