import {PaywallVariations, useSubscriptions} from "../../../store/useSubscriptions";
import {useModalManager} from "../../../store/useModalManager";
import { getCenterPlacement } from "../utils/editor.utils";
import {MODAL_NAME as PAYWALL_MODAL} from "../../../ds/modal/PayWallModal";
import {SectionTab} from "polotno/side-panel";
import { ReactComponent as TextIcon } from '../text.svg'
import {usePolotno} from "../../../store/usePolotno";
import { useTranslation } from 'react-i18next'
import { useClosePanelInMobile } from '../../../hooks/useClosePanelInMobile'

export default {
    name: 'texts',
    Tab: (props: any) => {
        const store = usePolotno(s => s.store)
        const hasActiveSubscription = useSubscriptions(s => s.hasActiveSubscription)
        const setPaywallVariation = useSubscriptions(s => s.setPaywallVariation)
        const toggleModal = useModalManager((s) => s.toggleModal)
        const { t } = useTranslation()
        const closePanelInMobile = useClosePanelInMobile()

        const onTabOpen = async () => {
            closePanelInMobile()
            const textWidth = 240
            const textHeight = 32
            const { x, y } = getCenterPlacement(store.width, store.height, textWidth, textHeight)
            const text = store.activePage.addElement({
                type: 'text',
                placeholder: t('pages.editor.editableText'),
                fontWeight: 'bold',
                fontSize: textHeight,
                width: textWidth,
                height: textHeight,
                x: x,
                y: y,
            })
            text.moveTop()
        }
        const onTabClick = () => {
            if ( !hasActiveSubscription) {
                setPaywallVariation(PaywallVariations.addText)
                toggleModal(PAYWALL_MODAL, true)
            } else {
                onTabOpen()
            }
        }
        return (
            <SectionTab name={t('pages.editor.addText')} {...props}  onClick={onTabClick} >
                <TextIcon />
            </SectionTab>
        )
    },
}