import { create } from "zustand";

type RplaceObjectStore = {
    replaceableObject: any,
    setReplaceableObject: (o: any) => void,
}

export const useReplaceObject = create<RplaceObjectStore>((set) => ({
    replaceableObject: null,
    setReplaceableObject: (replaceableObject: any) => set(() => ({replaceableObject})),
}))