import { useResize } from "./useResize"
import { usePolotno } from "../store/usePolotno"
import { useModalManager } from "../store/useModalManager"
import { MODAL_NAME as OPEN_OVERLAY } from "../ds/modal/OverlayModal"

export const useClosePanelInMobile = () => {
  const {isScreenDesktop} = useResize()
  const store = usePolotno(s => s.store)
  const toggleModal = useModalManager((s) => s.toggleModal)

  const closePanelInMobile = () => {
    if(!isScreenDesktop){
      store.openSidePanel('')
      toggleModal(OPEN_OVERLAY, false)
    }
  }

  return closePanelInMobile
}

export default useClosePanelInMobile