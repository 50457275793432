import {PaywallVariations, useSubscriptions} from "../../../../store/useSubscriptions";
import {useModalManager} from "../../../../store/useModalManager";
import {MODAL_NAME as PAYWALL_MODAL} from "../../../../ds/modal/PayWallModal";
import {SectionTab} from "polotno/side-panel";
import {observer} from "mobx-react-lite";
import MultiAssetPanel from "./MultiAssetPanel";
import { ReactComponent as AddToPhotos } from '@material-design-icons/svg/round/add_to_photos.svg'
import {addProjectObject, createNewPage} from '../../utils/polotno.utils.js'
import {infoTemplates} from "../../aicard/infographics";
import {noop} from "lodash";
import {StoreType} from "polotno/model/store";
import {usePolotno} from "../../../../store/usePolotno";
import {replaceAboutTextInfographicsTemplate, replaceTitleInfographicsTemplate} from "../../utils/infographics.utils";
import { useTranslation } from 'react-i18next'
import {MODAL_NAME as OPEN_OVERLAY} from "../../../../ds/modal/OverlayModal";
import {addPageTemplate} from "../../utils/editor.utils";
import { useResize } from "../../../../hooks/useResize";


export const MultiAssetTool = {
    name: 'multi-asset',
    Tab: (props: any) => {
        const hasPremiumSubscription = useSubscriptions(s => s.hasPremiumSubscription)
        const setPaywallVariation = useSubscriptions(s => s.setPaywallVariation)
        const toggleModal = useModalManager((s) => s.toggleModal)
        const { t } = useTranslation()
        const onTabClick = () => {
            if (!hasPremiumSubscription) {
                setPaywallVariation(PaywallVariations.multiAsset)
                toggleModal(PAYWALL_MODAL, true)
            } else {
                props.onClick()
                toggleModal(OPEN_OVERLAY, !props.active)
            }
        }

        return (
            <div>
                <div className="tab-wrapper divider dividerDesktop">
                    <div className="tab-divider" />
                    <SectionTab name={t('pages.editor.photoFunnel')} {...props} onClick={onTabClick}>
                        <AddToPhotos />
                    </SectionTab >
                </div>

                <div className='sectionTabMobile'>
                    <SectionTab name={t('pages.editor.photoFunnel')} {...props} onClick={onTabClick}>
                        <AddToPhotos />
                    </SectionTab >
                </div>
            </div>
        )
    },
    Panel: observer(({ store }: { store: any }) => {
        const infographics = usePolotno(s => s.infographics)
        const product = usePolotno(s => s.product)
        const { isScreenDesktop } = useResize()

        const applyInfographics = async (store: StoreType) => {
            await store.waitLoading()
            const template = infoTemplates.find((t: { key: any; }) => t.key === store.custom.activeInfographicsTemplate)
            if(!template) return
            const jsonTemplate = store.custom.cardSize === '1_1' && template.jsonSquareTemplate? template.jsonSquareTemplate : template.jsonRectangleTemplate
            const oldPage = store.pages[store.pages.length - 1];
            await addPageTemplate(store, oldPage, JSON.parse(jsonTemplate), product,
                { infographicsData: infographics, replaceTitleInfographicsTemplate, element: template, replaceAboutTextInfographicsTemplate, setPositionChanged: noop})
            store.deletePages([oldPage.id])
        }
        const addNewPage = async (store: any, imageUrl: string) => {
            await createNewPage(store)
            await addProjectObject(store, imageUrl)
            if(store.custom.activeInfographicsTemplate) {
                await applyInfographics(store)
            }
        }
        return <MultiAssetPanel store={store} addImage={addNewPage} isScreenDesktop={isScreenDesktop}></MultiAssetPanel>
    }),
}