import { PropsWithChildren } from 'react'
import s from './GenBgAdvicePanel.module.css'
import { useTranslation } from 'react-i18next'

type GenBgAdvicePanelProps = {
  header?: string
}

export function GenBgAdvicePanel(props: PropsWithChildren<GenBgAdvicePanelProps>) {
  const { header, children } = props
  const { t } = useTranslation()

  return (
    <div className={s.advice_panel}>
      {header && <div className={s.advice_panel__header}>{header}</div>}
      <p className={s.advice_panel__text}>
        {t('pages.editor.templateAppliedMessage')}
      </p>

      <div className={s.advice_panel__body}>{children}</div>
    </div>
  )
}
