import React, { useEffect } from 'react'

export const useClickOutside = (menuRef, avatarRef, callback) => {
  const handleClick = (e) => {
    if (menuRef.current && !menuRef.current.contains(e.target)) {
      if (avatarRef.current && !avatarRef.current.contains(e.target)) {
        callback()
      }
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClick)
    return () => {
      document.removeEventListener('mousedown', handleClick)
    }
  })
}
