import axios from 'axios'
import type { ProjectObject, State, Meta } from '../../domain/types/Project'
import { backendUrl } from '../../config/app'
import { mapProjectToObject } from './mappers'

export const createProject = async (
  state: Pick<State, 'sourceImage'>,
  meta: Pick<Meta, 'coverImage'>
): Promise<ProjectObject> => {
  const data = {
    meta,
    state,
  }
  const response = await axios.post(`${backendUrl()}api/ngb/projects`, data)

  return mapProjectToObject(response.data)
}
