import { getProjectId, IInternalEvent, logEvent } from '../logger/logEvent'

const VK_PIXEL_ID = 3523629

export const reachGoal = (goal: string, params?: Record<string, any>) => {
  if (typeof window.ym !== 'undefined') {
    trackYandexMetrika(goal,params)
  }

  if (typeof window._tmr !== 'undefined') {
    trackMailRuCounter(goal,params)
  }

  if (typeof window.amplitude !== 'undefined') {
    trackAmplitude(goal,params)
  }

  if(typeof window.fbq !== 'undefined'){
    trackFacebookPixel(goal,params)
  }

  if(typeof window.gtag !== 'undefined'){
    trackGoogleAnalytics(goal,params)
  }

  if(typeof window.dataLayer !== 'undefined'){
    trackGTM(goal,params)
  }

  trackInternal(goal, params)
}

const trackYandexMetrika = (goal: string, params?: Record<string, any>) => {
  window.ym(window.YM_COUNTER_ID, 'reachGoal', goal, params)
}

const trackMailRuCounter = (goal: string, params?: Record<string, any>) => {
  window._tmr.push({ type: 'reachGoal', id: VK_PIXEL_ID, goal: goal })
}

const trackAmplitude = (goal: string, params?: Record<string, any>) => {
  if(params?.order_price){
    const event = new window.amplitude.Revenue()
        .setProductId(goal)
        .setPrice(params.order_price)
        .setQuantity(1);
    window.amplitude.revenue(event);
  } else {
    window.amplitude.track(goal, params)
  }
}

const trackFacebookPixel = (goal: string, params?: Record<string, any>) => {
  if(params?.order_price){
    window.fbq('track', 'Purchase', {currency: "USD", value: params.order_price});
  }
  if(goal === 'user_registered'){
    window.fbq('track','CompleteRegistration')
  }
}

const trackGoogleAnalytics = (goal:string, params?: Record<string,any>) => {
  if(params?.order_price){
    window.gtag('event', 'purchase', {currency: "USD", value: params.order_price});
  } else if(goal === 'user_registered'){
    window.gtag('event','sign_up')
  }
}

const trackGTM = (goal:string, params?: Record<string,any>) => {
  if(params?.order_price){
    window.dataLayer.push({event: 'purchase', currency: "USD", value: params.order_price})
  } else if(goal === 'user_registered'){
    window.dataLayer.push({event: 'sign_up'})
  } else {
    window.dataLayer.push({event: goal, ...params})
  }
}

const trackInternal = (goal: string, params?: Record<string,any>)=> {
  const pid = getProjectId()
  const event: IInternalEvent = { projectId: pid }
  if (params) {
    event.dataJson = params
  }
  logEvent(`user:${goal}`, event)
}


export const sendUserId = (id: number) => {
  if (typeof window.ym !== 'undefined') {
    window.ym(window.YM_COUNTER_ID, 'userParams', { UserID: id })
  } else {
    console.warn('Yandex Metrica is not initialized')
  }
  if (typeof window.amplitude !== 'undefined') {
    window.amplitude.setUserId(''+id)
  } else {
    console.warn('Amplitude is not initialized')
  }
}
