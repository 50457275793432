import s from './ProjectList.module.css'
import { ProjectObject } from '../../../domain/types/Project'
import { CardProject } from '../CardProject/CardProject'
import { MenuHandlers } from '../CardProject/CardProject'

export type ProjectListProps = {
  //! Warning: Domain/DTO object is using directly
  items: ProjectObject[]
  handlers: MenuHandlers
}

export function ProjectList(props: ProjectListProps) {
  const { items, handlers } = props

  return (
    <div className={s.ProjectList}>
      {items.map((p) => (
        <CardProject
          key={p.id}
          project={p}
          onDuplicate={handlers.onDuplicate}
          onDelete={handlers.onDelete}
        />
      ))}
    </div>
  )
}
