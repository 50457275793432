import { getProjectId } from '../../../logger/logEvent'
import { getRandomAIServiceChoice } from '../../../utils/ai-gen'
import { generateAiBgThumb } from '../../../api/generateAiThumb'
import { generateAiBackground as generateAiBackgroundAPI } from '../../../api/generateAiBackground'
import { StoreType } from 'polotno/model/store'
import {
  setTranslations,
  unstable_setTextVerticalResizeEnabled,
  unstable_setTextOverflow,
  setAPI,
} from 'polotno/config'
import { isRuLocale } from './localization.utils'
import { polotnoRussianTranslations } from '../../../utils/polotnoRussianTranslation'
import { centerObjectInRect } from './editor.utils'
import { backendUrl } from '../../../config/app'

export const createImage = async (imageUrl: string): Promise<HTMLImageElement> => {
  const response = await fetch(imageUrl)
  const blob = await response.blob()
  const img = new Image()
  img.src = URL.createObjectURL(blob)

  return img
}

export const getBackgroundGenerator =
  ({ store, imageResultStore }: any) =>
  async (
    blob: any,
    prompt: string,
    negativePrompt: string,
    options: { isSuggested?: boolean; translate?: boolean; selectAiService?: boolean } = {
      isSuggested: false,
      translate: false,
      selectAiService: false,
    }
  ) => {
    await store.waitLoading()
    const pid = getProjectId()
    const { isSuggested, selectAiService } = options
    const formData = new FormData()
    formData.append('file', blob, 'image.webp')
    formData.append('prompt', prompt)
    formData.append('negativePrompt', negativePrompt)
    formData.append('cardSize', store.custom.cardSize)
    if (options.translate) {
      formData.append('translate', options.translate.toString())
    }
    let srv = getRandomAIServiceChoice() // cobalt | photoroom

    let response = null

    if (isSuggested) {
      const res = await generateAiBgThumb(formData, pid)
      response = { imageUrl: res.thumbUrl, meta: res, srv: 'cobalt' }
    } else {
      const res = await generateAiBackgroundAPI(formData, pid)
      response = res.data
    }

    if (!response || !response.imageUrl) {
      console.error('An error occurred:', response)
    }

    imageResultStore.storeUrl(response.imageUrl, 'cobalt')
    if (selectAiService) {
      imageResultStore.setCurrentByImageUrl(response.imageUrl)
    }
    return { imageUrl: response.imageUrl, meta: response.meta, credits: response.credits, srv }
  }
type PlacementMeta = {
  jsonTemplate: Record<string, any>
  setPositionChangedInFunction: (f: boolean) => void
}

export function placeObjectInTemplate(
  store: any,
  currentObject: any,
  templateELement: any,
  meta: PlacementMeta
) {
  const { jsonTemplate, setPositionChangedInFunction } = meta
  const boundingRect = {
    x: templateELement.x,
    y: templateELement.y,
    width: templateELement.width,
    height: templateELement.height,
  }
  const { x, y, width, height } = centerObjectInRect(currentObject, boundingRect)
  templateELement.x = x
  templateELement.y = y
  templateELement.width = width
  templateELement.height = height

  templateELement.src = currentObject.src

  if (
    store.activePage.background.includes('/') &&
    !store.activePage.background.includes('http') &&
    !store.activePage.background.includes('infographics') &&
    !jsonTemplate['pages'][0]['background'] &&
    (store.custom.objectPositionBeforeDisplacement.x != templateELement.x ||
      store.custom.objectPositionBeforeDisplacement.y != templateELement.y ||
      store.custom.objectPositionBeforeDisplacement.width != templateELement.width ||
      store.custom.objectPositionBeforeDisplacement.height != templateELement.height ||
      store.custom.objectPositionBeforeDisplacement.rotation != templateELement.rotation)
  ) {
    setPositionChangedInFunction && setPositionChangedInFunction(true)
  }

  if (!store.activePage.background.includes('/')) {
    setObjectBeforeDisplacement(store, currentObject)
  }
  store.activePage.addElement({
    ...templateELement,
    id: `object_${store.activePage.id}`,
    removable: false,
  })
  store.deleteElements([templateELement.id])
}

export function setObjectBeforeDisplacement(store: any, mainObject: any): void {
  setCustomStoreEntityProps(store, {
    objectPositionBeforeDisplacement: {
      x: mainObject.x,
      y: mainObject.y,
      width: mainObject.width,
      height: mainObject.height,
      rotation: mainObject.rotation,
    },
  })
}

export function setStoreProps(
  store: any,
  { backgroundType, backgroundKey, backgroundPrompt, infographicsTemplate, cardSize }: any
) {
  store.setSize(store.custom?.cardSize === '1_1' ? 1200 : 900, 1200)
  if (cardSize) {
    setCustomStoreEntityProps(store, { cardSize: cardSize })
    store.activePage.setSize({ width: store.custom.cardSize === '1_1' ? 1200 : 900, height: 1200 })
  }

  if (backgroundType) {
    setCustomStoreEntityProps(store, {
      activeBackgroundType: backgroundType,
    })
  }

  if (backgroundKey) {
    setCustomStoreEntityProps(store, {
      activeBackgroundKey: backgroundKey,
    })
  }

  if (backgroundPrompt) {
    setCustomStoreEntityProps(store, {
      currentPromptInStore: {
        prompt: backgroundPrompt,
      },
    })
  }

  if (infographicsTemplate != null) {
    setCustomStoreEntityProps(store, {
      activeInfographicsTemplate: infographicsTemplate,
    })
  }
}

export function setCustomStoreEntityProps(entity: any, custom: Record<string, any>) {
  entity.set({ custom: { ...(entity.custom || {}), ...custom } })
}

export function setupEditor(store: StoreType) {
  unstable_setTextVerticalResizeEnabled(true)
  unstable_setTextOverflow('change-font-size')
  if (isRuLocale()) {
    setTranslations(polotnoRussianTranslations)
  }

  setAPI('nounProjectList', ({ query, page = 1 }: { query: string; page: number }) => {
    return `${backendUrl()}api/icon_search?query=${query}&page=${page}`
  })
  setAPI('unsplashList', ({ query, page = 1 }: { query: string; page: number }) => {
    return `${backendUrl()}api/background_search?query=${query}&page=${page}`
  })

  store.addPage()
}
