import { SharedProps } from '../../components/ModalDialog/ModalDialog'
import { PlansContainer } from '../../components/plans/PlansContainer'
import { useModalManager } from '../../store/useModalManager'

export const MODAL_NAME = 'plansModal'

export type PlansModalProps = {}

/**
 * @deprecated since Aug 15 by iSavi.
 */
export function PlansModal(props: PlansModalProps & SharedProps) {
  const {} = props
  const toggleModal = useModalManager((s) => s.toggleModal)

  const handleOnClickBack = () => {
    toggleModal(MODAL_NAME, false)
  }

  return (
    <div>
      <PlansContainer onClickBack={handleOnClickBack} />
    </div>
  )
}
