import { SharedProps } from '../../components/ModalDialog/ModalDialog'

export const MODAL_NAME = 'overlayModal'

export function OverlayModal(props: SharedProps) {
  const { close } = props

  return (
    <div></div>
  )
}
