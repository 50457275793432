import {create} from "zustand";
import {AiService} from "../types/ai";

type StoreUrlParams = {imageUrl: string, srvType: AiService}
type ImageStore = {
    store: Record<string, AiService>,
    currentSelected: AiService | 'unknown',
    storeUrl: (p: StoreUrlParams) => void,
    setCurrentByImageUrl: (u: string) => void,
}


export const useImageStore = create<ImageStore>((set) => ({
    store: {},
    currentSelected: 'cobalt',
    storeUrl: ({imageUrl, srvType}: StoreUrlParams) => set((s) => ({...s, store: {...s.store, [imageUrl]: srvType}})),
    setCurrentByImageUrl: (url: string) => set((s) => ({currentSelected: s.store[url] ?? 'cobalt'}))
}))