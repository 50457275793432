import cx from 'clsx'
import s from './ModalDialog.module.css'
import { ReactComponent as CrossIcon } from './icon-cross.svg'
import { ModalDialogProps } from './ModalDialog'

export const ModalDialogComponent = (props: ModalDialogProps) => {
  const {
    isOpen,
    withCloseButton = true,
    closeable = true,
    maxWidth = true,
    close,
    children,
    render,
  } = props

  if (!isOpen) {
    return null
  }

  const onCloseHandler = () => (closeable ? close() : false)

  return (
    <div className={cx(s.dialog)} onClick={onCloseHandler}>
      <div
        className={cx(s.inner_wrapper, {
          [s.inner_wrapper_wide]: maxWidth,
        })}
        onClick={(event) => event.stopPropagation()}
      >
        {withCloseButton && (
          <div className={s.top_dock}>
            <div className={s.cursor_pointer} onClick={onCloseHandler}>
              <CrossIcon />
            </div>
          </div>
        )}
        <div className={cx(s.container)}>
          <div className={cx(s.body)}>
            {render && render({ close: close, closeable })}
            {!render && children && children}
          </div>
        </div>
      </div>
    </div>
  )
}
