import {getProjectId} from "../../../logger/logEvent";
import {backendUrl} from "../../../config/app";
import {Card, LOADING_IMG} from "../aicard/aiCardsContext";

export const getSuggestedBackgrounds = (imageUrl: string, onLoad: (data: any) => void): void => {
    const pid = getProjectId()
    fetch(
        `${backendUrl()}api/backgroundTemplates?imageUrl=${encodeURIComponent(imageUrl)}&pid=${pid}`
    )
        .then((response: Response) => {
            return response.json() // Parse the response body as JSON
        })
        .then((data) => {
            onLoad(data)
        })
        .catch((error) => {
            console.error('Error:', error)
        })
}

export const onBackgroundsLoaded = (setBackgrounds: (c: Card[]) => void) => (data: any) => {
    if (data.backgrounds && Array.isArray(data.backgrounds)) {
        setBackgrounds(
            data.backgrounds.map((card: { prompt: string; name: string }) => ({
                prompt: card.prompt,
                title: card.name,
                dataUrl: LOADING_IMG,
                loading: false,
            }))
        )
    }
}

export const createMask = async (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
        const canvas = document.createElement('canvas')
        canvas.height = 1200
        canvas.width = 900
        const ctx = canvas.getContext('2d')
        if (ctx) {
            ctx.fillStyle = 'white'
            ctx.fillRect(0, 0, 900, 1200)
        }
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = (event) => {
            const img = new Image()
            img.src = event.target?.result as string
            img.onload = () => {
                ctx?.drawImage(img, 0, 0, 900, 1200)
                if (ctx) {
                    const imageData = ctx.getImageData(0, 0, 900, 1200)
                    for (let i = 0; i < imageData.data.length; i += 4) {
                        if (
                            imageData.data[i] != 255 &&
                            imageData.data[i + 1] != 255 &&
                            imageData.data[i + 2] != 255
                        ) {
                            imageData.data[i] = 0
                            imageData.data[i + 1] = 0
                            imageData.data[i + 2] = 0
                        }
                    }
                    ctx.putImageData(imageData, 0, 0)
                }
                resolve(canvas.toDataURL('image/webp'))
            }
            img.onerror = ev => reject(ev)
        }
        reader.onerror = ev=> reject(ev)
    })
}