import { DOMAIN_NAME_EN, DOMAIN_NAME_RU } from "../constants/domains" 

export const getLocale = () => {
  return process.env.REACT_APP_LOCALE
}

export const isRuLocale = () => {
  const locale = getLocale()
  return locale === 'ru'
}

export const isEnLocale = () => {
  const locale = getLocale()
  return locale === 'en'
}

export const getDomainName = () => {
  return (isRuLocale() ? DOMAIN_NAME_RU : DOMAIN_NAME_EN)
}

export const getFullDomainName = () => {
  return 'https://www.' + (isRuLocale() ? DOMAIN_NAME_RU : DOMAIN_NAME_EN)
}