import { PropsWithChildren, useContext, useEffect, useState } from 'react'
import { DiscountLabel } from '../Tabs/misc'
import { Tab } from '../Tabs/Tab'
import { TabsGroup } from '../Tabs/TabGroup'
import { CardPlan } from './cards/CardPlan'
import s from './plan-page.module.css'
import { Button } from '../Button'
import { ReactComponent as IconArrowLeft } from './icon-arrow-left.svg'
import { Case, Switch } from '../CondRender/CondRender'
import { MONTHLY_FEATURES, YEARLY_FEATURES } from './plans-features'
import { useFullPageLoading } from '../../contexts/FullPageLoadingContext'
import { initRegularPayment } from '../../api/initRegularPayment'
import {
  subscriptionTiers,
  SubscriptionTierType,
  SubscriptionType,
  useSubscriptions,
} from '../../store/useSubscriptions'
import { initSubscription } from '../../api/initSubscription'
import { reachGoal } from '../../utils/metrics'
import { ReactComponent as QuestionWhiteIcon } from './cards/question-white.svg'
import { ReactComponent as QuestionIcon } from './question.svg'
import { applyDiscountForPrice } from './promo-code/utils'
import { usePromocode } from '../../store/usePromocode'
import { InputPromoCodeBlock } from './promo-code/InputPromoCodeBlock'
import { FeatureFlagContext } from '../../contexts/FeatureFlagContext'
import { useTranslation } from 'react-i18next'
import { backendUrl } from '../../config/app'
import { isRuLocale } from '../new-editor/utils/localization.utils'
import { maximizeSupport } from '../../utils/internationalSupport'
import { getCurrencyFormat } from '../new-editor/utils/subscription.utils'
import {ClientType, getPlans} from "./plans";

export type PlansContainerProps = {
  onClickBack: () => void
}

type Plan = 'yearly' | 'monthly' | 'shop'

export function PlansContainer(props: PlansContainerProps) {
  const { onClickBack } = props
  const isPromoCodeApplied = usePromocode((s) => s.isApplied)
  const { setLoading } = useFullPageLoading()
  const { t } = useTranslation()
  const resetPromoCode = usePromocode((s) => s.reset)

  const ClientTypeTips: Record<ClientType, string> = {
    standard: t('pages.plans.forBeginnerSellers'),
    premium: t('pages.plans.forMediumSellers'),
    business: t('pages.plans.forLargeSellers'),
  }

  const formatPrice = (price: number): string => {
    return `${t('pages.plans.total')} ${getCurrencyFormat(price)}`
  }

  const discountInfo = usePromocode((s) => ({
    plandIds: s.planIds,
    discount: s.discount,
    code: s.code,
  }))

  const applyDiscount = (sum: number, planId: string): number => {
    if (!isPromoCodeApplied) {
      return sum
    }

    return applyDiscountForPrice(sum, planId, discountInfo.plandIds!, discountInfo.discount)
  }

  const areSubscriptionExist = useSubscriptions((s) => s.activeSubscriptions.length > 0)
  const currentPlanDetails = useSubscriptions((s) => s.activeSubscriptions[0]?.planDetails)

  const { isAB } = useContext(FeatureFlagContext)

  const [planTab, setPlanTab] = useState<Plan>(isAB('packages_paywall') ? 'monthly' : 'yearly')

  useEffect(() => {
    reachGoal('open_plans')
  }, [])

  // Reset promocode status after unmount
  useEffect(() => {
    return () => {
      resetPromoCode()
    }
  }, [])

  type ButtonAction = 'buy' | 'current' | 'upgrade' | 'contact_support'

  const getActionBasedOnLocale = () => {
    return isRuLocale() ? 'upgrade' : 'contact_support'
  }

  const getButtonAction = (
    nextTier: SubscriptionTierType,
    nextTierType: SubscriptionType
  ): ButtonAction => {
    if (!currentPlanDetails) {
      return 'buy'
    }

    const { tier_type: currentTier, type: currentTierType } = currentPlanDetails
    const currentTierIndex = subscriptionTiers.indexOf(currentTier)
    const nextTierIndex = subscriptionTiers.indexOf(nextTier)

    if (nextTierType === currentTierType) {
      if (currentTierIndex > nextTierIndex) {
        return 'contact_support'
      } else if (currentTierIndex < nextTierIndex) {
        return getActionBasedOnLocale()
      } else {
        return 'current'
      }
    } else if (currentTierType === 'MONTHLY' && nextTierType === 'ANNUAL') {
      if (nextTierIndex >= currentTierIndex) {
        return getActionBasedOnLocale()
      } else {
        return 'contact_support'
      }
    } else if (currentTierType === 'ANNUAL' && nextTierType === 'MONTHLY') {
      return 'contact_support'
    }

    return 'contact_support'
  }

  const handleSubscriptionClick = async (
    planId: string,
    tier: SubscriptionTierType,
    type: SubscriptionType,
    stripePriceId?: string
  ) => {
    reachGoal('click_buy_credits')
    const buttonAction = getButtonAction(tier, type)

    if (buttonAction === 'buy' || buttonAction === 'upgrade') {
      setLoading(true)
      if (!isRuLocale()) {
        const res = await fetch(`${backendUrl()}api/subscription`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ priceId: stripePriceId, planId }),
        })

        const data = res.ok && (await res.json())
        window.location.href = data.redirectUrl;
        setLoading(false)
      } else {
        try {
          const url = await initSubscription(planId, discountInfo.code ?? undefined)
          window.location.href = url;
        } catch (e) {
          console.error(e)
          alert(t('pages.plans.paymentInitiationFailed'))
        } finally {
          setLoading(false)
        }
      }
    } else {
      if (isRuLocale()) {
        const contactSupportUrl = `https://t.me/mpcardai_support?text=${encodeURIComponent(
          t('pages.plans.changeSubscriptionPlan') + ':\n'
        )}`
        window.open(contactSupportUrl, '_blank')
      } else {
        maximizeSupport()
      }
    }
  }

  const handleRegularPayment = async (option: string) => {
    setLoading(true)
    reachGoal('click_buy_credits')
    try {
      const url = await initRegularPayment(option)
      location.href = url
    } catch (e) {
      console.error(e)
      alert(t('pages.plans.paymentInitiationFailed'))
    } finally {
      setLoading(false)
    }
  }

  const withAnalytics =
    (
      event: string,
      planId: string,
      tier: SubscriptionTierType,
      type: SubscriptionType,
      stripePriceId?: string
    ) =>
    () => {
      reachGoal(event)
      handleSubscriptionClick(planId, tier, type, stripePriceId)
    }

  const withAnalyticsPacket = (event: string, opt: string) => () => {
    reachGoal(event)
    handleRegularPayment(opt)
  }

  const checkDiscountAllowed = (planId?: string): boolean => {
    if (!planId || !isPromoCodeApplied || !discountInfo) {
      return false
    }

    if (discountInfo.plandIds && discountInfo.plandIds.length > 0) {
      return discountInfo.plandIds.includes(planId)
    }

    return true
  }

  const getButtonText = (tier: SubscriptionTierType, type: SubscriptionType) => {
    const labels = {
      buy: t('pages.plans.buy'),
      current: t('pages.plans.currentPlan'),
      upgrade: t('pages.plans.upgradePlan'),
      contact_support: t('pages.plans.changePlan'),
    }

    const buttonAction = getButtonAction(tier, type)

    return labels[buttonAction]
  }

  const isButtonDisabled = (tier: SubscriptionTierType, type: SubscriptionType) => {
    return getButtonAction(tier, type) === 'current'
  }

  const gerAdditionalGenerationsTitle = (count: string) => {
    return count + ' ' + t('pages.header.ofGenerations')
  }

  const plans = getPlans()

  return (
    <div className={s.Page}>
      <div>
        <div className={s.TopActions}>
          <Button
            size="s"
            variation="secondary"
            grow={false}
            onClick={onClickBack}
            className={s.BackButtonContainer}
          >
            <div className={s.BackButton}>
              <div>
                <IconArrowLeft />
              </div>
              <div>{t('pages.profile.returnToEditor')}</div>
            </div>
          </Button>
        </div>
        <div className={s.HeaderContainer}>
          <h1 className={s.Header}>{t('pages.plans.choosePlan')}</h1>
          <div className={s.SubHeader}>
            <span
              dangerouslySetInnerHTML={{ __html: t('pages.plans.chooseComfortablePlanBefore') }}
            />
            <span className={s.hg}>-40%</span>
            <span
              dangerouslySetInnerHTML={{ __html: t('pages.plans.chooseComfortablePlanAfter') }}
            />
          </div>
          {isRuLocale() && <InputPromoCodeBlock />}
        </div>

        <div className={s.TabsContainer}>
          <TabsGroup>
            <Tab onClick={() => setPlanTab('yearly')} isActive={planTab == 'yearly'} size="m">
              {t('pages.plans.yearly')}
              <DiscountLabel percents={40} />
            </Tab>
            <Tab onClick={() => setPlanTab('monthly')} isActive={planTab == 'monthly'} size="m">
              {t('pages.plans.monthly')}
            </Tab>
            {isRuLocale() && (
              <Tab onClick={() => setPlanTab('shop')} isActive={planTab == 'shop'} size="m">
                {t('pages.plans.onlineStore')}
                <div className={s.tooltip}>
                  {planTab == 'shop' ? <QuestionWhiteIcon /> : <QuestionIcon />}
                  <div className={s.tooltip_text}>{t('pages.plans.availableWithActivePlan')}</div>
                </div>
              </Tab>
            )}
          </TabsGroup>
        </div>

        <div className={s.PlanContainer}>
          <Switch>
            <Case condition={planTab == 'yearly'}>
              {plans.yearlyPlans.map((plan) => {
                const discountedPrice = applyDiscount(plan.price, plan.planId!) / 12
                const price = isRuLocale()? Math.floor(discountedPrice) : Math.floor(discountedPrice * 100) / 100
                const priceDescription = formatPrice(applyDiscount(plan.price, plan.planId!))
                const clientTypeTip = ClientTypeTips[plan.clientTypeTipKey!]
                const buttonText = getButtonText(plan.tier!, plan.type!)
                const disableButton = isButtonDisabled(plan.tier!, plan.type!)
                const featuresList = YEARLY_FEATURES[plan.featuresListKey!]

                const onButtonClick = withAnalytics(
                  plan.analyticsEvent!,
                  plan.planId!,
                  plan.tier!,
                  plan.type!,
                  plan.stripePriceId
                )

                return (
                  <CardPlan
                    key={plan.planId}
                    planId={plan.planId}
                    title={t(plan.titleKey)}
                    subTitle={plan.subTitleKey ? t(plan.subTitleKey) : undefined}
                    clientTypeTip={clientTypeTip}
                    price={price}
                    originalPrice={plan.originalPrice}
                    priceDescription={priceDescription}
                    pricePostfix={t('pages.plans.month')}
                    highlight={plan.highlight}
                    featuresList={featuresList}
                    onButtonClick={onButtonClick}
                    buttonText={buttonText}
                    disableButton={disableButton}
                    discountAmount={discountInfo.discount}
                    checkDiscountAllowed={checkDiscountAllowed}
                  />
                )
              })}
            </Case>

            <Case condition={planTab == 'monthly'}>
              <ComponentWithAnalytics event="visit_monthly_plans">
                {plans.monthlyPlans.map((plan) => {
                  const price = applyDiscount(plan.price, plan.planId!)
                  const clientTypeTip = ClientTypeTips[plan.clientTypeTipKey!]
                  const buttonText = getButtonText(plan.tier!, plan.type!)
                  const disableButton = isButtonDisabled(plan.tier!, plan.type!)
                  const featuresList = MONTHLY_FEATURES[plan.featuresListKey!]

                  const onButtonClick = withAnalytics(
                    plan.analyticsEvent!,
                    plan.planId!,
                    plan.tier!,
                    plan.type!,
                    plan.stripePriceId
                  )

                  return (
                    <CardPlan
                      key={plan.planId}
                      planId={plan.planId}
                      title={t(plan.titleKey)}
                      subTitle={plan.subTitleKey ? t(plan.subTitleKey) : undefined}
                      clientTypeTip={clientTypeTip}
                      price={price}
                      originalPrice={plan.originalPrice}
                      pricePostfix={t('pages.plans.month')}
                      highlight={plan.highlight}
                      featuresList={featuresList}
                      onButtonClick={onButtonClick}
                      buttonText={buttonText}
                      disableButton={disableButton}
                      discountAmount={discountInfo.discount}
                      checkDiscountAllowed={checkDiscountAllowed}
                    />
                  )
                })}
              </ComponentWithAnalytics>
            </Case>

            <Case condition={planTab == 'shop'}>
              <ComponentWithAnalytics event="visit_online_store">
                {plans.shopPlans.map((plan, index) => (
                  <CardPlan
                    key={index}
                    title={gerAdditionalGenerationsTitle(plan.titleKey)}
                    price={plan.price}
                    originalPrice={plan.originalPrice}
                    onButtonClick={withAnalyticsPacket(plan.analyticsEvent!, String(index + 1))}
                    buttonText={t('pages.plans.buy')}
                    disableButton={!areSubscriptionExist}
                    useButtonWithTooltip={true}
                    btnTooltipText={t('pages.plans.subscribeToGetGenerations')}
                  />
                ))}
              </ComponentWithAnalytics>
            </Case>
          </Switch>
        </div>
      </div>
    </div>
  )
}

const ComponentWithAnalytics = (p: PropsWithChildren<{ event: string }>) => {
  useEffect(() => {
    if (p.event) {
      reachGoal(p.event)
    }
  }, [p.event])
  return p.children as any
}
