import { useEffect } from 'react'
import { useCredits } from '../store/useCredits'
import { checkLoginStatus } from '../api/checkLoginStatus'
import { useAuthContext } from '../contexts/AuthProvider'
import { fetchCredits } from '../api/fetchCredits'
import { sendUtmData } from '../logger/source_saver'
import { sendUserId } from '../utils/metrics'
import { useAfterAuthOps } from './useAfterAuthOps'
import { useFullPageLoading } from '../contexts/FullPageLoadingContext'

export function AuthAware() {
  const setCredits = useCredits((s) => s.setCredits)
  const { setUserId, setAuthenticated, hasAuthenticated } = useAuthContext()
  const afterAuthJobs = useAfterAuthOps()
  const { setLoading, isLoading } = useFullPageLoading()

  useEffect(() => {
    
    ;(async () => {
      try {
        setLoading(true)
        if (hasAuthenticated) {
          setLoading(false)
          return
        }
        const result = await checkLoginStatus()
        if (result.status) {
          if (result.userId) {
            setUserId(result.userId)
            setAuthenticated(true)

            sendUtmData()
            sendUserId(result.userId)
          }

          const credits: number | null = await fetchCredits()
          if (credits !== null) {
            setCredits(credits)
          }

          await afterAuthJobs()
        }
        setLoading(false)
      } catch (e) {
        setLoading(false)
        console.error(e)
      }
    })()
  }, [])

  return null
}
