import React, { useState, useEffect } from 'react'
import styles from './register.module.css'
import { Button } from '../components/Button'
import { reachGoal, sendUserId } from '../utils/metrics'
import presentImage from './presentImage.webp'
import { useFullPageLoading } from '../contexts/FullPageLoadingContext'
import { SocialBlock } from './SocialBlock'
import { useSocialAuthHandler } from './useSocialAuthHandler'
import { HeaderSeparated } from '../components/headers/HeaderSeparated'
import { clearQueryString } from '../utils/clearQueryString'
import { useNavigate } from 'react-router-dom'
import authClient from './AuthClient'
import { R_SIGN_IN_PAGE, R_START_PAGE, R_THANKS_SIGN_UP } from '../router-constants'
import { useAuthContext } from '../contexts/AuthProvider'
import { sendUtmData } from '../logger/source_saver'
import { fetchCredits } from '../api/fetchCredits'
import { useCredits } from '../store/useCredits'
import { MarketingConsentCheckbox } from './MarketingConsentCheckbox'
import { useTranslation } from 'react-i18next';
import { getFullDomainName } from '../components/new-editor/utils/localization.utils'

const isSocialAuthEnabled = process.env.REACT_APP_ENABLE_SOCIAL_AUTH == 'true'

const Register: React.FC<{}> = () => {
  const [email, setEmail] = useState('')
  const navigate = useNavigate()
  const [telegram, setTelegram] = useState('')
  const [marketingConsent, setMarketingConsent] = useState(true);
  // const { signUpError, register, socialLogin, setSignUpErrMsg } = useAuth()
  const [signUpError, setSignUpErrMsg] = useState<string | null>(null)
  const { setLoading, isLoading } = useFullPageLoading()
  const [socialAuthError, setSocialAuthError] = useState<string | null>(null)
  const yandexAuthHandler = useSocialAuthHandler('yandex')
  const vkAuthHandler = useSocialAuthHandler('vk')
  const { t } = useTranslation()

  const REGISTER_TEXTS = {
    default: {
      registration: () => (
        <h2 className="titleL colorTextBlackPrimary">{t('pages.registerScreen.signUp')}</h2>
      ),
      present: () => (
        <>
          <span className="paragraphM">{t('pages.registerScreen.signUpAndGet')} </span>
          <span className="bodyM colorAccentVioletDefault">{t('pages.registerScreen.fourGenerationsFree')}</span>
        </>
      ),
    },
    tg: {
      registration: () => (
        <h2 className="titleL colorTextBlackPrimary">{t('pages.registerScreen.signUp')}</h2>
      ),
      present: () => (
        <>
          <span className="paragraphM">{t('pages.registerScreen.logInToGet')}</span>
          <span className="bodyM colorAccentVioletDefault">{t('pages.registerScreen.fourGenerations')}</span>
        </>
      ),
    },
  }

  const [texts, setTexts] = useState(REGISTER_TEXTS['default'])

  const { setAuthenticated, setUserId } = useAuthContext()
  const setCredits = useCredits((s) => s.setCredits)
  //There might be a risk user has outdated code/state values because of bookmarks or smth?
  //TODO: move it somewhere, doesn't seem logical to have social auth result check here
  useEffect(() => {
    //SOCIAL AUTH PROCESSING
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const [code, state] = [urlParams.get('code') ?? '', urlParams.get('state') ?? '']

    const processSocialAuthResult = async () => {
      setSocialAuthError(null)
      setLoading(true)
      const query: Record<string, string> = {}
      urlParams.forEach((value, key) => {
        query[key] = value
      })
      clearQueryString()
      // const status = await socialLogin(query)
      const result = await authClient.socialLogin(query)
      setLoading(false)
      if (!result.status) {
        setSocialAuthError(t('pages.registerScreen.socialAuthError'))
      } else {
        setAuthenticated(true)
        if (result.userId) {
          setUserId(result.userId)
          sendUserId(result.userId)
        }
        sendUtmData()
        if (result.registered) {
          reachGoal('user_registered')
        }
        reachGoal('user_logged_in')

        const credits: number | null = await fetchCredits()
        if (credits !== null) {
          setCredits(credits)
        }
        navigate(R_START_PAGE)
      }
    }
    if (code && state) processSocialAuthResult()

    //TEXTS PROCESSING FOR DIFFERENT FUNNELS
    const campaign = urlParams.get('campaign')
    if (campaign === 'tg') {
      setTexts(REGISTER_TEXTS['tg'])
      urlParams.delete('campaign')
      window.history.replaceState({}, '/', '/')
    }
  }, [])

  const createAuthHandler = (provider: 'vk' | 'yandex', marketingConsent?: boolean) => async () => {
    setSocialAuthError(null)
    try {
      if (provider === 'yandex') {
        await yandexAuthHandler(marketingConsent)
      }
      if (provider === 'vk') {
        await vkAuthHandler(marketingConsent)
      }
    } catch (error) {
      console.log('error', error)
      setSocialAuthError(t('pages.registerScreen.socialAuthError'))
    }
  }

  const handleRegister = async (e: React.FormEvent) => {
    setSocialAuthError(null)
    e.preventDefault()
    setSignUpErrMsg(null)
    setLoading(true)
    try {
      const { status, code } = await authClient.signUp({ email, marketingConsent, tlg: telegram })
      setLoading(false)
      if (status) {
        navigate(R_THANKS_SIGN_UP)
        reachGoal('user_registered')
      } else {
        if (code) {
          if (code === 4003) {
            setSignUpErrMsg(t('pages.registerScreen.emailAlreadyInUse'))
            return
          }
          if (code === 4005) {
            setSignUpErrMsg(t('pages.registerScreen.invalidEmail'))
            return
          }

          setSignUpErrMsg(t('pages.registerScreen.unexpectedError'))
        }
      }
    } catch (e) {
      setSignUpErrMsg(t('pages.registerScreen.unexpectedError'))
    }
  }

  const goSignIn = () => navigate(R_SIGN_IN_PAGE)

  // const composeTelegram = (value: string) => {
  //   var regular = new RegExp('@@|@https://t.me/|@http://t.me/|https://t.me/|http://t.me/')
  //   var newTelegram = value.replace(regular, '@')
  //   return newTelegram
  // }

  // const addSymbolInInput = (value: string) => {
  //   if (value === '') value = '@'
  //   return value
  // }

  // const deleteSymbolInInput = (value: string) => {
  //   if (value === '@') value = ''
  //   return value
  // }

  return (
    <div style={{
      width: '100%',
      display: 'flex',
      flexDirection: 'column'
    }}>
    <div className={styles.upload}>
      <div className={styles.content}>
        <div className={styles.title}>
          <h2 className="titleL colorTextBlackPrimary">{texts['registration']()}</h2>
        </div>
        <form className={styles.form} onSubmit={handleRegister}>
          <div className={styles.presentBlock}>
            <img className={styles.presentImage} src={presentImage} />
            <div className={styles.textInPresentBlock}>{texts['present']()}</div>
          </div>
          <div className={styles.inputContainer}>
            {/*<label
              className={`${signUpError ? styles.labelError : ''} ${styles.inputLabel}`}
              htmlFor="email"
            >
              E-mail{' '}
              <span className={`${signUpError ? styles.labelError : styles.requiredMark}`}>*</span>
            </label>*/}
            <input
              className={`${styles.input} bodyM ${signUpError ? styles.inputError : ''}`}
              placeholder="mail@example.ru"
              type="email"
              id="email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value)
                setSignUpErrMsg(null)
              }}
              disabled={isLoading}
              required
            />
            <span className={`${styles.errorText} paragraphS colorAccentPinkDark`}>
              {signUpError}
            </span>
            {socialAuthError && (
              <span className={`${styles.errorText} paragraphS colorAccentPinkDark`}>
                {socialAuthError}
              </span>
            )}
          </div>
          {/*<div className={styles.inputContainer}>
            <label className={styles.inputLabel} htmlFor="telegram">
              Telegram
            </label>
            <input
              className={`${styles.input}`}
              placeholder="@"
              type="text"
              value={telegram}
              id="telegram"
              onFocus={(e) => setTelegram(addSymbolInInput(e.target.value))}
              onBlur={(e) => setTelegram(deleteSymbolInInput(e.target.value))}
              onChange={(e) => setTelegram(composeTelegram(e.target.value))}
            />
            <span className={styles.inputDescription}>
              {t('pages.registerScreen.noSpamPromise')}
            </span>
            </div>*/}
          
          <Button type="submit" size="m" disabled={isLoading}>
            {t('pages.registerScreen.retrievePassword')}
          </Button>

          {isSocialAuthEnabled && (
            <>
              <div style={{ padding: '0 7%', width: '100%' }}>
                <HeaderSeparated>{t('pages.registerScreen.or')}</HeaderSeparated>
              </div>

              <div style={{ width: '100%' }}>
                <SocialBlock
                  onYandexClick={createAuthHandler('yandex', marketingConsent)}
                  onVkClick={createAuthHandler('vk', marketingConsent)}
                />
              </div>
            </>
          )}
          <div className={styles.other}>
            <span className="paragraphM colorTextBlackSecondary">{t('pages.registerScreen.alreadyHaveAccount')}</span>
            <Button onClick={goSignIn} size="s" variation="tertiary" grow={false}>
              {t('pages.registerScreen.login')}
            </Button>
          </div>
        </form>
        <div
          className="bodyS colorTextBlackPrimary center sm-only"
          style={{ color: 'var(--Text-Black-Tertiary, rgba(22, 27, 51, 0.4))' }}
        >
          <div style={{ marginBottom: '15px'}}>
            <MarketingConsentCheckbox 
              checked={marketingConsent}
              onChange={setMarketingConsent}
            />
          </div>
          <div className={styles.policyAndTermsContainer}>
            {t('pages.registerScreen.registrationAgreement')}
            {' '}
            {t('pages.registerScreen.with')}
            {'\u00A0'}
            <a
              href={getFullDomainName() + "/privacy-policy"}
              style={{ color: 'var(--Accent-Violet-Default)' }}
            >
              {t('pages.registerScreen.privacyPolicy')}
            </a>
            {' '}
            {t('pages.registerScreen.and')}{'\u00A0'}
            <a
              href={getFullDomainName() +  "/terms-of-service"}
              style={{ color: 'var(--Accent-Violet-Default)' }}
            >
              {t('pages.registerScreen.termsOfUse')}
            </a>
          </div>
        </div>
      </div>
    </div>
    <div
      className="bodyS colorTextBlackPrimary center hidden-desktop sm-hidden"
      style={{ 
        color: 'var(--Text-Black-Tertiary, rgba(22, 27, 51, 0.4))',
        maxWidth: '350px',
        alignSelf: 'center',
        marginTop: '15px',
        marginBottom: '15px'
      }}
    >
      <div style={{ marginBottom: '15px'}}>
        <MarketingConsentCheckbox 
          checked={marketingConsent}
          onChange={setMarketingConsent}
        />
      </div>
      
      <div className={styles.policyAndTermsContainer}>
        {t('pages.registerScreen.registrationAgreement')}
        {' '}
        {t('pages.registerScreen.with')}
        {'\u00A0'}
        <a
          href={getFullDomainName() + "/privacy-policy"}
          style={{ color: 'var(--Accent-Violet-Default)' }}
        >
          {t('pages.registerScreen.privacyPolicy')}
        </a>
        {' '}
        {t('pages.registerScreen.and')}{'\u00A0'}
        <a
          href={getFullDomainName() + "/terms-of-service"}
          style={{ color: 'var(--Accent-Violet-Default)' }}
        >
          {t('pages.registerScreen.termsOfUse')}
        </a>
      </div>
      
    </div>
    </div>
  )
}

export default Register
