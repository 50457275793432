import React, {useEffect, useRef, useState} from 'react'
import Logo from '../logo/logo'
import Count from '../count/Count'
import MobileCount from '../count/MobileCount'
import Avatar from '../avatar/Avatar'
import { ReactComponent as Burger } from './Burger.svg'
import { ReactComponent as Cross } from './Cross.svg'
import { ReactComponent as Exit } from './Exit.svg'
import { ReactComponent as ProfileIcon } from '@material-design-icons/svg/round/settings.svg'
import { ReactComponent as VideoIcon } from '@material-design-icons/svg/round/videocam.svg'
import styles from './Header.module.css'
import { useClickOutside } from '../../hooks/useClickOutside'
import { useNavigate } from 'react-router-dom'
import { useAuthContext } from '../../contexts/AuthProvider'
import { reachGoal } from '../../utils/metrics'
import cx from 'clsx'
import authClient from '../../auth/AuthClient'
import { R_SIGN_IN_PAGE, R_USER_PROFILE, R_START_PAGE, R_FORGOT_PASSWORD_PAGE, R_PLANS } from '../../router-constants'
import { ProductVideoModal } from '../../ds/modal/ProductVideoModal'
import { VideoModalDialogComponent } from '../../components/ModalDialog/VideoModalDialogComponent'
import { ReactComponent as ArrowLeft } from '@material-design-icons/svg/round/chevron_left.svg'
import { ReactComponent as DwlIcon } from '../../components/icons/DownloadIcon.svg'
import { Button } from '../Button'
import { useTranslation } from 'react-i18next'
import {isEnLocale, isRuLocale} from '../new-editor/utils/localization.utils'
import { useDownload } from '../../hooks/useDownload'
import {useResize} from "../../hooks/useResize";
import {setSupportVisibility} from "../../utils/internationalSupport";
import {SupportWidgetToggle} from "./SupportWidgetToggle";


export type HeaderProps = {
  withBackButton?: boolean
  backButtonText?: string,
  onBackButtonClick?: () => void
  setOpenMobileMenu?: React.Dispatch<React.SetStateAction<boolean>>
  isOpenMobileMenu?: boolean
}

const Header = (props: HeaderProps) => {
  const { withBackButton = false, onBackButtonClick, backButtonText, setOpenMobileMenu, isOpenMobileMenu } = props
  const { hasAuthenticated, setAuthenticated } = useAuthContext()
  const [isOpenMobileMenuInner, setOpenMobileMenuInner] = useState(false)
  const [isOpenProductVideoModal, setIsOpenProductVideoModal] = useState(false)
  const {isScreenDesktop} = useResize()
  const [isOpen, setOpen] = useState(false)
  const menuRef = useRef(null)
  const avatarRef = useRef(null)
  const navigate = useNavigate()
  useClickOutside(menuRef, avatarRef, () => {
    setOpen(false)
  })
  const { t } = useTranslation()
  const { onDownloadClick } = useDownload()
  const handleLogout = async () => {
    setOpen(false)
    try {
      await authClient.logout()
      setAuthenticated(false)
      navigate(R_SIGN_IN_PAGE)
    } catch (error) {
      console.error('Error during logout', error)
    }
  }

  const setOpenMenu = (status: boolean) => {
    setOpenMobileMenu ? setOpenMobileMenu(status) : setOpenMobileMenuInner(status)
  }

  const getIsOpenMobileMenu = () => isOpenMobileMenu || isOpenMobileMenuInner

  const handleBackButton = () => {
    setOpenMenu(false)
    if (onBackButtonClick) {
      onBackButtonClick()
    }
  }
  const handleMobileCreditsClick = () => {
    setOpenMenu(false)
  }

  const handleProfileClick = () => {
    setOpen(false)
    setOpenMenu(false)
    navigate(R_USER_PROFILE)
  }

  const handleVideoClick = () => {
    reachGoal('click-video-button')
    setIsOpenProductVideoModal(true)
    setOpenMenu(false)
  }

  const closeProductVideoModal = () => {
    setIsOpenProductVideoModal(false)
  }

  const setLocalStorage = () => {
    localStorage.setItem('viewedVideoAboutProduct', 'true')
  }

  const handleDownloadClick = () => {
    onDownloadClick()
    setOpenMenu(false)
  }

  const isLocationEditor = () => {
    if(location.pathname != R_START_PAGE && 
      location.pathname != R_FORGOT_PASSWORD_PAGE &&
      location.pathname != R_USER_PROFILE &&
      location.pathname != R_PLANS){
        return true
      }
    return false
  }

  return (
    <>
      <header className={styles.header}>
        <div className={`${styles.header_desktop} hidden-desktop`}>
          {!withBackButton && (
            <div className={styles.header_logo}>
              <Logo />
            </div>
          )}
          {withBackButton && (
            <div className={styles.BackButton} onClick={onBackButtonClick}>
              <ArrowLeft />
              <div className={styles.Text}>
                {backButtonText}
              </div>
            </div>
          )}

          {hasAuthenticated && (
            <div className={styles.header_items}>
              <Count />
              <div className={styles.divider}></div>
              <div className={styles.avatar} onClick={() => setOpen(!isOpen)} ref={avatarRef}>
                <Avatar />
              </div>
              <nav
                className={cx(styles.minW, { 'menu active': isOpen, menu: !isOpen })}
                ref={menuRef}
              >
                <ul className={styles.menu_list}>
                  <li onClick={handleProfileClick} className={cx(styles.menu_item, 'bodyM')}>
                    {t('pages.header.profileSettings')}
                  </li>
                  {isRuLocale() && (
                    <li onClick={handleVideoClick} className={cx(styles.menu_item, 'bodyM')}>
                      {t('pages.header.videoTutorial')}
                    </li>
                  )}
                  <li
                    onClick={handleLogout}
                    className={cx(styles.menu_item, styles.menu_item_out, 'bodyM')}
                  >
                    {t('pages.header.logOut')}
                  </li>
                </ul>
              </nav>
            </div>
          )}
        </div>

        <div className={`${styles.header_mobile} hidden-mobile`}>
          {!withBackButton && (
            <div className={styles.header_logo}>
              <Logo />
            </div>
          )}
          {withBackButton && (
            <div className={styles.BackButton} onClick={handleBackButton}>
              <ArrowLeft />
              <div className={styles.Text}>
                {backButtonText}
              </div>
            </div>
          )}

          {hasAuthenticated && (
            <div className={styles.header_items}>
              {isLocationEditor() && (
                <Button
                  size="s"
                  grow={false}
                  onClick={handleDownloadClick}
                  className={cx('editor-hidden-desktop', styles.btnDwlMobileHeader)}
                >
                  <div className="dwlButton">
                    <DwlIcon />
                  </div>
                </Button>
              )}
              <div
                className={styles.burgerButton}
                onClick={() => setOpenMenu(!getIsOpenMobileMenu())}
              >
                {getIsOpenMobileMenu() ? <Cross /> : <Burger />}
              </div>
            </div>
          )}
        </div>
      </header>
      <div className={`header_nav ${getIsOpenMobileMenu() ? 'active' : ''}`}>
        <div className={styles.header_nav_logoAndCreditsBackgroynd}>
          <div className={styles.mobileAvatar}>
            <Avatar />
          </div>
          <MobileCount onCreditsClick={handleMobileCreditsClick} />
        </div>
        <nav className={styles.mobileMenu}>
          <ul className={styles.mobileMenu_list}>
            <li onClick={handleProfileClick} className={cx(styles.mobileMenu_item, 'bodyM')}>
              <div className={styles.mobileMenu_line}>
                <ProfileIcon />
                {t('pages.header.profileSettings')}
              </div>
            </li>
            {isRuLocale() && (
              <li onClick={handleVideoClick} className={cx(styles.menu_item, 'bodyM')}>
                <div className={styles.mobileMenu_line}>
                  <VideoIcon />
                  {t('pages.header.videoTutorial')}
                </div>
              </li>
            )}
            <li onClick={handleLogout} className={cx(styles.mobileMenu_item, styles.mobileMenu_item_out, 'bodyM')}>
              <div className={styles.mobileMenu_line}>
                <Exit />
                {t('pages.header.logOut')}
              </div>
            </li>
          </ul>
        </nav>
        {isRuLocale() && (
            <a
                className={styles.telegram_button_block_inHeader}
                onClick={() => reachGoal('click-telegram-button')}
                href="https://t.me/mpcardai_support?text=%D0%A3%20%D0%BC%D0%B5%D0%BD%D1%8F%20%D0%B2%D0%BE%D0%BF%D1%80%D0%BE%D1%81%20%D0%BF%D0%BE%20%D0%BA%D0%BE%D0%BD%D1%81%D1%82%D1%80%D1%83%D0%BA%D1%82%D0%BE%D1%80%D1%83%20MPCard:%0A"
                target="_blank"
                title={t('pages.header.messageInTelegram')}
                rel="noopener noreferrer"
            >
              <div className={styles.telegram_button_inHeader}>
                <svg
                    fill="none"
                    height="92"
                    viewBox="0 0 92 93"
                    width="92"
                    xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                      d="m25.4728 44.7205s18.628-7.8458 25.0885-10.6085c2.4766-1.105 10.8753-4.6412 10.8753-4.6412s3.8764-1.547 3.5533 2.2101c-.1077 1.5471-.9691 6.9618-1.8304 12.8186-1.2922 8.2879-2.692 17.3493-2.692 17.3493s-.2153 2.5417-2.0458 2.9837-4.8455-1.547-5.3838-1.9892c-.4308-.3314-8.0757-5.3042-10.8753-7.7353-.7538-.663-1.6151-1.989.1076-3.5361 3.8764-3.6467 8.5064-8.1774 11.306-11.0505 1.2922-1.3261 2.5842-4.4203-2.7996-.6631-7.6449 5.4148-15.1822 10.498-15.1822 10.498s-1.7229 1.105-4.9531.1104c-3.2304-.9944-6.9991-2.3205-6.9991-2.3205s-2.584-1.6576 1.8306-3.4257z"
                      fill="#fff"
                  />
                </svg>
              </div>
            </a>
        )}
      </div>
      {isEnLocale() && (<SupportWidgetToggle isOpenMobileMenu={getIsOpenMobileMenu()}/>)}
      <div
          className={`header_background ${getIsOpenMobileMenu() ? 'active' : ''}`}
          onClick={() => setOpenMenu(!getIsOpenMobileMenu())}
      />
      {isOpenProductVideoModal && (
          <VideoModalDialogComponent setLocalStorage={setLocalStorage} close={closeProductVideoModal}>
            <ProductVideoModal/>
          </VideoModalDialogComponent>
      )}
    </>
  )
}

export default Header
