import axios from 'axios'

import { backendUrl } from '../config/app'

/*
Expects

{
  "status": "pending",
  "confirmation": {
    "type": "string",
    "confirmation_url": "string"
  }
}

*/

export const initRegularPayment = async (option: string): Promise<string> => {
  const response = await axios.post(`${backendUrl()}api/ngb/payments/init-regular?option=${option}`)
  if (response?.data.confirmation && response?.data.confirmation.confirmation_url) {
    return response.data.confirmation.confirmation_url
  } else {
    throw new Error('Cannot initialize payment process')
  }
}
