export const applyDiscountForPrice = (
  origPrice: number,
  planId: string,
  pcPlansIds: null | string[],
  discountPercent: number
): number => {
  if (pcPlansIds && pcPlansIds.length > 0) {
    if (pcPlansIds.includes(planId)) {
      return calculateDiscount(origPrice, discountPercent)
    }

    return origPrice
  }

  return calculateDiscount(origPrice, discountPercent)
}

const calculateDiscount = (price: number, discount: number): number => {
  const percent1 = price / 100
  const discountSum = percent1 * discount

  const sum = Math.round(price - discountSum)

  if (sum <= 0) {
    return price
  }

  return sum
}
