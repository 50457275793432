import {PaywallVariations, useSubscriptions} from "../../../../store/useSubscriptions";
import {useModalManager} from "../../../../store/useModalManager";
import {MODAL_NAME as PAYWALL_MODAL} from "../../../../ds/modal/PayWallModal";
import React, {useEffect} from "react";
import {MODAL_NAME as OPEN_OVERLAY} from "../../../../ds/modal/OverlayModal";
import {SectionTab} from "polotno/side-panel";
import { ReactComponent as ResizeCardIcon } from '@material-design-icons/svg/round/aspect_ratio.svg'
import { useTranslation } from 'react-i18next'

export const Tab = (props: any) => {
    const hasPremiumSubscription = useSubscriptions(s => s.hasPremiumSubscription)
    const setPaywallVariation = useSubscriptions(s => s.setPaywallVariation)
    const toggleModal = useModalManager((s) => s.toggleModal)
    const { t } = useTranslation()
    const onTabClick = () => {
        if ( !hasPremiumSubscription) {
            setPaywallVariation(PaywallVariations.adjustSize)
            toggleModal(PAYWALL_MODAL, true)
        } else {
            props.onClick()
            toggleModal(OPEN_OVERLAY, !props.active)
        }
    }

    return (
        <SectionTab name={t('pages.editor.cardSize')} {...props} onClick={onTabClick}>
            <ResizeCardIcon />
        </SectionTab>
    )
}