import s from './productVideo.module.css'
import { SharedProps } from '../../components/ModalDialog/ModalDialog'
import { reachGoal } from '../../utils/metrics'
import { useEffect } from 'react'
import cx from 'clsx'

export function ProductVideoModal() {

  useEffect(() => {
    reachGoal('open-video-popup')
  }, [])

  return (
    <div className={s.popupBody}>
      <div style={{ position: 'relative', paddingTop: '56.25%', width: '100% '}}>
        <iframe src="https://kinescope.io/embed/cBbyH75nBmUmimhobWr4BE"/*"https://kinescope.io/embed/ia1CC2kDdosVo92BoQqv5J"*/ allow="autoplay; fullscreen; picture-in-picture; encrypted-media; gyroscope; accelerometer; clipboard-write;" frameBorder="0" allowFullScreen 
        style={{ position: 'absolute', width: '100%', height: '100%', top: '0', left: '0', borderRadius: '16px'}}></iframe>
      </div>
      <div>
        <h1 className={cx(s.mainTextPopup, 'titleS')}>Видео-инструкция по{'\u00A0'}работе в редакторе</h1>
        <div className={cx(s.minorTextPopup, 'paragraphM')}>
          <p>
            После закрытия видео-инструкция будет доступна в меню редактора
          </p>
        </div>
      </div>
    </div>
  )
}
