import { backendUrl } from '../config/app'

export interface SignInResult {
  status: boolean
  code?: number
  userId: number
}

export interface SignUpResult {
  status: boolean
  code?: number
}

export interface CompleteRegistrationResult {
  status: boolean
  code?: number
}

export interface SocialLoginResult {
  registered?: boolean
  userId?: number
  error?: string
  status: boolean
}
export interface AuthClient {
  signUp: (data: { email: string; marketingConsent: boolean, tlg?: string }) => Promise<SignUpResult>
  completeRegistration: ( data: { phone: string, marketingConsent: boolean | null }) => Promise<CompleteRegistrationResult>
  signIn: (data: { email: string; pwd: string }) => Promise<SignInResult>
  socialLogin: (query: Record<string, string>) => Promise<SocialLoginResult>
  logout: () => Promise<void>
}

const DEF_HEADERS = {
  'Content-Type': 'application/json',
}

export const defaultAuthClient: AuthClient = {
  signIn: async (data) => {
    const response = await fetch(`${backendUrl()}api/login`, {
      method: 'post',
      body: JSON.stringify({
        username: data.email,
        password: data.pwd,
      }),
      headers: { ...DEF_HEADERS },
    })

    const result: SignInResult = await response.json()

    return result
  },

  socialLogin: async (query) => {
    const response = await fetch(`${backendUrl()}api/auth/verification`, {
      method: 'post',
      body: JSON.stringify(query),
      headers: { ...DEF_HEADERS },
    })

    const result: SocialLoginResult = await response.json()

    return result
  },
  signUp: async (data) => {
    const res = await fetch(`${backendUrl()}api/register`, {
      method: 'post',
      body: JSON.stringify({ email: data.email, marketingConsent: data.marketingConsent, telegram: data.tlg}),
      headers: { ...DEF_HEADERS },
    })
    const result: SignUpResult = await res.json()
    return result
  },

  completeRegistration: async (data) => {
    const res = await fetch(`${backendUrl()}api/complete-registration`, {
      method: 'post',
      body: JSON.stringify({ phone: data.phone, marketingConsent: data.marketingConsent}),
      headers: { ...DEF_HEADERS },
    })
    const result: CompleteRegistrationResult = await res.json()
    return result
  },

  logout: async () => {
    const response = await fetch(`${backendUrl()}api/logout`, {
      method: 'post',
    })
  },
}

export default defaultAuthClient
