import { PropsWithChildren } from 'react'
import { createTheme, ThemeProvider } from '@mui/material'
import { FeatureFlagProvider } from './contexts/FeatureFlagContext'
import { AuthProvider } from './contexts/AuthProvider'
import { AppLevelModals } from './components/AppLevelModals'
import { FullPageLoadingProvider } from './contexts/FullPageLoadingContext'
import { FullPageLoading } from './components/FullPageLoading'
import { AuthAware } from './auth/AuthAware'
import { CheckVersion } from './components/CheckVersion'
import { getLocale } from './components/new-editor/utils/localization.utils'

const theme = createTheme({
  palette: {
    primary: {
      main: '#9f72ff',
    },
    secondary: {
      main: '#7948e1',
    },
  },
  typography: {
    fontFamily: '"Nunito", sans-serif',
  },
})

console.log(`Locale: ${getLocale()}`)

export function App(props: PropsWithChildren) {
  return (
    <ThemeProvider theme={theme}>
      <FeatureFlagProvider>
        <AuthProvider>
          <FullPageLoadingProvider>
            <FullPageLoading />
            <AuthAware />
            <CheckVersion />
            {props.children}
          </FullPageLoadingProvider>
        </AuthProvider>
        {/* Modal dialogs which are available everywhere */}
      </FeatureFlagProvider>
    </ThemeProvider>
  )
}
