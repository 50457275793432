import s from './paywall.module.css'
import { Button } from '../../components/Button'
import { SharedProps } from '../../components/ModalDialog/ModalDialog'
import { ReactComponent as Underline } from './underline.svg'
import { ReactComponent as Star } from '@material-design-icons/svg/round/star.svg'
import { ReactComponent as SizeIcon } from '@material-design-icons/svg/round/sell.svg'
import { ReactComponent as GenerationsIcon } from '@material-design-icons/svg/round/auto_awesome.svg'
import { ReactComponent as Brush } from '@material-design-icons/svg/round/brush.svg'
import { ReactComponent as Collections } from '@material-design-icons/svg/round/collections.svg'
import { ReactComponent as UploadFile } from '@material-design-icons/svg/round/upload_file.svg'
import { ReactComponent as Interests } from '@material-design-icons/svg/round/interests.svg'
import { ReactComponent as TextFields } from '@material-design-icons/svg/round/text_fields.svg'
import { ReactComponent as Download } from '@material-design-icons/svg/round/download.svg'
import { ReactComponent as AddToPhotos } from '@material-design-icons/svg/round/add_to_photos.svg'
import cx from 'clsx'
import { reachGoal } from '../../utils/metrics'
import { useEffect } from 'react'
import { useSubscriptions, PaywallVariations as Variations } from "../../store/useSubscriptions";
import { useTranslation } from 'react-i18next'

export const MODAL_NAME = 'payWall'

export type PayWallModalProps = {
  onBuyClick: () => void,
}

const ToolChips = [
  { icon: SizeIcon, text: 'pages.plans.cardSizeAdaptation', iconClass: s.feature_pink },
  { icon: GenerationsIcon, text: 'pages.payWallModal.increasedGenerations', iconClass: s.feature_green },
  { icon: Star, text: 'pages.payWallModal.infographicTemplates', iconClass: s.feature_star }
]

const VariationContent = {
  [Variations.createMore]: {
    header: 'pages.payWallModal.createMoreThanOneCard',
    headerIcon: Brush,
    headerIconClass: s.feature_purple,
    isPremium: false,
    secondaryText: 'pages.payWallModal.unlockProjectListForMultipleCards',
    chips: ToolChips,
  },
  [Variations.createMorePremium]: {
    header: 'pages.payWallModal.createMoreThanTenCards',
    headerIcon: Brush,
    headerIconClass: s.feature_purple,
    isPremium: true,
    secondaryText: 'pages.payWallModal.unlockMoreProjects',
    chips: ToolChips,
  },
  [Variations.createMoreBusiness]: {
    header: 'pages.payWallModal.createMoreThanHundredCards',
    headerIcon: Brush,
    headerIconClass: s.feature_purple,
    isPremium: true,
    isBusiness: true,
    secondaryText: 'pages.payWallModal.unlockUnlimitedProjects',
    chips: ToolChips,
  },
  [Variations.backgrounds]: {
    header: 'pages.payWallModal.unlockBackgroundLibrary',
    headerIcon: Collections,
    headerIconClass: s.feature_purple,
    isPremium: false,
    secondaryText: 'pages.payWallModal.createCardsWithMinimalistBackgrounds',
    chips: ToolChips,
  },
  [Variations.addImage]: {
    header: 'pages.payWallModal.addExtraImagesToCard',
    headerIcon: UploadFile,
    headerIconClass: s.feature_purple,
    isPremium: false,
    secondaryText: 'pages.payWallModal.unlockImageAddingForCardDecoration',
    chips: ToolChips,
  },
  [Variations.stickers]: {
    header: 'pages.payWallModal.unlockShapeCreation',
    headerIcon: Interests,
    headerIconClass: s.feature_purple,
    isPremium: false,
    secondaryText: 'pages.payWallModal.customizeShapesAndIconsForUniqueDesign',
    chips: ToolChips,
  },
  [Variations.addText]: {
    header: 'pages.payWallModal.unlockTextCreation',
    headerIcon: TextFields,
    headerIconClass: s.feature_purple,
    isPremium: false,
    secondaryText: 'pages.payWallModal.addTextBlocksToHighlightProduct',
    chips: ToolChips,
  },
  [Variations.templates]: {
    header: 'pages.payWallModal.unlockInfographicTemplates',
    headerIcon: Star,
    headerIconClass: s.feature_star,
    isPremium: true,
    secondaryText: 'pages.payWallModal.improveInfographicWithTemplates',
    chips: [
      { icon: SizeIcon, text: 'pages.plans.cardSizeAdaptation', iconClass: s.feature_pink },
      { icon: Brush, text: 'pages.payWallModal.toolsAccess', iconClass: s.feature_purple },
      { icon: GenerationsIcon, text: 'pages.payWallModal.increasedGenerations', iconClass: s.feature_green }
    ],
  },
  [Variations.adjustSize]: {
    header: 'pages.payWallModal.adaptCardForMarketplaces',
    headerIcon: SizeIcon,
    headerIconClass: s.feature_pink,
    isPremium: true,
    secondaryText: 'pages.payWallModal.resizeCardsForMultipleMarketplaces',
    chips: [
      { icon: Brush, text: 'pages.payWallModal.toolsAccess', iconClass: s.feature_purple },
      { icon: GenerationsIcon, text: 'pages.payWallModal.increasedGenerations', iconClass: s.feature_green },
      { icon: Star, text: 'pages.payWallModal.infographicTemplates', iconClass: s.feature_star }
    ],
  },
  [Variations.download]: {
    header: 'pages.payWallModal.unlimitedDownloads',
    headerIcon: Download,
    headerIconClass: s.feature_purple,
    isPremium: false,
    secondaryText: 'pages.payWallModal.unlockDownloadAccessForMarketplaces',
    chips: [
      { icon: SizeIcon, text: 'pages.plans.cardSizeAdaptation', iconClass: s.feature_pink },
      { icon: GenerationsIcon, text: 'pages.payWallModal.increasedGenerations', iconClass: s.feature_green },
      { icon: Star, text: 'pages.payWallModal.infographicTemplates', iconClass: s.feature_star }
    ],
  },
  [Variations.multiAsset]: {
    header: 'pages.payWallModal.createProductPhotoFunnelForMarketplaces',
    headerIcon: AddToPhotos,
    headerIconClass: s.feature_pink,
    isPremium: true,
    secondaryText: 'pages.payWallModal.uploadProductPhotosInDifferentAngles',
    chips: [
      { icon: Brush, text: 'pages.payWallModal.toolsAccess', iconClass: s.feature_purple },
      { icon: GenerationsIcon, text: 'pages.payWallModal.increasedGenerations', iconClass: s.feature_green },
      { icon: Star, text: 'pages.payWallModal.infographicTemplates', iconClass: s.feature_star }
    ],
  }
}


export function PayWallModal(props: SharedProps & PayWallModalProps) {
  const { close, onBuyClick } = props
  const variation = useSubscriptions(s => s.paywallVariation)
  const content = VariationContent[variation || 'backgrounds'];
  const { t } = useTranslation()

  const onBuySubscriptionClick = () => {
    reachGoal(`click_paywall_${variation}`)
    onBuyClick()
    close()
  }

  useEffect(() => reachGoal(`open_paywall_${variation}`), [])

  return (
    <div className={s.wrapper}>
      <div className={s.title}>
        <h1 className={cx(s.mainTextPopup, 'titleM')}>
          <span dangerouslySetInnerHTML={{ __html: t('pages.payWallModal.unlockSpecialMagic') }} />
        </h1>
        <Underline />
      </div>
      <div className={s.body}>
        <div className={cx(s.starImage, content.headerIconClass)}>
          <content.headerIcon />
        </div>

        <h2 className={s.h2}>
          {t(content.header)}
        </h2>

        <div className={cx('bodyM', s.subscriptionType)}>
          {content.isPremium && content.isBusiness
              ? t('pages.payWallModal.availableInBusinessPlan')
              : content.isPremium
                  ? t('pages.payWallModal.availableInSellerAndBusinessPlans')
                  : t('pages.payWallModal.availableInAllPlans')
          }
        </div>

        <div className={cx(s.minorTextPopup, 'paragraphM')}>
          {t(content.secondaryText)}
        </div>

        <div className={s.featureBlocks}>
          {content.chips.map(chip => (
            <div className={cx(s.featureBlock, 'paragraphS')}>
              <div className={cx(s.featureIcon, chip.iconClass)}>
                <chip.icon />
              </div>
              {t(chip.text)}
            </div>
          ))}
        </div>

        <div className={s.popupButtons}>
          <div className={s.footerContainer}>
            <Button size="m" onClick={onBuySubscriptionClick}>
              <div className={s.buttonInput}>
                <div className={s.buttonText}>{t('pages.payWallModal.subscribeNow')}</div>
              </div>
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
