import { useState } from 'react'
import { PlanDetails, Subscription, SubscriptionType } from '../../../store/useSubscriptions'
import s from './CardActiveSubscription.module.css'
import cx from 'clsx'
import { ReactComponent as ArrowDown } from '@material-design-icons/svg/filled/keyboard_arrow_down.svg'
import { ReactComponent as ArrowUp } from '@material-design-icons/svg/filled/keyboard_arrow_up.svg'
import { ReactComponent as CheckIcon } from '../../plans/cards/icon-check.svg'
import { useTranslation } from 'react-i18next'
import { getCurrencyFormat } from '../../new-editor/utils/subscription.utils'

export type CardActiveSubscriptionProps = {
  subscription: Subscription
}

const formatDate = (dateStr: string): string => {
  const date = new Date(dateStr)
  if (isNaN(date.valueOf())) {
    return ''
  }

  return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`
}

type SubscriptionDetailsProps = {
  details: PlanDetails
}

const SubscriptionDetails = (props: SubscriptionDetailsProps) => {
  const [isShown, setShownFlag] = useState(true)
  const { details } = props
  const { t } = useTranslation()
  return (
    <div>
      <div className={cx(s.DetailsCollapsibleHeader)} onClick={() => setShownFlag(!isShown)}>
        <div className="bodyS">{!isShown ? t('pages.plans.showDetails') : t('pages.plans.hideDetails')}</div>
        <div style={{ marginBottom: '-6px' }}>{isShown ? <ArrowUp /> : <ArrowDown />}</div>
        <div></div>
      </div>
      {isShown && (
        <div className={s.SubscriptionDetails}>
          <div className={s.FeaturesList}>
            {details.features_list.map((i, ix) => (
              <div key={ix} className={s.Feature}>
                <div className={s.FeatureIcon}>
                  <CheckIcon />
                </div>
                <div dangerouslySetInnerHTML={{ __html: t('pages.plans.' + i.label, { quantity: i.quantity})}} />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

const formatPrice = (price: number, type: SubscriptionType): string => {
  const { t } = useTranslation()
  const label = type == 'MONTHLY' ? t('pages.plans.perMonth') : t('pages.plans.perYear')
  const formattedPrice = getCurrencyFormat(price)

  return `${formattedPrice} ${label}`
}

export function CardActiveSubscription(props: CardActiveSubscriptionProps) {
  const { subscription } = props
  const { t } = useTranslation()

  const subUpdateKey = subscription.cancelledAt
      ? 'pages.plans.subscriptionWillBeCancelled'
      : 'pages.plans.subscriptionWillBeUpdated'
  
  return (
    <div className={s.Card}>
      <div className={s.CardContent}>
        <div className={s.PlanRow}>
          <div className={s.PlanName}>
            { t('pages.plans.' + subscription.planDetails.title) }
          </div>
        </div>
        <div className="bodyL">
          {formatPrice(subscription.price, subscription.subscriptionType)}
        </div>

        <div className={cx('paragraphS', s.nextPayDateLabel)}>
          {t(subUpdateKey)} {formatDate(subscription.nextPaymentDate)}
        </div>

        <SubscriptionDetails details={subscription.planDetails} />
      </div>
    </div>
  )
}
