import type { Feature } from './cards/CardPlan'

export const YEARLY_FEATURES: {
  base: Feature[]
  premium: Feature[]
  business: Feature[]
} = {
  base: [
    { label: 'storeUpToCards', quantity: 10 },
    { label: 'AIGenerationsPerMonth', quantity: 250},
    { label: 'unlimitedCardDownloads' },
    { label: 'infographicEditor' },
    { label: 'standardSupport' },
    { label: 'InfographicTemplates', disadvantage: true, quantity: 3 },
  ],
  premium: [
    { label: 'storeUpToCards', quantity: 100 },
    { label: 'AIGenerationsPerMonth', quantity: 2500 },
    { label: 'unlimitedCardDownloads' },
    { label: 'allInfographicTemplates' },
    { label: 'cardSizeAdaptation' },
    { label: 'photoFunnelWithSlides' },
    { label: 'prioritySupport247' },
  ],
  business: [
    { label: 'unlimitedStorage' },
    { label: 'unlimitedAIGenerations' },
    { label: 'personalSupportManager' },
    { label: 'everythingFromOtherPlans' },
  ],
}

export const MONTHLY_FEATURES: {
  base: Feature[]
  premium: Feature[]
  business: Feature[]
} = {
  base: [
    { label: 'storeUpToCards', quantity: 10 },
    { label: 'AIGenerationsPerMonth', quantity: 250 },
    { label: 'unlimitedCardDownloads' },
    { label: 'infographicEditor' },
    { label: 'standardSupport' },
    { label: 'InfographicTemplates', disadvantage: true, quantity: 3 },
  ],
  premium: [
    { label: 'storeUpToCards', quantity: 100 },
    { label: 'AIGenerationsPerMonth', quantity: 2500 },
    { label: 'unlimitedCardDownloads' },
    { label: 'allInfographicTemplates' },
    { label: 'cardSizeAdaptation' },
    { label: 'photoFunnelWithSlides' },
    { label: 'prioritySupport247' },
  ],
  business: [
    { label: 'unlimitedStorage' },
    { label: 'unlimitedAIGenerations' },
    { label: 'personalSupportManager' },
    { label: 'everythingFromOtherPlans' },
  ],
}

export const FULL_FEATURES: {
  base: Feature[]
  premium: Feature[]
  business: Feature[]
} = {
  base: [
    { label: 'storeUpToCards', quantity: 10 },
    { label: 'AIGenerationsPerMonth', quantity: 250 },
    { label: 'unlimitedCardDownloads' },
    { label: 'infographicEditor' },
    { label: 'standardSupport' },
    { label: 'InfographicTemplates', disadvantage: true, quantity: 3 },
  ],
  premium: [
    { label: 'storeUpToCards', quantity: 100 },
    { label: 'AIGenerationsPerMonth', quantity: 2500 },
    { label: 'unlimitedCardDownloads' },
    { label: 'allInfographicTemplates' },
    { label: 'cardSizeAdaptation' },
    { label: 'photoFunnelWithSlides' },
    { label: 'prioritySupport247' },
  ],
  business: [
    { label: 'unlimitedStorage' },
    { label: 'unlimitedAIGenerations' },
    { label: 'allInfographicTemplates' },
    { label: 'cardSizeAdaptation' },
    { label: 'photoFunnelWithSlides' },
    { label: 'personalSupportManager' },
  ],
}
