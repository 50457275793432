export enum PanelState {
    Templates,
    Prompt,
}

export type PromptRef = {
    prompt: string | null,
    negativePrompt: string | null,
}

export type ProductType = any;