import {StoreType} from "polotno/model/store";
import React, {useContext, useState} from "react";
import {PaywallVariations, useSubscriptions} from "../../../../store/useSubscriptions";
import {reachGoal} from "../../../../utils/metrics";
import {GenBgAdvicePanel} from "../../../GenBgAdvicePanel";
import {Button} from "../../../Button";
import {useModalManager} from "../../../../store/useModalManager";
import { ReactComponent as ArrowLeft } from '@material-design-icons/svg/round/chevron_left.svg'
import { ReactComponent as DeleteRounded } from '../../delete-rounded.svg'
import {MODAL_NAME as PAYWALL_MODAL} from "../../../../ds/modal/PayWallModal";
import { addTemplate } from "../../utils/infographics.utils";
import { ReactComponent as PremiumTemplateIcon } from '../../premium_template.svg'
import { usePolotno } from "../../../../store/usePolotno";
import { useTranslation } from 'react-i18next'
import { deleteTemplate, infoTemplates } from "../../aicard/infographics";
import { useClosePanelInMobile } from '../../../../hooks/useClosePanelInMobile'
import {getMainObject} from "../../utils/editor.utils";
import {setCustomStoreEntityProps} from "../../utils/image.utils";

const isDeleteTemplate = (template: {key: number}): boolean => template.key === 0;
const displaysBackgroundsBadge = (template: {key: number}, store: StoreType) =>
    !isDeleteTemplate(template) && !store.custom?.infographicsApplied


type Props = {
    store: StoreType,
    isScreenDesktop: boolean
}
export const InfographicsPanel = ({store, isScreenDesktop}: Props) => {
    const toggleModal = useModalManager((s) => s.toggleModal)
    const setPaywallVariation = useSubscriptions(s => s.setPaywallVariation)
    const [activeTemplateKey, setActiveTemplateKeyInternal] = useState(store.custom?.activeInfographicsTemplate ?? 0)
    const [showGenerate, setShowGenerate] = useState(false);
    const product = usePolotno(s => s.product)
    const setProduct = usePolotno(s => s.setProduct)
    const infographics = usePolotno(s => s.infographics)
    const { t } = useTranslation()
    const closePanelInMobile = useClosePanelInMobile()

    const setActiveTemplateKey = (key: number) => {
        setCustomStoreEntityProps(store, { activeInfographicsTemplate: key })
        setActiveTemplateKeyInternal(key)
    }

    const onClickAddInfographics = async (template: any, premiumRequired: boolean) => {
        if (premiumRequired) {
            setPaywallVariation(PaywallVariations.templates)
            toggleModal(PAYWALL_MODAL, true)
            return
        }
        await applyInfographics(template)
        reachGoal('click_infographics_template')
    }

    const onClickDeleteInfographics = async () => {
        await applyInfographics(deleteTemplate)
        reachGoal('click_delete_infographics_template')
    }

    const applyInfographics = async (template: {key: number}) => {
        closePanelInMobile()
        setActiveTemplateKey(template.key)
        displaysBackgroundsBadge(template, store) && setShowGenerate(true)
        await addTemplate(template, infographics, store, product)
        setProduct(getMainObject(store))
    }

    const onBackButtonClick = () =>{
        closePanelInMobile()
    }

    return (
        <div className="tool-panel info_panel">
            <div className="tool-panel_content">
                <div className="tool-panel_content_text">
                    <div className='backButton' onClick={() =>
                        onBackButtonClick()
                    }>
                        <ArrowLeft />
                    </div>
                    <h2 className={`${isScreenDesktop ? 'titleS' : 'bodyL'} colorTextBlackPrimary`}>{t('pages.editor.infographics')}</h2>
                </div>
                {!!showGenerate && (
                    <div className="tool-panel_about_background">
                        <GenBgAdvicePanel header={t('pages.downloadModal.done')}>
                            <Button
                                size="s"
                                onClick={() => {
                                    store.openSidePanel('ai-background')
                                    setShowGenerate(false)
                                }}
                                style={{ width: '100%', height: '40px' }}
                            >
                                {t('pages.editor.generateBackground')}
                            </Button>
                        </GenBgAdvicePanel>
                    </div>
                )}
                <div className="tool-panel_content_wrapper content_info_wrapper">
            <span className="odyS colorTextBlackPrimary info_text">
                {t('pages.editor.selectInfographicTemplate')}
            </span>
                    <div className="tool-panel_content_category_wrapper info_wrapper">
                        <div
                            className={
                                activeTemplateKey === deleteTemplate.key
                                    ? 'tool-panel_content_template tool-panel_content_template_delete info_elements active'
                                    : 'tool-panel_content_template tool-panel_content_template_delete info_elements'
                            }
                        >
                            <div
                                className="tool-panel_content_template_image delete_info"
                                onClick={onClickDeleteInfographics}
                            >
                                <div className="panel_content_template_delete bodyS">
                                    <DeleteRounded className="infogaphicsTemplate" />
                                    <div className="infogaphicsTemplate">{t('pages.editor.noInfographics')}</div>
                                </div>
                            </div>
                        </div>
                        {infoTemplates.map((template: any) => {
                            const hasPremiumSubscription = useSubscriptions((s) => s.hasPremiumSubscription)
                            const premiumTemplate =  !hasPremiumSubscription && !template.isFree

                            return (
                                <div
                                    className={
                                        activeTemplateKey === template.key
                                            ? 'tool-panel_content_template info_elements active'
                                            : 'tool-panel_content_template info_elements'
                                    }
                                >
                                    {premiumTemplate && <PremiumTemplateIcon className="premium_icon" />}
                                    <img
                                        className="tool-panel_content_template_image info_img"
                                        src={template.image}
                                        alt={template.alt}
                                        onClick={() => onClickAddInfographics(template, premiumTemplate)}
                                    />
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}