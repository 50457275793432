import s from './NoSubscription.module.css'
import cx from 'clsx'
import { Button } from '../../Button'
import { ReactComponent as WarnIcon } from './WarnIcon.svg'
import {useNavigate} from "react-router-dom";
import {R_PLANS} from "../../../router-constants";
import { useTranslation } from 'react-i18next'

export function NoSubscription() {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const handleGetPlan = () => navigate(R_PLANS)

  return (
    <div className={s.Card}>
      <div className={s.Content}>
        <div>
          <WarnIcon />
        </div>
        <div className={cx('bodyM', s.Text)}>{t('pages.plans.subscriptionNotPurchased')}</div>
      </div>

      <div className="Actions">
        <Button size="s" onClick={handleGetPlan}>
          {t('pages.plans.subscribeNow')}
        </Button>
      </div>
    </div>
  )
}
