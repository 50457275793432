import {useModalManager} from "../../store/useModalManager";
import { ReactComponent as CameraswitchIcon } from '@material-design-icons/svg/round/cameraswitch.svg'
import { observer } from 'mobx-react-lite'
import { useReplaceObject } from "../../store/useReplaceObject";
import { useTranslation } from 'react-i18next'

export const ReplaceObjectButton = observer(({element} : {element: any}) => {
    const toggleModal = useModalManager((s) => s.toggleModal)
    const setReplaceableObject = useReplaceObject(s => s.setReplaceableObject)
    const { t } = useTranslation()

    return (
        <span className="bp5-popover-target">
          <button
            type="button"
            aria-expanded="false"
            aria-haspopup="menu"
            className="bp5-button bp5-minimal"
            onClick={() => {
              setReplaceableObject(element)
              toggleModal('ReplaceObject', true)
          }}>
            <span aria-hidden="true" className="bp5-icon bp5-icon-layers">
              <CameraswitchIcon />
            </span>
            <span className="bp5 buttonTextReplaceObject">{t('pages.editor.replaceObject')}</span>
          </button>
        </span>
      )
})