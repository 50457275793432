import {useModalManager} from "../../../store/useModalManager";
import {PaywallVariations, useSubscriptions} from "../../../store/useSubscriptions";
import {useProjects, useProjectsSaving} from "../../../store/useProjects";
import React from "react";
import {useNavigate} from "react-router-dom";
import {MODAL_NAME as SAVING_MODAL} from "../../../ds/modal/SavingModal";
import {R_START_PAGE} from "../../../router-constants";
import {MODAL_NAME as PAYWALL_MODAL} from "../../../ds/modal/PayWallModal";
import {SectionTab} from "polotno/side-panel";
import { ReactComponent as NewCardIcon } from '../new_card.svg'
import { useTranslation } from 'react-i18next'


const NewCardTool = {
    name: 'newCard',
    Tab: (props: any) => {
        const toggleModal = useModalManager((s) => s.toggleModal)
        const hasActiveSubscription = useSubscriptions((s) => s.hasActiveSubscription)
        const hasPremiumSubscription = useSubscriptions((s) => s.hasPremiumSubscription)
        const hasBusinessSubscription = useSubscriptions((s) => s.hasBusinessSubscription)
        const projectsCount = useProjects(s => s.projects.length)
        const setPaywallVariation = useSubscriptions(s => s.setPaywallVariation)
        const isProjectSaving = useProjectsSaving((s) => s.isSaving)
        const navigate = useNavigate()
        const { t } = useTranslation()

        const createNew = () => {
            if (isProjectSaving) {
                toggleModal(SAVING_MODAL, true)
            } else {
                navigate(R_START_PAGE)
            }
        }

        const openModalNewCard = function() {
            if (projectsCount >= 100 && !hasBusinessSubscription) {
                setPaywallVariation(PaywallVariations.createMoreBusiness)
                toggleModal(PAYWALL_MODAL, true)
            } else if (projectsCount >= 10 && !hasPremiumSubscription) {
                setPaywallVariation(PaywallVariations.createMorePremium)
                toggleModal(PAYWALL_MODAL, true)
            } else if (!hasActiveSubscription) {
                setPaywallVariation(PaywallVariations.createMore)
                toggleModal(PAYWALL_MODAL, true)
            } else {
                createNew()
            }
        }
        return (
            <SectionTab name={t('pages.editor.newCard')} {...props} onClick={openModalNewCard}>
                <NewCardIcon />
            </SectionTab>
        )
    },
}

export default NewCardTool