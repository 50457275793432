import type { AiService } from '../types/ai'

export const getRandomAIServiceChoice = (): AiService => {
  const percentageString = process.env.REACT_APP_PHOTOROOM_PERCENTAGE
  const percentage = percentageString ? parseInt(percentageString, 10) : 0
  const isPhotoRoom = Math.random() < percentage / 100

  if (isPhotoRoom) {
    return 'photoroom'
  } else {
    return 'cobalt'
  }
}
