import axios from 'axios'

import { backendUrl } from '../config/app'

export interface OutpaintBgRequest {
  prompt: string
  imageUrlBase64?: string
  maskImageUrlBase64?: string
}

export interface OutpaintBgResponse {
  url: string
}

export const outpaintBackground = async (
  dataUrl: string,
  maskaUrl: string,
  prompt: string,
  pid: string
): Promise<OutpaintBgResponse> => {
  const requestData: OutpaintBgRequest = {
    prompt: prompt,
  }

  requestData.imageUrlBase64 = await drawEdgesImage(dataUrl)
  requestData.maskImageUrlBase64 = await drawEdgesMask(maskaUrl)

  const response = await axios.post(
    `${backendUrl()}api/image/outpaintBackground?pid=${pid}`,
    requestData,
    { headers: { 'Content-Type': 'application/json' } }
  )

  if (!response?.data) {
    console.error('Error during generate bg (outpaint)')
    throw new Error('Bad response from bg outpaint service')
  }

  return response?.data as OutpaintBgResponse
}

const drawEdgesImage = async (dataUrl: string): Promise<string> => {
  return new Promise((resolve, reject) => {
    const canvas = document.createElement('canvas')
    canvas.height = 1200
    canvas.width = 1200
    const ctx = canvas.getContext('2d')
    if (ctx) {
      ctx.fillStyle = 'white'
      ctx.fillRect(0, 0, 1200, 1200)
    }
    const image = new Image()
    image.src = dataUrl
    image.onload = function () {
      ctx?.drawImage(image, 150, 0, 900, 1200)
      resolve(canvas.toDataURL('image/webp'))
    }
    image.onerror= ev => reject(ev)
  })
}

const drawEdgesMask = async (maskaUrl: string): Promise<string> => {
  return new Promise((resolve) => {
    const canvas = document.createElement('canvas')
    canvas.height = 1200
    canvas.width = 1200
    const ctx = canvas.getContext('2d')
    if (ctx) {
      ctx.fillStyle = 'white'
      ctx.fillRect(0, 0, 1200, 1200)
    }
    const image = new Image()
    image.src = maskaUrl
    image.onload = function () {
      ctx?.drawImage(image, 150, 0)
      resolve(canvas.toDataURL('image/webp'))
    }
  })
}
