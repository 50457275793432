import Header from '../components/Header/Header'
import { useNavigate } from 'react-router-dom'
import { UserProfileContainer } from '../components/UserProfile/UserProfileContainer'
import { useState } from 'react'
import {ScrollLayout} from "../components/layouts";

export function UserProfilePage() {
  const navigate = useNavigate()
  const handleGoBack = () => navigate(-1)
  const [isOpenMobileMenu, setOpenMobileMenu] = useState(false)

  return (
    <ScrollLayout header={<Header isOpenMobileMenu={isOpenMobileMenu} setOpenMobileMenu={setOpenMobileMenu}/>}>
      <UserProfileContainer onClickBack={handleGoBack} />
    </ScrollLayout>
  )
}
