import { FullWideLayout } from '../components/layouts/FullWideLayout'
import Header from '../components/Header/Header'
import { useNavigate } from 'react-router-dom'
import { PlansContainer } from '../components/plans/PlansContainer'

export function PlansPage() {
  const navigate = useNavigate()
  const handleGoBack = () => navigate(-1)
  return (
    <FullWideLayout header={<Header />}>
      <PlansContainer onClickBack={handleGoBack} />
    </FullWideLayout>
  )
}
