import { RadioButtonForIconsCard } from "../../RadioButtonForIconsCard/RadioButtonForIconsCard"
import { useFullPageLoading } from "../../../contexts/FullPageLoadingContext";
import { updateInfographicsAndCardSize } from "../utils/infographics.utils";
import { createMask } from "../utils/backgrounds.utils";
import { getBackgroundWidth, outpaintAndApplyBackground } from "../utils/resize.utils";
import { usePolotno } from "../../../store/usePolotno";
import { useState } from "react";
import { useCards } from "../aicard/aiCardsContext";
import { StoreType } from "polotno/model/store";
import { BackgroundType, getMainObjectBlob } from "../utils/polotno.utils.js";
import { isRuLocale } from "../utils/localization.utils";
import styles from './MarketplaceRatioOption.module.css'
import { LOCALE_EN, LOCALE_RU } from "../constants/locales";
import { SIZE_RATIO_3_4, SIZE_RATIO_1_1 } from "../constants/sizeRatios";
import { useClosePanelInMobile } from '../../../hooks/useClosePanelInMobile'
import {setCustomStoreEntityProps} from "../utils/image.utils";

const MARKETPLACE_IMAGES = {
  [LOCALE_RU]: {
    'size34': ['/icon/icon_wb.webp', '/icon/icon_ozon.webp','/icon/icon_ym.webp','/icon/icon_mm.webp'],
    'size11': ['/icon/icon_ozon.webp', '/icon/icon_mm.webp'],
  },
  [LOCALE_EN]: {
    'size11': ['/icon/icon_amazon.webp', '/icon/icon_shopify.webp', '/icon/icon_ebay.webp', '/icon/icon_instagram.webp', '/icon/icon_facebook.webp', '/icon/icon_flipkart.webp'],
    'size34': ['/icon/icon_etsy.webp', '/icon/icon_shopify.webp', '/icon/icon_ebay.webp', '/icon/icon_mercadoLibre.webp'],
  }
};

const MARKETPLACE_TEXT = {
  [LOCALE_RU]: {
    'size34': 'Все товары для Wildberries и Яндекс Маркет. Одежда, обувь и аксессуары для Ozon и Мегамаркет',
    'size11': 'Все товары (кроме одежды, обуви и аксессуаров) для МегаМаркет и Ozon',
  },
  [LOCALE_EN]: {
    'size11': 'Amazon, Shopify, eBay, Instagram, Facebook Marketplace, Flipkart, Walmart Marketplace',
    'size34': 'Etsy, Shopify, eBay, Mercado Libre',
  }
};

const AiBackgrounds = [BackgroundType.Suggested, BackgroundType.Template,BackgroundType.Prompt];

type Props = {store: StoreType, isScreenDesktop: boolean}

export const MarketplaceRatioOption = ({store, isScreenDesktop}: Props) => {
  const { setLoading } = useFullPageLoading()
  const product = usePolotno(s => s.product)
  const [selectedSize, setSelectedSize] = useState(store.custom.cardSize)
  const {resetBackgrounds} = useCards()
  const closePanelInMobile = useClosePanelInMobile()

  const setSize = (cardSize: string) => {
    setCustomStoreEntityProps(store, { cardSize })
    store.setSize(cardSize === SIZE_RATIO_1_1 ? 1200 : 900, 1200)
    setSelectedSize(cardSize)
  }

  const clickOnResizeButton = async () => {
    setLoading(true)
    try{
        if (store.custom.cardSize === SIZE_RATIO_1_1
            && AiBackgrounds.includes(product?.custom?.activeBackgroundType)
            && store.activePage.background.includes('/') //Seems like activeBackgroundType might be unreliable with multiple pages, look into this later
        ) {
            const width = await getBackgroundWidth(store)
            if (width === 900) {
                const blob = await getMainObjectBlob(store, product)
                const mask = await createMask(blob)
                const prompt = product?.custom?.activebackgroundType === BackgroundType.Suggested
                    ? store.custom.cardInStore.prompt
                    : store.custom.currentPromptInStore.prompt
                await outpaintAndApplyBackground(store.activePage.background, mask, prompt, store)
            }
        }
        updateInfographicsAndCardSize(store)
        resetBackgrounds()
    } catch (e){
        console.error('resize error',e)
        setLoading(false)
    }
    setLoading(false)
  }

  const changeSize = (cardSize: string) => async () => {
    if (store.custom.cardSize !== cardSize) {
      setSize(cardSize)
      await clickOnResizeButton()
      closePanelInMobile()
    }
  }

  const getMarketPlaceLocale = () => {
    return (isRuLocale() ? LOCALE_RU : LOCALE_EN) as keyof typeof MARKETPLACE_IMAGES
  }

  return(
    <div className={isRuLocale() ? styles.button_container : styles.button_container_reverse}>
      <RadioButtonForIconsCard
        selection={selectedSize === SIZE_RATIO_3_4}
        title={'3:4'}
        text={MARKETPLACE_TEXT[getMarketPlaceLocale()].size34}
        arrayMarketPlace={MARKETPLACE_IMAGES[getMarketPlaceLocale()].size34}
        onClick={changeSize(SIZE_RATIO_3_4)}
      />
      <RadioButtonForIconsCard
        selection={selectedSize === SIZE_RATIO_1_1}
        title={'1:1'}
        text={MARKETPLACE_TEXT[getMarketPlaceLocale()].size11}
        arrayMarketPlace={MARKETPLACE_IMAGES[getMarketPlaceLocale()].size11}
        onClick={changeSize(SIZE_RATIO_1_1)}
      />
    </div>
  )} 