import {useModalManager} from "../../../store/useModalManager";
import React, {useEffect, useRef, useState} from "react";
import {MODAL_NAME as OPEN_OVERLAY} from "../../../ds/modal/OverlayModal";
import {SectionTab} from "polotno/side-panel";
import {observer} from "mobx-react-lite";
import { ReactComponent as AiBackgroundIcon } from '../ai_background.svg'
import {StoreType} from "polotno/model/store";
import {TemplatesPanel} from "./AiBackgrounds/TemplatesPanel";
import {PanelState} from "./AiBackgrounds/types";
import {PromptPanel} from "./AiBackgrounds/PromptPanel";
import {useResize} from "../../../hooks/useResize";
import {usePolotno} from "../../../store/usePolotno";
import { useTranslation } from 'react-i18next'
import {setCustomStoreEntityProps} from "../utils/image.utils";

const getPrompt = (store: StoreType) => ({
        prompt: store.custom.currentPromptInStore?.prompt,
        negativePrompt: store.custom.currentPromptInStore?.prompt,
    })

export const AiBackgroundTool = {
    name: 'ai-background',
    Tab: (props: any) => {
        const toggleModal = useModalManager((s) => s.toggleModal)
        const { t } = useTranslation()

        const onClickHandler = () => {
            props.onClick()
            toggleModal(OPEN_OVERLAY, !props.active)
        }

        return (
            <SectionTab name={t('pages.editor.aiBackground')} {...props} onClick={onClickHandler}>
                <AiBackgroundIcon />
            </SectionTab>
        )
    },
    Panel: observer(({ store }: { store: StoreType }) => {
        const [panelState, setPanelState] = useState(PanelState.Templates)
        const { isScreenDesktop } = useResize()
        const currentPrompt = useRef(getPrompt(store))
        const [activeBackgroundKey, setActiveBackgroundKeyInternal] = useState<number | undefined>()
        const product = usePolotno(s => s.product)
        const setActiveBackgroundKey = (key: number | undefined) => {
            setCustomStoreEntityProps(product, { activeBackgroundKey: key })
            setActiveBackgroundKeyInternal(key)
        }

        return (
            <div className="tool-panel">
                {panelState === PanelState.Templates &&
                    (<TemplatesPanel store={store} setPanelState={setPanelState} isScreenDesktop={isScreenDesktop} promptRef={currentPrompt}
                                     activeBackground={activeBackgroundKey} setActiveBackground={setActiveBackgroundKey}/>)}
                {panelState === PanelState.Prompt && (
                    <PromptPanel setPanelState={setPanelState} store={store}
                        promptRef={currentPrompt}/>
                )}
            </div>
        )
    }),
}