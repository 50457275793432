import { PropsWithChildren } from 'react'
import s from './HeaderSeparated.module.css'

export function HeaderSeparated(props: PropsWithChildren<unknown>) {
  return (
    <div className={s.borderWithTitle}>
      <span>{props.children}</span>
    </div>
  )
}
