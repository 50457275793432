import { useFullPageLoading } from '../../contexts/FullPageLoadingContext'
import './styles.css'

export function FullPageLoading() {
  const { isLoading } = useFullPageLoading()
  if (!isLoading) {
    return null
  }
  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(22, 27, 51, 0.20)',
        color: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="96"
        height="96"
        viewBox="0 0 96 96"
        fill="none"
        style={{
          animation: 'spin 2s linear infinite', // Apply the animation
        }}
      >
        <mask id="path-1-inside-1_97_1119" fill="white">
          <path d="M74.6748 83.4939C75.8693 85.0833 75.5557 87.3528 73.8812 88.4248C66.0304 93.4513 56.8603 96.0975 47.4869 95.9973C36.91 95.8842 26.6667 92.2806 18.3487 85.7465C10.0307 79.2124 4.10394 70.1138 1.48943 59.8645C-1.12509 49.6152 -0.280922 38.7894 3.89074 29.0693C8.0624 19.3491 15.3279 11.2792 24.5582 6.11345C33.7885 0.947718 44.4667 -1.02447 54.9332 0.503365C65.3998 2.0312 75.0686 6.97349 82.4371 14.5622C88.9672 21.2874 93.3665 29.7573 95.1316 38.9107C95.5081 40.863 94.0791 42.6538 92.1041 42.8829C90.1292 43.1121 88.3584 41.6917 87.9561 39.7446C86.3906 32.1672 82.696 25.1644 77.2715 19.5779C71.0083 13.1275 62.7898 8.92652 53.8932 7.62786C44.9967 6.3292 35.9202 8.00556 28.0745 12.3964C20.2287 16.7873 14.053 23.6468 10.5071 31.9089C6.96121 40.171 6.24367 49.373 8.46601 58.0848C10.6883 66.7967 15.7261 74.5306 22.7964 80.0845C29.8667 85.6385 38.5735 88.7016 47.5639 88.7977C55.3502 88.8809 62.9715 86.7346 69.5424 82.6492C71.2308 81.5994 73.4803 81.9045 74.6748 83.4939Z" />
        </mask>
        <path
          d="M74.6748 83.4939C75.8693 85.0833 75.5557 87.3528 73.8812 88.4248C66.0304 93.4513 56.8603 96.0975 47.4869 95.9973C36.91 95.8842 26.6667 92.2806 18.3487 85.7465C10.0307 79.2124 4.10394 70.1138 1.48943 59.8645C-1.12509 49.6152 -0.280922 38.7894 3.89074 29.0693C8.0624 19.3491 15.3279 11.2792 24.5582 6.11345C33.7885 0.947718 44.4667 -1.02447 54.9332 0.503365C65.3998 2.0312 75.0686 6.97349 82.4371 14.5622C88.9672 21.2874 93.3665 29.7573 95.1316 38.9107C95.5081 40.863 94.0791 42.6538 92.1041 42.8829C90.1292 43.1121 88.3584 41.6917 87.9561 39.7446C86.3906 32.1672 82.696 25.1644 77.2715 19.5779C71.0083 13.1275 62.7898 8.92652 53.8932 7.62786C44.9967 6.3292 35.9202 8.00556 28.0745 12.3964C20.2287 16.7873 14.053 23.6468 10.5071 31.9089C6.96121 40.171 6.24367 49.373 8.46601 58.0848C10.6883 66.7967 15.7261 74.5306 22.7964 80.0845C29.8667 85.6385 38.5735 88.7016 47.5639 88.7977C55.3502 88.8809 62.9715 86.7346 69.5424 82.6492C71.2308 81.5994 73.4803 81.9045 74.6748 83.4939Z"
          stroke="white"
          strokeWidth="16"
          mask="url(#path-1-inside-1_97_1119)"
        />
      </svg>
    </div>
  )
}
