import React, { useState } from 'react'
import styles from './register.module.css'
import { Button } from '../components/Button'
import { useFullPageLoading } from '../contexts/FullPageLoadingContext'
import { useNavigate } from 'react-router-dom'
import authClient from './AuthClient'
import { useAuthContext } from '../contexts/AuthProvider'
import { AsYouType } from 'libphonenumber-js'
import {reachGoal} from '../utils/metrics';
import {MarketingConsentCheckbox} from './MarketingConsentCheckbox';
import { useTranslation } from 'react-i18next'
import { isRuLocale } from '../components/new-editor/utils/localization.utils'

const RU_COUNTRY_CODE = '7'

const CompleteRegistration: React.FC<{}> = () => {
  const [phone, setPhone] = useState(isRuLocale() ? RU_COUNTRY_CODE : '')
  const [phoneError, setPhoneError] = useState<string | null>(null)
  const [marketingConsent, setMarketingConsent] = useState(true)
  const { setLoading, isLoading } = useFullPageLoading()
  const { setHasCompletedRegistration, marketingConsent: initialMarketingConsent, setIsTimeToShowTheVideo } = useAuthContext()
  const { t } = useTranslation()
  const alreadyHasMarketingConsentDecision = initialMarketingConsent != null

  const validatePhone = (phone: string): boolean => {
    const phoneRegex = /^\d{7,15}$/;
    return phoneRegex.test(phone);
  }

  const handleRegister = async (e: React.FormEvent) => {
    e.preventDefault()
    setPhoneError(null)

    if (!validatePhone(phone)) {
      setPhoneError(t('pages.registerScreen.invalidPhoneNumberFormat')) 
      return
    }

    setLoading(true)

    try {
      const { status, code } = await authClient.completeRegistration({ phone, marketingConsent: alreadyHasMarketingConsentDecision ? null : marketingConsent })
      setLoading(false)
      if (status) {
        setHasCompletedRegistration(true)
        setIsTimeToShowTheVideo(true)
        reachGoal('user_provided_phone')
      } else {
        if (code) {
          if (code === 4006) {
            setPhoneError(t('pages.registerScreen.invalidPhoneNumberFormat'))
            return
          }

          setPhoneError(t('pages.registerScreen.unexpectedError'))
        }
      }
    } catch (e) {
      setPhoneError(t('pages.registerScreen.unexpectedError'))
    }
    setLoading(false)
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value.replace(/\D/g, '');
    setPhone(input);
  };

  const formattedPhone = new AsYouType().input('+' + phone);

  return (
    <div className={styles.upload}>
      <div className={styles.content}>
        <div className={styles.title}>
          <h2 className="titleM colorTextBlackPrimary center">{t('pages.registerScreen.completeRegistration')}</h2>
        </div>
        <span className="paragraphM colorTextBlackSecondary center">{t('pages.registerScreen.enterPhoneNumberToComplete')}</span>
        <form className={styles.form} onSubmit={handleRegister}>
          <div className={styles.inputContainer}>
            <input
              className={`${styles.input} bodyM ${phoneError ? styles.inputError : ''}`}
              type="tel"
              value={formattedPhone}
              onChange={handleInputChange}
              placeholder="+7 999 000-00-00"
            />
            {phoneError && <span className={`${styles.errorText} paragraphS colorAccentPinkDark`}>
              {phoneError}
            </span>}
          </div>
          <Button type="submit" size="m" disabled={isLoading}>
            {t('pages.registerScreen.finishRegistration')}
          </Button>
        </form>

        {!alreadyHasMarketingConsentDecision && (
          <div
            className="bodyS colorTextBlackPrimary center"
            style={{ color: 'var(--Text-Black-Tertiary, rgba(22, 27, 51, 0.4))' }}
          >
            <MarketingConsentCheckbox 
              checked={marketingConsent}
              onChange={setMarketingConsent}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default CompleteRegistration
