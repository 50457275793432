import React from 'react'
import styles from './register.module.css'
import { Button } from '../components/Button'
import { useNavigate } from 'react-router-dom'
import { R_SIGN_IN_PAGE } from '../router-constants'
import { useTranslation } from 'react-i18next'

const AuthThankYou: React.FC<{}> = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const goSignIn = () => navigate(R_SIGN_IN_PAGE)
  return (
    <div className={styles.upload}>
      <div className={styles.content}>
        <div className={`${styles.title} ${styles.thanksModal}`}>
          <h2 className="titleL colorTextBlackPrimary">{t('pages.registerScreen.thankYou')}</h2>
          <div className={styles.other}>
            <span className="paragraphMCenter colorTextBlackSecondary">
              {t('pages.registerScreen.passwordSentToEmail')}
            </span>
          </div>
        </div>
        <Button onClick={goSignIn} type="button" size="m">
          {t('pages.registerScreen.loginToAccount')}
        </Button>
      </div>
    </div>
  )
}

export default AuthThankYou
