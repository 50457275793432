import React from "react";
import {observer} from "mobx-react-lite";
import { Tab } from "./Tab";
import {useResize} from "../../../../hooks/useResize";
import {Panel} from "./Panel";


export const ResizeTool = {
    name: 'resizeCard',
    Tab,
    Panel: observer(({ store }: { store: any }) => {
        const { isScreenDesktop } = useResize()
        return <Panel store={store} isScreenDesktop={isScreenDesktop} />
    }),
}